.columnCreator {
  .aem-Grid {
    //Change to site width
    width: 1440px;
    margin: auto;
  }
  .aem-Grid-fluid {
    width: 100%;
  }
  .aem-Grid-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
}
