.imageupload {
  width: 100%;

  p {
    margin: 0;
  }

  .imageupload-container {
    position: relative;
    background: #fff;
    border: 2px solid $platform-image-upload-border;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 9.9rem;
    min-height: 7.5rem;
    @media screen and (min-width: $screen-sm) {
      width: 11.2rem;
      min-height: 9.8rem;
    }
  }

  .imageupload-icon {
    margin-bottom: 5px;

    @media screen and (min-width: $screen-sm) {
      margin-bottom: 16px;
    }
  }

  .imageupload-svg-upload {
    height: 20px;
    width: 20px;
    margin: 2px 10px 0 10px;
  }

  .imageupload-container input[type="file"],
  .imageupload-container input {
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  .imageupload-container input[type="file"]:hover {
    cursor: pointer;
  }

  .imageupload-container
    input[type="file"]:hover
    + .imageupload-button
    > .imageupload-btn-text {
    border-bottom: 2px solid black;
    outline: none;
  }

  .imageupload-container
    input[type="file"]:active
    + .imageupload-button
    > .imageupload-btn-text {
    border-bottom: 2px solid black;
    outline: none;
  }

  .imageupload-container
    input[type="file"]:focus
    + .imageupload-button
    > .imageupload-btn-text {
    border-bottom: 2px solid black;
    outline: none;
  }

  .imageupload-button {
    box-sizing: border-box;
    background-color: $platform-white;
    padding: 0;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .imageupload-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 10px;
  }

  .imageupload-cancel-icon {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

    span {
      max-width: 100%;
      margin-bottom: 9px;
      margin-right: 15px;
      height: 25px;
      @media screen and (min-width: $screen-sm) {
        height: 26px;
      }
    }
  }

  .imageupload-svg-cancel {
    height: 20px;
    width: 20px;
    margin: 2px 0 0 10px;
  }

  .imageupload-image-container {
    width: 100%;
    height: 4.6rem;
    overflow: hidden;
    display: none;

    img {
      max-width: 100%;
      display: block;
      width: 100%;
    }

    @media screen and (min-width: $screen-sm) {
      height: 6.6rem;
    }
  }

  .flex-it {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .imageupload-filename {
    white-space: nowrap;
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 14px;
    @media screen and (min-width: $screen-sm) {
      font-size: $font-size;
    }
  }

  .imageupload-error {
    display: none;
    color: $platform-gray-text;
    margin: 0;
    font-size: 14px;
    @media screen and (min-width: $screen-sm) {
      font-size: $font-size;
    }
  }

  .filename-error {
    display: none;
    color: $platform-gray-text;
    margin: 0;
    font-size: 14px;
    @media screen and (min-width: $screen-sm) {
      font-size: $font-size;
    }
  }

  .imageupload-btn-text {
    border-bottom: 2px solid black;
    margin: 0;
    font-size: $font-size;
    @media screen and (min-width: $screen-sm) {
      border-bottom: 2px solid transparent;
      font-size: 18px;
    }
  }

  .image-required-message {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-align: left;
    padding-left: 0.5rem;
    display: none;
  }

  .imageupload-pdf-icon {
    height: 50px;
    width: 50px;
    margin: 40px 62px 0 62px;
  }

  ////===================================================================================
  ////=============================== Style System Section ==============================
  ////===================================================================================

  &.side-by-side {
    padding: 5px;
    margin: 5px;
    width: 100%;
    @media screen and (min-width: $screen-sm) {
      width: fit-content !important; // important used to over rule AEM grid classes
    }
  }

  &.up-and-down {
    width: 100%;
    padding: 5px;
    margin: 5px;
  }
}
