.retailer-list-container {
  max-width: 100%;

  .retailer-list-title {
    font-size: 1.5rem;
  }

  .retailer-items {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    margin: 1.5rem 0;
    text-align: center;

    .retailer-item-image {
      border: 1px solid;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
    }

    .retailer-item-title {
      display: inline-block;
      margin: 0 auto;
      text-align: center;
    }

    @media screen and (max-width: 60rem) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
