.grid--wall__color {
  display: flex;
  flex-flow: wrap;
  width: 100%;

  .grid--wall__item-color {
    border-bottom: none !important;
    width: 100%;

    &.visible {
      opacity: 1;
      display: flex;
      align-items: center;
      visibility: visible;
      flex-direction: column;
    }

    @media screen and (min-width: 325px) {
      flex: 1 0 24.3333%;
    }

    @media screen and (min-width: $screen-lg-md) {
      width: 2.2rem;
      flex: 0 0 24.3333%;
    }
  }

  .cbg-cmp-wall-item__swatch-container {
    border: 2px solid $platform-cream;
    padding-bottom: rem(12px);
    height: 16.5rem;
    width: rem(158px);
    position: relative;

    @media screen and (min-width: $screen-sm) {
      width: 10.5rem;
    }

    @media screen and (min-width: $screen-md) {
      height: 18rem;
    }

    @media screen and (min-width: $screen-lg) {
      width: 12.5rem;
    }

    .cbg-colorCard__info {
      .cbg-cmp-card__tagemblem {
        position: relative;
        width: 100%;

        .cbg-cmp--tag-emblem {
          padding-left: 0;
        }

        .cbg-cmp--tag-emblem-inner {
          margin-left: 0;
        }
      }
    }
  }

  .color-anchor {
    color: $platform-text-color-blue;
    text-decoration: none;

    &:focus-visible {
      z-index: 1;
    }

    .cbg-cmp--tag-emblem {
      min-height: 40px;
    }
  }

  .cbg-colorCard__info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding-left: 10px;
    padding-top: 12px;
    margin-bottom: 12px;
    height: 113px;
  }

  .cbg-colorCard__color-id {
    color: $plaform-gray-fill;
    font-size: 12px;
    letter-spacing: 0;
    margin: 0;
    font-family: $font-ibm, sans-serif;
    font-weight: 400;
    margin-bottom: 12px;

    @media screen and (min-width: $screen-md) {
      margin-bottom: 31px;
    }
    //padding-bottom: 20px;
  }

  .cbg-color-swatch {
    height: 9.8rem;
    background-color: var(--color-swatch-bg-color);

    &.imageHover {
      background-repeat: no-repeat;
      background-size: cover;
      transition:
        all 1s ease-in-out,
        opacity 0.3s ease-in-out;

      &:hover {
        // clip-path: unset;
        // border-radius: unset;
        background-size: cover;
      }
    }

    &.secondaryImage {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;

      &.hovered {
        opacity: 1;
      }
    }
  }

  .load-more-btn {
    margin: auto;
  }

  .cbg-colorCard__cart-btn {
    background: transparent;
    padding: rem(9.5px 6.5px);
    border-radius: 0;
    display: flex;
    align-items: center;
    width: rem(158px);

    @media screen and (min-width: $screen-sm) {
      width: 10.5rem;
    }

    @media screen and (min-width: $screen-lg) {
      width: 12.5rem;
    }

    &:focus-visible {
      z-index: 1;
    }
  }

  .icon-add {
    background-image: url("../resources/images/svgs/add-chip.svg");
  }

  .icon-remove {
    background-image: url("../resources/images/svgs/remove-chip.svg");
  }

  @media screen and (min-width: $screen-lg) {
    max-width: 58rem;
  }
}
