.billboard.side-by-side {
  .billboard-textbox {
    width: 100%;

    .billboard-text-container {
      width: 96%;
      display: flex;
      justify-content: center;
      flex-direction: row;

      .billboard-button-zone {
        width: 100%;

        .button.base {
          //cart button before add
          .button.button-home.cd-cart-btn.cart-cta--add-state {
            margin: 1rem 0.5rem 1rem auto;
          }

          //cart button after add
          .button.button-home.cd-cart-btn.cart-cta--add-remove {
            margin: 1rem 0.5rem 1rem auto;
          }

          // Color of the year add to favorites button
          .button.button-home.cbg-coty-collection-favorite {
            margin: 1rem auto 1rem 0.5rem;
          }

          .button--wrapper-inner {
            display: flex;
            width: 100%;
          }

          &:first-of-type {
            .button--wrapper-inner {
              justify-content: flex-end;
            }
          }

          &:last-of-type {
            .button--wrapper-inner {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    @media screen and (max-width: 874px) {
      .billboard-text-container {
        .billboard-button-zone {
          .button.base {
            //cart button before add
            .button.button-home.cd-cart-btn.cart-cta--add-state {
              margin: 1rem 0.5rem 1rem auto;
            }

            // cart button after add
            .button.button-home.cd-cart-btn.cart-cta--add-remove {
              margin: 1rem 0 1rem auto;
            }

            // Color of the year add to favorites button
            .button.button-home.cbg-coty-collection-favorite {
              margin: 1rem auto 1rem 0;
            }

            &:first-of-type {
              .button--wrapper-inner {
                justify-content: center;
                width: auto;
              }
            }

            &:last-of-type {
              .button--wrapper-inner {
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .aem-Grid {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media screen and (max-width: 874px) {
        flex-direction: column;
        .button-home {
          width: 100%;
        }
      }
    }
  }
}

.billboard {
  &.mobile-image-on-top {
    .billboard-textbox {
      @media screen and (max-width: $screen-md) {
        flex-direction: column-reverse;
      }
    }
  }

  &.mobile-image-on-bottom {
    .billboard-textbox {
      @media screen and (max-width: $screen-md) {
        flex-direction: column;
      }
    }
  }

  .billboard-home {
    &.hexBackground {
      background-color: var(--billboard-bg-color);
    }

    &.hexText,
    &.hexText .billboard-description {
      color: var(--billboard-text-color);
    }

    .pinterest-home {
      .pinterest-anchor {
        float: right;
      }
    }

    &.billboard-textbox-full {
      .billboard-gutter-wrapper {
        width: 100%;
        @media screen and (min-width: $screen-lg) {
          padding: 0;
        }
      }
    }

    .billboard-textbox {
      .billboard-text-container ul li {
        margin-bottom: 0;
      }
    }

    ////===================================================================================
    ////=============================== Style System Section ==============================
    ////===================================================================================

    &.container-margin-small {
      @media screen and (min-width: $screen-md) {
        padding-top: 2em;
        padding-bottom: 2em;
      }
    }

    &.container-margin-medium {
      @media screen and (min-width: $screen-md) {
        padding-top: 4em;
        padding-bottom: 4em;
      }
    }

    &.container-margin-large {
      @media screen and (min-width: $screen-md) {
        padding-top: 6em;
        padding-bottom: 6em;
      }
    }

    &.container-margin-extra-large {
      @media screen and (min-width: $screen-md) {
        padding-top: 8em;
        padding-bottom: 8em;
      }
    }
  }

  .image-area {
    margin-bottom: -6px;
  }

  &.centered-no-image {
    .billboard-home {
      min-height: 0;
      padding: 2em;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }

    .billboard-textbox-full {
      min-height: 0;
    }

    .billboard-gutter-wrapper {
      width: 100%;
      @media screen and (min-width: $screen-lg) {
        padding: 0;

        .billboard-title {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (min-width: $screen-md) {
      min-height: 0;

      .billboard-title {
        margin-bottom: 0;
      }
    }
    @media screen and (min-width: $screen-md-lg) {
      padding: 0;
      text-align: center;

      .billboard-title {
        margin-bottom: 0;
      }
    }
    @media screen and (min-width: $screen-lg) {
      min-height: 0;

      .billboard-title {
        margin-bottom: 0;
      }
    }
  }

  &.underline-billboard {
    .billboard-gutter-wrapper {
      @media screen and (min-width: $screen-md) {
        border-bottom: 2px solid $platform-dark-blue;
      }
    }

    .image-area {
      margin-bottom: -6px;
    }
  }

  &.underline-card {
    .billboard-parsys {
      border-bottom: 2px solid $valspar-dark-blue;
    }
  }

  // Style Policy to Add Gutter Billboard
  &.add-gutter {
    .billboard-home {
      width: 100%;

      @media screen and (min-width: $screen-md) {
        margin: auto;
        max-width: $desktop-grid;
      }
    }

    .billboard-textbox {
      @media screen and (min-width: $screen-sm) {
        align-items: normal;
      }
    }

    .billboard-text-container {
      width: 100%;
      @media screen and (min-width: $screen-lg) {
        align-self: center;
        padding: 0;
        width: 50%;
      }
    }

    .desktop-billboard-image {
      width: 100%;
    }

    .billboard-textbox-right {
      .billboard-text-container {
        padding: 1.3rem;
      }
    }
  }

  &.block-align-right {
    .billboard-description {
      text-align: right;
    }
  }

  &.block-align-left {
    .billboard-description {
      text-align: left;
    }
  }

  &.block-align-center {
    .billboard-description {
      text-align: center;
    }
  }

  ////===================================================================================
  ////=============================== End of Style System Section ==============================
  ////===================================================================================

  .billboard-right-rail-styling {
    .desktop-billboard-image {
      &.billboard-parsys {
        padding: 1.2em;
        width: 100%;
        margin-left: 0.8em;
        @media screen and (min-width: $screen-md) {
          padding: 3em;
        }
      }
    }

    .authoredList {
      .authored-list-home {
        width: 90%;
      }
    }

    .billboard-text-container {
      width: 100%;
      @media screen and (min-width: $screen-lg) {
        width: 66%;
      }
    }
  }

  // Image to the Left of Textbox Style Option Class
  .billboard-textbox-left {
    @media screen and (min-width: $screen-md) {
      .billboard-textbox {
        flex-direction: column-reverse;
        @media screen and (min-width: $screen-md) {
          flex-direction: row;
          justify-content: flex-start;
        }
      }
    }

    &.use-as-mobile {
      .desktop-billboard-image {
        display: none;
        @media screen and (min-width: $screen-sm-billboard) {
          display: flex;
          justify-content: normal;
        }
      }

      .secondary-image-area {
        display: flex;
        @media screen and (min-width: $screen-sm-billboard) {
          display: none;
        }
      }
    }

    .billboard-gutter-wrapper {
      @media screen and (min-width: $screen-md) {
        width: 88%;
      }
      @media screen and (min-width: $screen-lg) {
        padding: 0;
      }
      @media screen and (min-width: $screen-xl) {
        width: 1220px;
      }
    }

    .desktop-billboard-image {
      @media screen and (min-width: $screen-lg) {
        margin-right: 5em;
      }
    }

    .billboard-text-container {
      padding: 1.3rem 0;
      @media screen and (min-width: $screen-lg) {
        padding: 1rem;
      }
    }

    .billboard-title {
      @media screen and (min-width: $screen-md) {
        letter-spacing: 1px;
        max-width: 100%;
      }
    }

    .billboard-subtitle {
      @media screen and (min-width: $screen-md) {
        max-width: 100%;
      }
    }
  }

  // Image to the Right of Textbox Style Option Class
  .billboard-textbox-right {
    margin: auto;
    @media screen and (min-width: $screen-md) {
      flex-direction: column;
    }

    &.use-as-mobile {
      .desktop-billboard-image {
        display: none;
        @media screen and (min-width: $screen-sm-billboard) {
          display: flex;
          justify-content: normal;
        }
      }

      .secondary-image-area {
        display: flex;
        @media screen and (min-width: $screen-sm-billboard) {
          display: none;
        }
      }
    }

    .billboard-textbox {
      @media screen and (min-width: $screen-md) {
        flex-direction: row;
      }
    }

    .billboard-gutter-wrapper {
      @media screen and (min-width: $screen-md) {
        width: 88%;
      }
      @media screen and (min-width: $screen-lg) {
        padding: 0;
      }

      @media screen and (min-width: $screen-xl) {
        width: 1220px;
      }
    }

    .billboard-text-container {
      padding: 1.3rem 0;
      @media screen and (min-width: $screen-md) {
        padding: 1rem;
        padding-left: 6em;
      }
    }

    .billboard-title {
      @media screen and (min-width: $screen-md) {
        letter-spacing: 1px;
        max-width: 100%;
      }
    }

    .billboard-subtitle {
      @media screen and (min-width: $screen-md) {
        max-width: 100%;
      }
    }
  }

  // Wrapper class that sets gutter for Billboard
  .billboard-gutter-wrapper {
    margin: 0 auto;
    @media screen and (min-width: $screen-lg) {
      width: 1220px;
    }
    @media screen and (min-width: $screen-xl) {
      width: 1220px;
    }

    .flex-align-top {
      align-items: normal;
    }

    .billboard-title {
      &.title-squish-35 {
        @media screen and (min-width: $screen-lg) {
          width: 35%;
        }
      }

      &.title-squish-50 {
        @media screen and (min-width: $screen-lg) {
          width: 45%;
        }
      }

      &.title-squish-75 {
        @media screen and (min-width: $screen-lg) {
          width: 65%;
        }
      }

      &.title-squish-95 {
        @media screen and (min-width: $screen-lg) {
          width: 90%;
        }
      }
    }

    .desktop-billboard-image {
      width: 100%;
      padding: 1.3rem 0;

      img {
        width: 100%;
      }

      @media screen and (min-width: $screen-md) {
        display: inline-flex;
        flex-direction: column;
        padding: 0;
        max-width: 60rem;
      }

      @media screen and (min-width: $screen-lg) {
        display: inline-flex;
        flex-direction: column;
        padding: 0;
        max-width: 35rem;
      }
    }
  }

  // Billboard Component's Parsys Area Class
  .billboard-button-zone {
    width: 100%;

    .each-card {
      &.text-only {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
      }
    }

    ul li {
      margin-bottom: 0;
    }

    @media screen and (min-width: $screen-lg) {
      width: 90%;
    }
  }

  // ============ Component Classes that Render Text ============ //

  // Class That Holds All Billboard Text Rendering Elements, plus Parsys Areas
  .billboard-textbox {
    display: flex;
    padding: 0 1.3rem;
    flex-direction: column;

    .desktop-image {
      margin-right: 2em;
    }

    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0;
    }
  }

  // Class that Contains just the Billboard Text Rendering Elements
  .billboard-text-container {
    align-self: center;
    display: flex;
    flex-flow: column wrap;
    padding: 0;
    width: 100%;
    @media screen and (min-width: $screen-lg) {
      height: fit-content;
      width: 50%;
      padding: 1rem 1rem;
    }
  }

  // Class for Billboard Title Element
  .billboard-title {
    margin-bottom: 10px;

    @media screen and (min-width: $screen-md) {
      font-weight: normal;
      letter-spacing: 0;
      margin-bottom: 20px;
      padding: 0;
      width: 90%;
    }
  }

  // Class for Billboard Subtitle Element
  .billboard-subtitle {
    letter-spacing: 2px;
    margin: 0 0 5px 0;
    @media screen and (min-width: $screen-sm) {
      font-size: 1rem;
      letter-spacing: 2px;
      margin: 0 0 10px 0;
      max-width: 34rem;
      padding: 0;
      width: 90%;
    }
  }

  // Class for Billboard Description Element
  .billboard-description {
    letter-spacing: 0;
    margin-bottom: 20px;
    margin-top: 0;

    p {
      margin: 0;
      padding: 0;
    }

    @media screen and (min-width: $screen-sm) {
      font-size: $font-size;
      line-height: 1.5rem;
      max-width: 34rem;
      margin-bottom: 30px;
      padding: 0;
      width: 90%;
    }
  }

  // Class for Billboard Header Element
  .billboard-heading {
    .billboard-text-container {
      width: 100%;
    }
  }

  //============ End Of Component Classes that Render Text ============//

  .billboard-textbox-full {
    .billboard-textbox-full-mobile {
      @media screen and (max-width: $screen-sm-billboard) {
        background-size: contain;
        background-repeat: no-repeat;
        &.box-align-center {
          padding-top: 95%;
        }
        &.box-align-left {
          .billboard-text-container-full {
            width: 90%;
            margin: auto;
            padding: 40px;
            padding-top: 95%;
          }

          .billboard-description {
            font-size: $font-size-mobile-H3-5;
            line-height: 30px;
          }
        }
        &.box-align-right {
          .billboard-text-container-full {
            width: 90%;
            margin: 0 auto;
            padding: 40px;
            padding-top: 95%;
          }

          .billboard-description {
            font-size: $font-size-mobile-H3-5;
            line-height: 30px;
          }
        }
      }

      &.use-as-mobile {
        display: flex;
        //background-size: contain;
        //background-repeat: no-repeat;
        //padding-top: 50%;
        @media screen and (min-width: $screen-sm-billboard) {
          display: none;
        }
      }
    }
  }

  //============ Billboard Textbox With Full Width Background Image Class ============//
  .billboard-textbox-full-image {
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    height: 100%;
    min-height: 30rem;
    justify-content: center;
    width: 100%;

    &.box-align-center {
      justify-content: center;
    }

    &.box-align-left {
      justify-content: left;
    }

    &.box-align-right {
      justify-content: right;
    }

    &.use-as-mobile {
      display: none;
      @media screen and (min-width: $screen-sm-billboard) {
        display: flex;
        //justify-content: normal;
      }

      .secondary-image-area {
        display: flex;
        background-size: cover;
        @media screen and (min-width: $screen-sm-billboard) {
          display: none;
        }
      }
    }

    .billboard-gutter-wrapper {
      width: 100%;
      @media screen and (min-width: $screen-lg) {
        padding: 0;
      }
    }

    .secondary-image-area {
      margin-top: 20px;
      @media screen and (min-width: $screen-md) {
        margin: auto;
      }
    }

    @media screen and (min-width: $screen-md) {
      min-height: 36rem;
      text-align: center;
      padding: 0;
    }
    @media screen and (min-width: $screen-lg) {
      min-height: 40rem;
      text-align: center;
      padding: 0;
    }
  }

  // Billboard Textbox Container for Full Width Background Image Class
  .billboard-text-container-full {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1.3rem;

    margin: 1em;
    width: 90%;
    @media screen and (min-width: $screen-md) {
      width: 50%;
      padding: 80px;
    }

    &.billboard-text-container--top {
      align-self: start;
      padding-top: 2%;
    }

    &.billboard-text-container--bottom {
      align-self: end;
      padding-bottom: 2%;
    }

    &.box-align-left {
      align-items: start;

      .billboard-text-container-full {
        //padding-left: 2%;
        width: 89%;
        margin: auto;
      }
    }

    &.box-align-right {
      align-items: end;

      .billboard-text-container-full {
        padding-right: 2%;
      }
    }

    .billboard-title {
      font-weight: normal;
      margin-bottom: 2rem;
      text-align: center;
      @media screen and (min-width: $screen-lg) {
        display: block;
        letter-spacing: 0;
        text-align: center;
      }
    }

    .billboard-button-zone {
      margin-bottom: 0;
      width: 100%;

      .button--wrapper-inner {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .text-align-left.billboard .billboard-text-container-full {
    text-align: left;

    .billboard-title {
      text-align: left;
    }
  }

  //Kicking off Build
  .text-align-right.billboard .billboard-text-container-full {
    text-align: right;

    .billboard-title {
      text-align: right;
    }
  }

  .text-align-center.billboard .billboard-text-container-full {
    text-align: center;

    .billboard-title {
      text-align: center;
    }
  }
}

.lowes-inspiration {
  .billboard {
    @media screen and (min-width: 1200px) {
      .billboard .billboard-button-zone {
        width: 100%;
      }
    }
  }
}

.billboard-button-container {
  @media screen and (min-width: $screen-lg) {
    display: flex;
    gap: 30px;
    width: 100%;
    justify-content: center;
  }

  .button-home {
    margin-top: 10px;
    width: 100%;
  }
}

.billboard-button-container:has(.card-alignment-left) {
  justify-content: flex-start;
}

.billboard-button-container:has(.card-alignment-right) {
  justify-content: flex-end;
}
