.paintSplotchCarousel {
  max-width: rem(780px);

  &.carousel {
    .cmp-carousel__action--previous,
    .cmp-carousel__action--next {
      display: none;
    }

    .tns-nav {
      grid-template-columns: repeat(8, 1fr);
      width: inherit;
      gap: 6px;

      [aria-controls] {
        width: 1.4rem;
        height: 1.4rem;
      }

      .tns-nav-active {
        width: 1.4rem;
        height: 1.4rem;
      }

      @media screen and (min-width: $screen-md) {
        gap: 8px;

        [aria-controls] {
          width: 2rem;
          height: 2rem;
        }

        .tns-nav-active {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  .cmp-carousel {
    margin: 0.5em;

    @media screen and (min-width: 400px) {
      margin: 1em;
    }

    width: auto;
    max-width: 52em;

    .cmp-carousel__content {
      padding: 1em;
      display: grid;
      grid-template: 1fr / 1fr;
      border: 2px solid $platform-image-upload-border;

      @media screen and (min-width: 992px) {
        grid-template: auto 1fr 1fr / 45% 1em 1fr;
      }

      .splotch-img-container {
        text-align: center;

        @media screen and (min-width: 992px) {
          grid-row-start: 1;
          grid-row-end: 4;
        }

        .splotch-img {
          width: auto;
          max-width: 100%;
        }
      }

      .cmp-carousel__actions {
        height: min-content;
        z-index: 1;

        @media screen and (min-width: 992px) {
          grid-column: 3;
          grid-row: 2;
          justify-content: left;
          margin: 0;
          align-self: center;
        }
      }

      .splotch-color-data {
        display: inline-flex;

        @media screen and (min-width: 992px) {
          grid-column: 3;
          grid-row: 1;
          padding-top: 1em;
        }

        .cbg-favorite-cta {
          position: initial;
        }

        .cbg-favorite-cta:before,
        .cbg-favorite-cta:after {
          @media screen and (min-width: 992px) {
            top: rem(26px);
          }
        }

        .splotch--name {
          color: $platform-dark-blue;
          text-decoration: none;
          border-bottom: 2px solid #62b5e5;
        }

        .splotch--number {
          font-size: 0.9em;
          margin-top: 0.25em;
          color: $valspar-dark-gray;
        }

        .splotch--text {
          .cbg-cmp-paintSplotch__tagemblem {
            div {
              position: relative;
              height: fit-content;
              width: 15rem;
              min-height: initial;
              min-width: initial;
              span {
                margin: 0 0 0 0;
              }
            }
          }
        }
      }

      .paint-splotch-btn-container {
        .buy-paint-sample-btn {
          &.hide-button {
            display: none;
          }
          &:hover {
            cursor: pointer;
          }
        }

        @media screen and (min-width: 992px) {
          grid-column: 3;
          grid-row: 3;
        }

        .paint-splotch-chip-btn .cbg-colorCard__text,
        .button-secondary {
          font-size: 1em;
          font-family: $font-ibm-regular;
        }

        .button-secondary {
          text-decoration: none;
          border: 2px solid #62b5e5;
          color: $brand-dark-blue;
          padding: 0.5em 1.75em;
          width: 100%;
          display: block;
          border-radius: 3em;
          text-align: center;
          margin-top: 0.75em;

          &:hover {
            border-color: $brand-dark-blue;
          }

          @media screen and (min-width: 992px) {
            width: rem(225px);
          }
        }
      }

      .paint-splotch-chip-btn {
        padding: 0.7em 0;
        @media screen and (min-width: 992px) {
          width: rem(225px);
        }
      }
    }
  }
}
