$z-level0: 0;
$z-level1: 10;
$z-level2: 20;
$z-level3: 30;
$z-level4: 40;
$z-level5: 50;
$z-level6: 60;
$z-level7: 70;
$z-level8: 80;
$z-level9: 90;
$z-level10: 100;
