.projectRoomCard {
  border-radius: 0;
  overflow: hidden;

  .cbg-favorite-cta:before,
  .cbg-favorite-cta:after,
  .cbg-favorite-cta.loading:before,
  .cbg-favorite-cta.loading:after {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 5px;
    right: 5px;
  }

  .cbg-favorite-cta {
    position: relative;
    width: auto;
    height: auto;
  }

  &.roomCard {
    border-radius: 0;
    box-shadow: none;
  }

  .project-room-card {
    .project-room-card__images {
      display: grid;
      grid-template: auto 1fr / 1fr;

      @media screen and (min-width: $screen-md) {
        grid-template-columns: 1fr 300px;
      }

      .project-room-card__image--left {
        grid-column: 1;
        display: grid;
        grid-template: 1fr / 1fr;

        .project-room-card__primary {
          max-width: 100%;
          width: 100%;
          grid-column: 1;
          grid-row: 1;
        }

        .mobile-btn {
          width: 125px;
          height: 30px;
          grid-column: 1;
          grid-row: 1;
          background: white;
          color: $platform-gray-text;
          font-size: rem(14px);
          padding: 0;
          justify-self: end;
          align-self: end;
          margin: 0 1em 1em;
        }
      }

      .project-room-card__image--right {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        margin: 1em 0 1em 0;
        height: min-content;

        @media screen and (min-width: $screen-md) {
          flex-direction: column;
          flex-wrap: nowrap;
          margin: 1em 0 1em 1em;
        }

        > * {
          width: 100%;

          @media screen and (min-width: $screen-md) {
            height: 33%;
            width: auto;
          }
        }

        .project-room-card__chip {
          width: 100%;
          display: flex;
          grid-row: 1;
          flex-direction: column;
          border: 2px solid $platform-image-upload-border;
          max-height: 145px;

          @media screen and (min-width: $screen-sm-md) {
            width: calc(50% - 0.5em);
          }

          @media screen and (min-width: $screen-md) {
            width: 100%;
          }

          > * {
            height: auto;

            .primary-colorId,
            .secondary-colorId {
              height: 50px;
              width: 50px;

              margin: 1em 0 0 1em;
            }

            .color-chip--text {
              margin: 1em 0 0 10px;
              flex: 1 1 auto;

              .color-chip--name {
                font-size: rem(14px);
                line-height: 1;
                font-weight: 500;
                color: $brand-dark-blue;
                border-bottom: 2px solid $platform-full-width-btn;
                width: fit-content;
                text-decoration: none;

                @media screen and (min-width: $screen-sm-md) {
                  font-size: rem(18px);
                }
              }

              .color-chip--number {
                color: $platform-gray-text;
                font-size: rem(14px);
                margin-top: 2px;
              }
            }

            @media screen and (min-width: $screen-md) {
              height: 50%;
            }
          }
        }

        .project-room-card__description {
          color: $platform-gray-text;
          font-size: rem(14px);
          line-height: 1.8;
          grid-row: 2;

          @media screen and (min-width: $screen-sm-md) {
            font-size: 1em;
          }
        }

        .project-room-card__chip--top {
          display: inline-flex;
        }

        .project-room-card__chip--bottom {
          text-align: center;
          margin: 0.75em 1.5em 0.5em;
          display: inline-flex;
          height: auto;
          font-size: rem(14px);

          @media screen and (min-width: $screen-sm-md) {
            font-size: 1em;
          }

          .button-secondary {
            &:hover {
              border-color: $brand-dark-blue;
            }

            text-decoration: none;
            background: none;
            border: 2px $platform-full-width-btn solid;
            color: $brand-dark-blue;
            font-size: 1em;
            padding: 0.5em 1.75em;
            width: 100%;

            .cbg-colorCard__text {
              font-size: 1em;
              letter-spacing: initial;
              font-weight: bold;
            }
          }
        }
      }
    }

    .project-room-card__link {
      display: flex;
      justify-content: center;
      padding: 10px;

      .project-room-card__text {
        box-sizing: border-box;
        border-radius: 19.3588px;
        padding: 0.15em 1.75em;
        color: black;
        font-weight: 500;
        font-size: 1em;
      }

      .project-room-card__btn {
        border: 1px solid #1c1c1c;
        text-decoration: none;
      }
    }
  }
}
