.pdf-sharing {
  position: absolute;
  left: rem(-999999px);
  pointer-events: none;
}

.image-area:has(.cmp-billboard__sharing-button) {
  position: relative;
}

.cmp-billboard__sharing-button,
.cmp-colordetail__sharing-button {
  background-image: url("../resources/images/svgs/share.svg");
  background-repeat: no-repeat;
  background-position: 50% 30%;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 1.25rem;
  z-index: 1;
}

.cmp-billboard__sharing-button {
  background-color: $platform-white;
  top: 2rem;
  @media screen and (min-width: $screen-md) {
    top: 1rem;
  }
}

.cmp-colordetail__sharing-button {
  border-radius: 0;
  background-color: transparent;
  height: 2rem;
  width: 2rem;
  background-size: cover;

  @media screen and (min-width: $screen-md) {
    height: 2.25rem;
    width: 2.25rem;
  }
}

.pdf-colordetail {
  display: flex;
  flex-direction: column;

  button,
  .cmp-dynamicCard__card-current-msg-pdf {
    display: none;
  }

  .logo-container-pdf {
    height: 80px;
    position: relative;
    background-color: $platform-dark-blue;

    .cmp-image__image-pdf {
      max-width: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .top-half-pdf {
    padding: 16px;
    display: flex;
    gap: 16px;
    height: 55%;

    .featured-color-pdf,
    .dynamicFlexContainer-pdf {
      flex-basis: 50%;
    }
  }

  .each-row-pdf,
  .featured-color-pdf,
  .cbg-cmp-dynamicFlexContainer-pdf.coordinating-colors-pdf {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card-hex-div-pdf,
  .card-holder-pdf,
  .cmp-dynamicCard__parentContainer-pdf,
  .cmp-dynamicCard__card-container-pdf,
  .cmp-dynamicCard__image-container-pdf {
    height: 100%;
  }

  .coordinating-colors-pdf .each-row-pdf {
    gap: 16px;
  }

  .cmp-dynamicFlexContainer__title-pdf {
    font-size: 16px;
    color: $platform-black;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-family: $font-ibm;
  }

  .color-card-pdf {
    flex-grow: 1;
  }

  .cmp-dynamicCard__link-pdf {
    text-decoration: none;
  }

  .cmp-dynamicCard__card-info-container-pdf {
    padding: 8px 16px;
    font-family: $font-ibm-medium;
    font-weight: 700;
    line-height: 1.25;
  }

  .cmp-dynamicCard__card-color-name-pdf,
  .cmp-dynamicCard__card-color-id-pdf {
    font-size: 16px;
  }

  .cmp-dynamicCard__card-color-name-pdf {
    font-weight: 700;
  }

  .cmp-dynamicCard__card-color-id-pdf {
    font-weight: 300;
    font-style: italic;
  }

  .cmp-dynamicCard__card-color-id-pdf,
  .disclaimer-pdf {
    margin: 0;
  }

  .related-shades {
    flex: 1;
  }

  .related-colors-pdf {
    &.cbg-cmp-dynamicFlexContainer-pdf {
      padding: 16px;
    }

    .cmp-dynamicCard__card-info-container-pdf {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .each-row-pdf {
      gap: 8px;
    }
  }

  .disclaimer-pdf {
    padding: 0 16px 16px;
    font-style: italic;
    color: $gray-60;
  }
}

.pdf-inspiration {
  .cmp-billboard__sharing-button-pdf {
    display: none;
  }

  .logo-container-pdf {
    text-align: center;

    .cmp-image__image-pdf {
      display: inline;
      max-width: 35%;
      padding: 20px;
    }
  }

  .featured-color-heading-pdf {
    color: $platform-black;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 20px 0 10px 20px;
    text-transform: uppercase;
    text-align: left;
  }

  .desktop-billboard-image-pdf.billboard-parsys-pdf {
    text-align: center;

    img {
      display: inline;
    }
  }

  .cbg-cmp-dynamicFlexContainer-pdf {
    margin: 0 20px;

    .cmp-dynamicFlexContainer__title-pdf {
      color: $platform-black;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 14.06px;
      margin: 20px 0px 10px;
      text-transform: uppercase;
    }

    .each-row-pdf {
      display: flex;
      justify-content: space-between;

      .cbg-favorite-cta-pdf {
        display: none;
      }

      .cmp-dynamicFlexContainer__card-item-pdf.card-item-pdf.color-card-pdf {
        flex: 1;
        margin: 0 5px;
        text-align: left;

        .cmp-dynamicCard__image-container-pdf {
          border-radius: 0;
          width: 100%;

          .cmp-dynamicCard__card-info-container-pdf {
            color: $platform-black;

            h4.cmp-dynamicCard__card-color-name-pdf {
              font-size: 14px;
              font-weight: 700;
              padding-top: 10px;
            }

            h5.cmp-dynamicCard__card-color-id-pdf {
              font-size: 14px;
              font-style: italic;
              font-weight: 400;
              margin-top: 0;
            }
          }
        }
        .cmp-dynamicCard__card-color-swatch-pdf {
          height: 110px;
        }
      }
    }
  }

  .disclaimer-pdf {
    font-size: 12px;
    font-style: italic;
    color: $platform-black;
    margin: 0 20px;
  }
}

.valspar-container .logo-container-pdf {
  background-color: $platform-text-color-blue;
}

.hgsw-container .logo-container-pdf {
  background-color: $hgsw-darkblue;
}
