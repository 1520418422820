@media screen and (max-width: $tablet-breakpoint) {
  body.nav-open {
    overflow: hidden;
    height: 100vh;
  }
}

.cmp-experiencefragment--header .navigation .cmp-navigation-container {
  height: 100%;
  align-items: center;
  display: flex;
  z-index: 200;

  nav {
    display: none;
    opacity: 0;

    @media screen and (min-width: $desktop-breakpoint) {
      display: block;
      opacity: 1;

      > ul {
        height: $global-nav-height;

        li a {
          padding: 26px 25px;
        }
      }
    }

    > ul {
      display: flex;
    }

    ~ .cmp-navigation__overlay {
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.7);
      top: $global-nav-height;
      bottom: 0;
      left: 0;
      right: 0;

      opacity: 0;
      z-index: $z-level0;

      @media screen and (max-width: $screen-md) {
        top: 130px;
      }
    }

    > .cmp-container {
      height: auto;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    li a {
      font-size: 16px;
      font-weight: 500;
      height: 45px;
      padding: 12px 25px;
      display: flex;
      border: none;
      letter-spacing: 1px;

      > span {
        pointer-events: none;
      }
    }

    @media screen and (max-width: $tablet-breakpoint) {
      li.cmp-navigation__item--level-0 {
        > a:after {
          display: inline-block;
          position: absolute;
          right: 20px;
          margin-top: 6px;
          margin-left: 8px;
          vertical-align: 4px;
          content: " ";
          border: 2px solid $platform-dark-gray;
          border-left: none;
          border-top: none;
          transform: rotate(315deg);
          width: 6px;
          height: 6px;
          letter-spacing: 1.5px;
          transform-origin: 50% 75%;
          transition: transform 125ms ease-out;
        }
      }
    }
  }

  // Navigation Subnav
  .cmp-navigation__item--level-0 {
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    float: left;

    // Desktop
    @media screen and (min-width: $desktop-breakpoint) {
      position: relative;
    }

    > .cmp-navigation__item-link {
      padding-right: 1rem;
      padding-left: 25px;
      margin-right: 30px;
      transition: background 500ms ease-out;

      // Desktop
      @media screen and (min-width: $desktop-breakpoint) {
        // Angle Down icon
        span {
          pointer-events: none;
          padding: 2px;
          flex-wrap: nowrap;
          display: flex;
          align-items: center;

          &:after {
            display: inline-block;
            margin-left: 8px;
            vertical-align: 4px;
            content: " ";
            border: 2px solid $platform-dark-gray;
            border-left: none;
            border-top: none;
            transform: rotate(45deg);
            width: 6px;
            height: 6px;
            letter-spacing: 1.5px;
            transform-origin: 50% 75%;
            transition: transform 125ms ease-out;
          }
        }
      }

      @media screen and (max-width: 960px) {
        margin-right: 1rem;
      }

      @media screen and (max-width: 930px) {
        margin-right: 0;
        padding-right: 2px;
      }
    }

    // Subnav container styles
    .cmp-navigation__subnav {
      display: none;
      background-color: $platform-white;
      box-shadow: 0 12px 20px 0 rgba($box-shadow-gray, $box-shadow-opacity);
      z-index: $z-level3;
      opacity: 0;
      top: $global-nav-height;

      li a {
        line-height: 54px;
        height: auto;
        padding: 0 32px;
      }
    }

    // END: Subnav container styles

    &.active {
      // Active state for caret on top level links
      > .cmp-navigation__item-link {
        span:after {
          transform: rotate(225deg);
        }
      }

      // END: Active state for caret on top level links

      // Active state for subnav
      .cmp-navigation__subnav {
        display: block;
        opacity: 1;
        position: absolute;
        width: 100%;

        @media screen and (min-width: $desktop-breakpoint) {
          width: max-content;
        }
      }

      // END: Active state for subnav
    }
  }

  // END: Navigation Subnav

  // Navigation Buttons
  .btn-navigation-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 45px;
    height: 45px;

    background-color: transparent;

    padding: 0;
    margin: 0 auto;

    cursor: pointer;

    .icon {
      transition: opacity 250ms ease-in;
      width: calc(100% - 27px);
      height: 2px;
      background-color: $platform-gray-text;
      pointer-events: none;
    }

    .cmp-nav-menubar__icon {
      display: block;
      margin-bottom: 4px;
      opacity: 1;

      &:first-of-type {
        margin-top: 4px;
      }
    }

    .cmp-nav-close__icon {
      display: block;
      transform: rotate(45deg) translate(-8px, -8px);
      opacity: 0;

      &:after {
        display: block;
        content: " ";
        width: 100%;
        height: 100%;
        background-color: inherit;
        transform: rotate(90deg);
      }
    }

    @media screen and (min-width: $desktop-breakpoint) {
      display: none;
    }
  }
  // END: Navigation Buttons

  // Mobile Navigation Open State
  @media screen and (max-width: $tablet-breakpoint) {
    &.nav-open {
      nav {
        display: block;
        position: absolute;
        top: $global-nav-height;
        left: 0;
        right: 0;
        z-index: $z-level5;

        width: calc(100% - 90px);
        height: 100vh;
        min-width: 280px;
        max-width: 360px;
        overflow-y: auto;

        color: white;

        box-shadow: inset 0 12px 20px 0
          rgba($box-shadow-gray, $box-shadow-opacity);

        animation: $animation_slide_in_left;

        @media screen and (max-width: $screen-md) {
          top: 80px;
        }

        > ul {
          flex-direction: column;
          width: 100%;
          background: $platform-cream;

          .cmp-navigation__item--level-0 {
            position: static;
          }
        }

        ~ .cmp-navigation__overlay {
          position: fixed;

          animation: $animation_slide_in_left;
          pointer-events: all;
          z-index: $z-level3;
        }
      }

      .btn-navigation-menu {
        .cmp-nav-menubar__icon {
          opacity: 0;
        }

        .cmp-nav-close__icon {
          opacity: 1;
        }
      }
    }

    &.subnav-open {
      nav {
        background-color: transparent;
        transition: background-color 125ms;
        transition-delay: 2 * $anim_std_duration;
      }

      nav > ul {
        animation: $animation_slide_out_left;
        transform: translateX(calc(-100%));

        li.cmp-navigation__item--level-0.active {
          position: static;

          .cmp-navigation__subnav {
            display: block;
            opacity: 0;
            background-color: $platform-cream;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            width: 100%;
            animation: $animation_slide_in_right_alt;
            animation-delay: $anim_std_duration;

            ul {
              height: calc(100vh - #{$global-nav-height});
            }
          }
        }
      }

      .utilityNavigation {
        animation: $animation_slide_out_left;
        transform: translateX(calc(-102%));
      }
    }
  }
  // END: Mobile Navigation Open State
}

.cmp-navigation__item-back-button {
  height: 45px;

  background-color: $platform-dark-gray;
  color: $platform-white;

  font-size: 16px;
  font-weight: normal;
  line-height: 45px;
  text-align: center;

  &:before {
    display: inline-block;
    position: absolute;
    left: 20px;
    margin-top: 15px;
    margin-left: 8px;
    content: " ";
    border: 2px solid $platform-white;
    border-left: none;
    border-top: none;
    transform: rotate(135deg);
    width: 6px;
    height: 6px;
    letter-spacing: 1.5px;
    transform-origin: 50% 75%;
    transition: transform 125ms ease-out;
    cursor: pointer;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    display: none;
  }

  span {
    pointer-events: none;
  }
}

body.cart-modal--active {
  margin-top: $global-nav-height;

  @media screen and (max-width: $tablet-breakpoint) {
    position: fixed;
    width: 100%;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    overflow: hidden;
  }

  .cmp-experiencefragment--header {
    animation: $animation_slide_down;
    animation-delay: 250ms;
    pointer-events: all;
    position: fixed;
    background-color: white;
    opacity: 0;
    width: 100%;
    top: 0;
    z-index: $z-level3;
  }
}

body.independent-retailer li.suppressIR,
body.lowes li.suppressLowes {
  display: none;
}
