.cbg-cmp-search-results__container
  .cbg-cmp-search-results__content-container.cbg-cmp-search-results__content-container-color {
  flex-wrap: wrap;
  grid-gap: 25px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 157px);

  &.active {
    display: grid;
  }

  @media screen and (max-width: $screen-md) {
    justify-content: center;
  }

  .cbg-cmp-searchresult--color.cbg-cmp-card__swatch-container {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    border: 2px solid #f6f8f8;
    min-width: 140px;
    width: 161px;
    transform: none;

    a {
      text-decoration: none;
    }

    @media screen and (max-width: $screen-xs) {
      max-height: none;
    }
  }

  .cbg-color-swatch {
    @media screen and (max-width: $screen-xs) {
      max-width: none;
      max-height: none;
    }

    img {
      width: 100%;
    }
  }

  .cbg-colorCard__color-name {
    font-size: 16px;
    line-height: 20px;
  }

  .cbg-colorCard__color-id {
    color: #5b6770;
    font-family: $font-ibm;
    font-size: 12px;
    line-height: 15px;
  }

  .cbg-cmp--tag-emblem-inner {
    //padding: 0 8px;
    font-weight: 500;
    letter-spacing: 1.67px;
  }
}
