.gif-container {
  label {
    &.clicktogif {
      background: white no-repeat center center;
      display: flex;
      float: left;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $screen-md) {
        width: 100%;
      }

      &:hover {
        background-color: lightgray;
      }

      .gif-image {
        display: block;
        height: 250px;
      }

      .checkBox[type="checkbox"] {
        position: absolute;
        appearance: none;
        border: 0;

        &:focus {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          outline: 2px solid lightgray;
        }
      }

      .checkBox[type="checkbox"] + .gif-image {
        opacity: 0;
      }

      .checkBox[type="checkbox"]:checked + .gif-image {
        opacity: 1;
        z-index: 10;
        position: relative;
      }

      &.hide-gif-image {
        display: none;
      }
    }
  }

  .gifIcon {
    position: absolute;
    z-index: 1;
    animation: cssAnimation 0s 5s forwards;
    visibility: hidden;
    height: 50px;
    width: 50px;

    @media screen and (max-width: $screen-md) {
      padding: 0;
    }
  }

  @keyframes cssAnimation {
    to {
      visibility: visible;
    }
  }

  .gif-placeholder {
    position: absolute;
    z-index: 0;
    height: 250px;
    width: auto;
  }
}
