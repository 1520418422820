$card-padding: 5px;
.add-gutter .cmp-dynamicFlexContainer__header-container,
.add-gutter .cmp-dynamicFlexContainer__card-container {
  @media screen and (min-width: $screen-md) {
    margin: 15px auto;
    width: rem(1220px);
    padding-left: rem(25px);
  }
}

.cbg-cmp-dynamicFlexContainer {
  display: flex;
  flex-direction: column;
  padding: rem(20px 0 20px 20px);

  .hide-card {
    display: none;
  }

  .view-more-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 15%;
    background-color: $cement-gray;

    &.hide-card {
      display: none;
    }

    .view-more-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .view-more-title {
      font-size: $font-size;
      line-height: 0px;
      margin-top: 20px;
    }

    svg {
      width: 45px;
      height: 45px;
      margin: auto;
    }
  }

  a.card-item {
    .view-more-section {
      flex: 100%;
    }
  }

  @media screen and (min-width: $screen-md) {
    padding: rem(20px 0);
  }

  .cmp-dynamicFlexContainer__card-container {
    &.isMobileHorizontalScroll {
      .each-row {
        display: flex;
        overflow-x: auto;
        @media screen and (max-width: $screen-md) {
          display: flex;
        }
      }
    }
  }
}

.card-container-home .dynamicFlexContainer.grid-layout .each-row {
  flex-direction: row;
}

.dynamicFlexContainer.grid-layout {
  .cbg-cmp-dynamicFlexContainer.column-count- {
    .cmp-dynamicFlexContainer__card-container {
      .each-row {
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: stretch;
        align-content: flex-start;
        gap: rem(10px);

        .cmp-dynamicFlexContainer__card-item {
          &.card-item {
            flex-basis: auto;
          }
        }
      }
    }
  }
}

.dynamicFlexContainer.card-size-xs {
  .cbg-cmp-dynamicFlexContainer.column-count- {
    .cmp-dynamicFlexContainer__card-container {
      .each-row {
        .cmp-dynamicFlexContainer__card-item {
          &.card-item {
            &.color-card {
              .cmp-dynamicCard__parentContainer {
                width: 6rem;

                .cmp-dynamicCard__card-container {
                  .cmp-dynamicCard__image-container,
                  .cmp-dynamicCard__card-color-swatch {
                    width: 6rem;
                    height: 6rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dynamicFlexContainer.card-size-s {
  .cbg-cmp-dynamicFlexContainer.column-count- {
    .cmp-dynamicFlexContainer__card-container {
      .each-row {
        .cmp-dynamicFlexContainer__card-item {
          &.card-item {
            &.color-card {
              .cmp-dynamicCard__parentContainer {
                width: 7rem;

                .cmp-dynamicCard__card-container {
                  .cmp-dynamicCard__image-container,
                  .cmp-dynamicCard__card-color-swatch {
                    width: 7rem;
                    height: 7rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dynamicFlexContainer.card-size-m {
  .cbg-cmp-dynamicFlexContainer.column-count- {
    .cmp-dynamicFlexContainer__card-container {
      .each-row {
        .cmp-dynamicFlexContainer__card-item {
          &.card-item {
            &.color-card {
              .cmp-dynamicCard__parentContainer {
                width: 9rem;

                .cmp-dynamicCard__card-container {
                  .cmp-dynamicCard__image-container,
                  .cmp-dynamicCard__card-color-swatch {
                    width: 9rem;
                    height: 9rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cmp-dynamicFlexContainer__card-container.each-row.horizontal-container {
  display: flex !important;
  flex-direction: row;
  overflow-x: auto !important;
  align-items: stretch;

  @media screen and (min-width: $screen-md) {
    padding-left: 25px;
  }
}

.cmp-dynamicCard__container.cbg-cmp-card.each-card {
  margin: 15px 0;
}

.cbg-cmp-dynamicFlexContainer.column-count-.add-gutter.related-colors {
  .cmp-dynamicCard__container.cbg-cmp-card.each-card {
    margin-right: 0px;
    display: none;
  }

  .each-row {
    justify-content: center;
    .cmp-dynamicFlexContainer__card-item.card-item.color-card {
      max-width: 12.6875rem;
      margin-right: rem(6.5px);
      .cmp-dynamicCard__parentContainer {
        .cmp-dynamicCard__card-container {
          max-width: 12.6875rem;
          width: auto;
        }
        .cmp-dynamicCard__link {
          display: block;
        }
      }
      .cmp-dynamicCard__card-footer {
        .cmp-dynamicCard__cart-btn.cart-cta--button {
          width: 100%;
          border: 1px solid #d8dce8;
          margin: rem(-1px 0 0);
          padding: rem(14px 5px 14px 1px);
          display: flex;
          justify-content: center;

          &:hover {
            border-color: #081329;
          }

          &:focus {
            outline: 4px solid #d8dce8;
          }

          .cart-cta--icon {
            background-position: center center;
            padding: 0;
            transform: translateY(-1px);
          }
        }
      }
    }
  }
}

.cmp-dynamicFlexContainer__card-container.each-row {
  display: flex;
  margin: 0 auto;
  align-items: center;
  gap: rem(23px);
  flex-direction: column;
  padding: 0 12px 0 0;

  @media screen and (min-width: $screen-md) {
    flex-direction: row;
    padding: 0 0 0 27px;
  }
}

.cmp-dynamicFlexContainer__card-item .color-card {
  text-align: left;
}

.cmp-dynamicFlexContainer__card-item .product-card {
  max-width: 300px;
  margin: 20px;
}

.product-card .cbg-cmp-card__text-area {
  text-align: center;
}

//Product card styles

.cbg-cmp-dynamicFlexContainer.column-count-.related-products {
  padding: rem(0 10px);
}

.cmp-dynamicFlexContainer__card-container.each-row.product-row {
  padding: 0;

  @media screen and (min-width: $screen-md) {
    padding-left: rem(27px);
    justify-content: flex-start;
  }
}

.cmp-dynamicFlexContainer__card-item.card-item.product-card {
  flex-grow: 0;
  padding-right: 0;
  margin-right: 15px;
  margin-bottom: 15px;
  @media screen and (min-width: $screen-md) {
    margin-bottom: 0;
  }
}

.product-card .cmp-dynamicCard__container.cbg-cmp-card.each-card {
  width: rem(335px);
  height: rem(88px);

  @media screen and (min-width: $screen-md) {
    width: rem(273px);
    height: rem(315px);
  }
}

.product-card .cmp-dynamicCard__container .cmp-dynamicCard__product-card {
  width: 100%;
}

// This section is only for Lowes Inspiration style modifications (Valspar/HGSW)
.valspar-container.lowes-inspiration,
.hgsw-container.lowes-inspiration {
  .dynamicFlexContainer {
    .cbg-cmp-dynamicFlexContainer {
      .cmp-dynamicFlexContainer__title {
        font-size: 1.5rem;
        font-family: $font-ibm-regular;
        font-weight: 400;
        text-align: left;
      }

      .cmp-dynamicFlexContainer__card-container {
        &.isMobileHorizontalScroll {
          .each-row {
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: stretch;
            gap: rem(5px);
          }
        }
      }

      .cmp-dynamicFlexContainer__card-item {
        &.card-item {
          &.color-card {
            width: auto;
            max-width: unset;
            margin-right: 15px;

            .cmp-dynamicCard__parentContainer {
              width: 6rem;

              @media screen and (min-width: 400px) {
                width: 7rem;
              }

              @media screen and (min-width: 480px) {
                width: 9rem;
              }

              .cmp-dynamicCard__container.each-card {
                border: none;
              }

              .cmp-dynamicCard__card-container {
                width: 95%;
                max-width: unset;
                flex: 1 0 auto;
                border: 2px solid $platform-cream;

                .cmp-dynamicCard__card-color-swatch {
                  height: 6rem;
                  max-height: unset;
                  clip-path: none;

                  @media screen and (min-width: 480px) {
                    height: 8rem;
                  }
                }

                .cmp-dynamicCard__card-info-container {
                  text-align: left;

                  @media screen and (max-width: 480px) {
                    padding-left: 0.3rem;
                    padding-right: 0.3rem;
                  }

                  .cmp-dynamicCard__card-color-name,
                  .cmp-dynamicCard__card-color-id {
                    font-size: 0.75rem;
                    font-family: $font-ibm-regular;

                    @media screen and (min-width: 400px) {
                      font-size: 0.85rem;
                    }

                    @media screen and (min-width: $screen-md) {
                      font-size: 1rem;
                    }
                  }

                  .cmp-dynamicCard__card-color-name {
                    font-style: normal;
                  }

                  .cmp-dynamicCard__card-color-id {
                    font-style: italic;
                    color: $platform-gray-text;
                  }
                }
              }
            }

            @media screen and (min-width: $screen-md) {
              flex: 0 0 0%;
              width: 100%;
              max-width: unset;
            }
          }
        }

        .cmp-dynamicCard__link {
          height: 100%;
        }
      }

      @media screen and (max-width: $screen-md) {
        .cmp-dynamicFlexContainer__card-container {
          &.isMobileHorizontalScroll {
            .each-row {
              overflow-x: auto;
              justify-content: flex-start;
              flex-wrap: wrap;

              .cmp-dynamicFlexContainer__card-item.card-item {
                flex: 0 0 0%;
                margin-right: 0px;
              }
            }
          }

          &.isDefault {
            .each-row {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}

// End styles for Lowes Inspiration

.dynamicFlexContainer {
  .cbg-cmp-dynamicFlexContainer {
    .each-row {
      flex-wrap: wrap;

      .card-item {
        padding-bottom: calc(#{$card-padding} * 5);
        margin-right: 0px;
      }

      &.small-margin .card-item {
        padding-right: calc(#{$card-padding} * 1);
      }

      &.medium-margin .card-item {
        padding-right: calc(#{$card-padding} * 2);
      }

      &.large-margin .card-item {
        padding-right: calc(#{$card-padding} * 3);
      }

      &.xl-margin .card-item {
        padding-right: calc(#{$card-padding} * 4);
      }
    }

    &.column-count-2 {
      .each-row {
        .card-item {
          flex: 0 0 47%;
        }
      }
    }

    &.column-count-3 {
      .each-row {
        .card-item {
          flex: 0 0 31%;
        }
      }
    }

    &.column-count-4 {
      .each-row {
        .card-item {
          flex: 0 0 24%;
        }
      }
    }

    &.column-count-5 {
      .each-row {
        .card-item {
          flex: 0 0 19%;
        }
      }
    }

    &.column-count-6 {
      .each-row {
        .card-item {
          flex: 0 0 14%;
        }
      }
    }

    &.column-count-,
    &.column-count-0 {
      .each-row {
        display: flex;
        overflow-x: auto;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: rem(23px);
      }
    }

    @media screen and (max-width: $screen-md) {
      .cmp-dynamicFlexContainer__card-container {
        .each-row {
          display: flex;
          flex-wrap: wrap;
        }

        &.isMobileOneColumn {
          .each-row {
            justify-content: center;
          }

          .cmp-dynamicFlexContainer__card-item.card-item {
            flex: 0 0 51%;
          }
        }

        &.isMobileTwoColumn {
          .each-row {
            justify-content: space-between;
          }

          .cmp-dynamicFlexContainer__card-item.card-item {
            flex: 0 0 48%;
            padding: 20px 0px;
          }
        }

        &.isDefault {
          .each-row {
            justify-content: space-between;
          }

          .cmp-dynamicFlexContainer__card-item.card-item {
            flex: 0 0 32%;
            padding: 20px 0px;
          }
        }

        &.isMobileHorizontalScroll {
          .each-row {
            overflow-x: auto;
            justify-content: flex-start;
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
}
