.languageToggle {
  padding-top: 2.4rem;

  .language-toggle-home {
    .cmp-form-options__field--drop-down {
      background-image: $icon-dropdown;
      background-repeat: no-repeat;
      background-position: center right 12px;
      cursor: pointer;
      appearance: none;
      background-size: 20px 22px;
      font-size: $font-size;
      line-height: 20px;
      letter-spacing: 0.5px;
      padding: 11px 10px 10px 20px;
      position: relative;
      outline: none;
    }

    .cmp-form-options--drop-down {
      display: flex;
      flex-direction: column;
      .before-icon,
      .after-icon {
        align-self: center;
        height: 20px;
        width: 20px;
        z-index: 1;
      }
    }

    .left-icon-showing {
      padding-left: 32px;
    }

    .right-icon-showing {
      background-image: none;
    }

    .field-toggle-list {
      margin: 12px 0;
    }

    .no-bg-img {
      background-image: none;
    }
  }

  .cmp-form-options__label {
    font-size: $font-size;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 20px;
    @media screen and (min-width: $screen-sm) {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 26.1px;
    }
  }

  .before-icon {
    z-index: 1;
    position: relative;
    top: 54px;
    left: 5px;
    display: block;
    padding: 0;
    height: 20px;
    width: 20px;
  }

  .after-icon {
    z-index: 1;
    position: relative;
    left: 70%;
    top: 34px;
    display: block;
    padding: 0;
    height: 20px;
    width: 20px;
  }

  .relative-position {
    position: relative;
  }
}
