.grid--wall__item.grid--wall__item-product {
  flex-direction: column;
  display: none;
  opacity: 0;
  visibility: collapse;

  transition: all 0.5s ease-in;

  &.grid--wall__item-active {
    &.visible {
      opacity: 1;
      display: flex;
      visibility: visible;
    }
  }

  .img-container {
    max-width: 100%;

    position: relative;
    align-self: center;
    align-items: center;
    display: flex;
    .button-link {
      outline-offset: -1px;
    }
    img {
      max-width: 360px;
    }

    @media screen and (max-width: $screen-xs) {
      img {
        max-width: 240px;
      }
    }

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      align-self: initial;
      width: 100%;
      max-width: 274px;
      max-height: 400px;

      img {
        max-width: 100%;
      }
    }
    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    margin-top: 2px;

    ul {
      display: block;
      margin-left: -1em;

      li {
        text-indent: unset;
        padding-left: 0.5rem;
        @media screen and (min-width: $screen-md-lg) {
          max-width: 25rem;
          font-family: $font-ibm-regular;
        }
      }
    }

    .learn-more {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding-top: 1.3rem;

      @media screen and (min-width: $screen-md) {
        flex-direction: row;
      }
    }

    .button-link {
      text-decoration: none;
      margin-left: 0;
    }

    .button-home {
      font-size: rem(14px);
      padding: 0.8rem;

      @media screen and (min-width: $screen-sm-md) {
        padding: rem(8px 30px);
      }

      @media screen and (min-width: $screen-md) {
        margin-bottom: 1.3rem;
      }
    }

    .more-features span:nth-child(2) {
      @media screen and (min-width: $screen-md-lg) {
        display: inline-block;
      }
    }

    h4 {
      @media screen and (min-width: $screen-md-lg) {
        display: inline-block;
      }
    }

    ul,
    p {
      display: inline-block;
      font-family: $font-ibm-regular;
    }

    @media screen and (min-width: $screen-lg) {
      margin-left: 1.5rem;
    }
  }

  .grid--wall__image-tag {
    $icon-size: 2.9rem;
    border-radius: 1.5rem;
    width: $icon-size;
    height: $icon-size;
    font-family: $font-ibm;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    line-height: $icon-size;
  }

  .grid--wall__image-tag-new {
    background-color: $platform-dark-gray;
    color: $platform-white;
  }

  .grid--wall__image-tag-pro {
    border: 2px solid $platform-dark-gray;
    color: $platform-dark-gray;
  }

  @media screen and (min-width: $screen-lg) {
    flex-direction: row;
  }
}
