.dynamicPdpColorChips {
  .dynamic-pdp-container {
    .tabs {
      margin-bottom: 20px;
    }

    .tab-list {
      list-style: none;
      padding: 0;
      display: flex;
    }

    .tab-list li {
      margin-right: 10px;
    }

    .tab-list a {
      text-decoration: none;
      padding: 10px 15px;
      display: block;
      border: 1px solid #ccc;
      border-bottom: none;
      background: #f9f9f9;
      color: #333;
    }

    .tab-list a.active {
      background: #fff;
    }

    .tab-pane {
      display: none;
    }

    .tab-pane.active {
      display: block;

      &.color-cards-container {
        margin: auto;
        width: 100%;

        .color-group {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
          gap: 20px;

          .color-card {
            text-align: left;

            .color-image {
              img {
                height: 200px;
              }
            }

            .color-name {
              color: #000000;
            }
          }
        }
      }
    }
  }
}
