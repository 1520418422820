.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0; // Start hidden off-screen
  width: 100%; // Wrapper should take full screen width for the overlay effect
  height: 100%;
  display: flex;
  justify-content: flex-end; // Position the slider on the right
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out; // Smooth transition effect for both transform and opacity
  z-index: 1000;
  pointer-events: none; // Prevent interaction when hidden
  opacity: 0;

  &.open {
    opacity: 1;
    pointer-events: auto; // Enable interaction when opened
  }

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Semi-transparent black overlay
    cursor: pointer; // Indicate it's clickable
  }

  .modal-slider {
    position: relative;
    min-width: 500px;
    width: 33.33%;
    height: 100%;
    background: #fff; // White background for the slider content
    transform: translateX(100%); // Start off-screen (hidden)
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    &.open {
      transform: translateX(0); // Slide in to view
    }
    @media screen and (max-width: $screen-slide-md) {
      min-width: 100%;
      width: 100%;
    }

    .slider-header {
      display: flex;
      padding: 1rem;
      background-color: #e6e8e9;
      justify-content: space-between;
      align-items: center;
      box-shadow: 1px 10px 8px -11px rgba(0, 0, 0, 0.75);

      .slider-title {
        font-size: 1.2rem;
        padding-left: 0.5rem;
        letter-spacing: 3px;
        font-family: $font-ibm;
      }

      .default-close-icon {
        background-image: url("../resources/images/svgs/field-close.svg");
        width: 0.75rem;
        height: 0.75rem;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .slider-body {
      height: inherit;

      .slider-iframe-container {
        height: inherit;
      }

      .slider-iframe {
        width: 100%;
        height: 100%;
      }

      .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }
    }
  }
}

.modal__fullscreen {
  overflow: hidden;
}

.slider-iframe-class {
  padding-bottom: 3rem;

  .cmp-container .experiencefragment {
    display: none;
  }
}
