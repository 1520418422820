.banner-home {
  color: $platform-font-light-scheme;

  .banner-gutter-wrapper {
    @media screen and (max-width: $screen-md) {
      flex-direction: column;
    }
  }

  // Banner Text Elements Classes
  .banner-title {
    font-family: $font-ibm-regular;
    letter-spacing: 1.3px;

    &.quote-layout {
      .first-title,
      .accent-title,
      .second-title {
        display: block;
      }
    }

    @media screen and (min-width: $screen-md) {
      font-weight: normal;
      letter-spacing: 0;
      padding: 10px 0;
    }
  }

  .banner-description {
    letter-spacing: 0;
    @media screen and (min-width: $screen-lg) {
      letter-spacing: 0;
      padding: 10px 0;
      width: 100%;
    }
  }

  &.banner-textbox-left {
    .pinterest-home {
      @media screen and (min-width: $screen-md-lg) {
        justify-content: flex-end;
      }
    }

    .banner-text-container {
      padding: rem(40px 20px 40px 20px);
      width: 100%;

      @media screen and (min-width: $screen-md) {
        margin-left: 5%;
        width: 40%;
      }
      @media screen and (min-width: $screen-lg) {
        margin-left: 10%;
      }
    }

    .banner-title {
      max-width: 34rem;
    }

    .desktop-banner-image {
      @media screen and (min-width: $screen-md) {
        width: 75%;
      }
      @media screen and (min-width: $screen-md-lg) {
        width: 60%;
      }
      @media screen and (min-width: $screen-lg) {
        width: 50%;
      }
    }

    .banner-description {
      @media screen and (min-width: $screen-lg) {
        max-width: 33rem;
      }
    }

    .banner-description,
    .banner-title,
    .banner-subtitle,
    .banner-button-zone {
      margin: 10px;
      margin-top: 0;
      @media screen and (min-width: $screen-md) {
        margin: 0;
      }
      &.hideElement {
        display: none;
      }
    }
  }

  &.banner-textbox-right {
    .pinterest-home {
      @media screen and (min-width: $screen-md-lg) {
        justify-content: flex-end;
        right: 50%;
      }
    }
    .banner-text-container {
      padding: rem(40px 20px 40px 20px);
      width: 100%;

      @media screen and (min-width: $screen-md) {
        margin: 1em 3em 1em 2em;
        padding: 0;
        width: 40%;
      }

      @media screen and (min-width: $screen-lg) {
        margin: 2em 2em 2em 4em;
        padding: 0;
      }
    }

    .banner-title {
      max-width: 34rem;
    }

    .desktop-banner-image {
      @media screen and (min-width: $screen-md) {
        width: 75%;
      }
      @media screen and (min-width: $screen-md-lg) {
        width: 60%;
      }
      @media screen and (min-width: $screen-lg) {
        width: 50%;
      }
    }

    .banner-description {
      @media screen and (min-width: $screen-lg) {
        max-width: 33rem;
      }
    }

    .banner-description,
    .banner-title,
    .banner-subtitle,
    .banner-button-zone {
      margin-bottom: 0.5em;
      @media screen and (min-width: $screen-md) {
        margin: 0;
      }
    }
  }
}

// Banner Mobile Image Style Class
.mobile-banner-image {
  display: block;
  margin-bottom: 2em;

  @media screen and (min-width: $screen-md) {
    display: none;
    margin-bottom: 0;
  }
}

// Banner Show Conditional Mobile Image Style Class
.show-image-in-mobile {
  .banner-text-container .banner-button-zone {
    margin-bottom: 0;
  }
}

// Banner Desktop Image Style Class
.desktop-banner-image {
  display: none;
  margin: 0;
  img {
    max-width: 100%;
    display: block;
  }

  @media screen and (min-width: $screen-sm) {
    width: 60%;
    img {
      width: 100%;
    }
  }

  @media screen and (min-width: $screen-md) {
    display: block;
  }

  @media screen and (min-width: $screen-xl) {
    display: block;
    width: 50%;
    img {
      width: 100%;
    }
  }
  @media screen and (min-width: $screen-md) {
    padding: 0;
  }
}

// Banner Text Container Style Class
.banner-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: $screen-md) {
    width: 40%;
    padding: 84px 0;
  }
}

// Banner Quote Style Class
.quote-styling {
  .banner-gutter-wrapper {
    max-width: 60rem;
  }

  .banner-text-container {
    width: 100%;
    @media screen and (min-width: $screen-md) {
      width: 50%;
    }

    .text-segments {
      justify-content: center;
      display: block;
      font-size: $rem-size-H4;
      @media screen and (min-width: $screen-md) {
        display: flex;
        font-size: $rem-size-H4;
      }
      .banner-button-zone {
        .segment {
          font-size: 16px;
          @media screen and (min-width: $screen-sm) {
            font-size: 16px;
          }
        }
      }

      @media screen and (min-width: $screen-md) {
        margin-top: $font-size;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    margin: auto;
  }

  .banner-text-container .banner-subtitle {
    letter-spacing: 0;

    @media screen and (min-width: $screen-md) {
      letter-spacing: 0;
    }
  }
}

.banner-text-container .banner-quote-image {
  @media screen and (min-width: $screen-md) {
    img {
      height: 3.3rem;
      width: 3rem;
    }
  }
}

// Banner Add Gutter Style Class
.add-gutter {
  .banner-home {
    @media screen and (min-width: $screen-sm) {
      width: 100%;
    }
    @media screen and (min-width: $screen-md) {
      margin: auto;
      max-width: 740px;
    }
    @media screen and (min-width: $screen-lg) {
      margin: auto;
      max-width: 1100px;
    }
  }
}

// Banner Button Zone Style Class
.banner-button-zone {
  @media screen and (min-width: $screen-md) {
    padding: 0;
  }

  .list-horizontal {
    flex-flow: row wrap;
    @media screen and (max-width: $screen-sm) {
      flex-flow: column;
    }
  }
}

// Banner Slender Mode Style Class
.banner-slender {
  height: fit-content;
  padding: 1rem 1.3rem;
  font-family: $font-ibm, sans-serif;

  .banner-gutter-wrapper {
    justify-content: center;
    align-items: center;
  }

  .banner-text-container {
    display: block;
    width: 100%;

    @media screen and (min-width: $screen-md) {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .banner-subtitle {
      width: 50%;
      text-align: right;
      margin-right: 10px;

      @media screen and (min-width: $screen-sm) {
        padding: 10px;
      }
    }

    .banner-button-zone {
      @media screen and (min-width: $screen-md) {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    padding: 1rem 0;
  }
}

.banner {
  ////===================================================================================
  ////=============================== Style System Section ==============================
  ////===================================================================================

  &.mobile-image-on-top {
    .banner-gutter-wrapper {
      @media screen and (max-width: $screen-md) {
        flex-direction: column-reverse;
      }
    }
  }

  &.mobile-image-on-bottom {
    .banner-gutter-wrapper {
      @media screen and (max-width: $screen-md) {
        flex-direction: column;
      }
    }
  }

  &.align-text-left {
    .banner-no-image {
      .banner-gutter-wrapper {
        text-align: left;
        justify-content: flex-start;
      }
      .banner-description {
        width: 75%;
      }

      .banner-text-container {
        align-items: flex-start;
        text-align: left;
        .banner-title {
          line-height: 3rem;
          @media screen and (min-width: $screen-md) {
            line-height: 3.8rem;
          }
        }
        @media screen and (min-width: $screen-md) {
          padding: 0;
        }
      }
    }
  }

  &.align-text-center {
    .button--wrapper-inner {
      margin-left: auto;
      margin-right: auto;
    }
    .card-text-only {
      margin-left: auto;
      margin-right: auto;
    }
    .banner-no-image {
      .banner-gutter-wrapper {
        justify-content: center;
        text-align: center;
      }
      .banner-description {
        width: 75%;
      }
      .banner-text-container {
        text-align: center;
        align-items: center;
        .banner-title {
          line-height: 3rem;
          @media screen and (min-width: $screen-md) {
            letter-spacing: 1px;
            line-height: 3.8rem;
          }
        }
        @media screen and (min-width: $screen-md) {
          padding: 0;
        }
      }
    }
  }

  &.align-text-right {
    .banner-no-image {
      .banner-gutter-wrapper {
        justify-content: flex-end;
        text-align: right;
      }
      .banner-description {
        width: 75%;
      }
      .banner-text-container {
        align-items: flex-end;
        text-align: right;
        .banner-title {
          line-height: 3rem;
          @media screen and (min-width: $screen-md) {
            letter-spacing: 1px;
            line-height: 3.8rem;
          }
        }
        @media screen and (min-width: $screen-md) {
          padding: 0;
        }
      }
    }
  }

  &.small-padding {
    @media screen and (min-width: $screen-sm) {
      .banner-quote-image {
        padding: 0 35px;
      }
      .banner-subtitle {
        padding: 0 35px;
      }
      .banner-button-zone {
        padding: 0 35px;
      }
      .banner-description {
        padding: 0 35px;
      }
      .banner-title {
        padding: 0 35px;
      }
    }
  }

  &.medium-padding {
    @media screen and (min-width: $screen-sm) {
      .banner-quote-image {
        padding: 0 45px;
      }
      .banner-subtitle {
        padding: 0 45px;
      }
      .banner-button-zone {
        padding: 0 45px;
      }
      .banner-description {
        padding: 0 45px;
      }
      .banner-title {
        padding: 0 45px;
      }
    }
  }

  &.large-padding {
    @media screen and (min-width: $screen-sm) {
      .banner-quote-image {
        padding: 0 55px;
      }
      .banner-subtitle {
        padding: 0 55px;
      }
      .banner-button-zone {
        padding: 0 55px;
      }
      .banner-description {
        padding: 0 55px;
      }
      .banner-title {
        padding: 0 55px;
      }
    }
  }

  &.text-area-one-third {
    .desktop-image {
      width: 66%;
    }

    .banner-text-container {
      width: 100%;
      @media screen and (min-width: $screen-lg) {
        width: 30%;
      }
    }
  }

  &.text-area-two-third {
    .banner-text-container {
      width: 100%;
      @media screen and (min-width: $screen-lg) {
        width: 70%;
      }
    }
  }

  &.billboard-styling {
    .desktop-image {
      margin-left: 3em;

      img {
        width: 75%;
      }
    }

    .banner-text-container {
      width: 50%;
    }
  }

  &.banner-textbox-left {
    .desktop-image {
      margin-left: 2em;
    }
  }

  &.banner-textbox-right {
    .desktop-image {
      margin-right: 2em;
    }
  }

  ////===================================================================================
  ////=============================== End of Style System Section ==============================
  ////===================================================================================

  .banner-gutter-wrapper {
    width: 100%;
    display: block;

    @media screen and (min-width: $screen-md) {
      display: flex;
    }

    margin: 0 auto;

    .flex-align-top {
      align-items: normal;
    }

    .desktop-image {
      display: none;

      @media screen and (min-width: $screen-lg) {
        display: inline-flex;
        width: 66%;

        img {
          width: 100%;
        }
      }
    }

    @media screen and (min-width: $screen-lg-md) {
      padding: 0;
    }
  }

  .banner-button-zone {
    width: 100%;

    @media screen and (min-width: $screen-md) {
      padding: 10px;
      margin: 0;
      padding-left: 0;
    }
    .each-card {
      &.text-only {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
      }
    }
  }

  .videoplayer .base {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .banner-video-zone .videoplayer iframe {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    @media screen and (min-width: $screen-md) {
      position: absolute;
    }
  }

  .banner-break {
    clear: both;
    display: block;
    width: 100%;
  }

  // Banner No Image Class
  .banner-no-image {
    padding: 2rem 1.3rem;

    .banner-parsys {
      display: none;
    }

    .banner-subtitle {
      padding: 0;
    }

    .banner-title {
      padding: 0;
    }

    .banner-description {
      padding: 0;
      margin: 1.75rem 0 2.4rem 0;
    }

    .banner-button-zone {
      width: 100%;
    }

    .banner-gutter-wrapper {
      max-width: 1220px;
      @media screen and (min-width: $screen-md) {
        padding: 3em;
        padding-left: 36px;
      }
    }

    .banner-text-container {
      width: 100%;
      @media screen and (min-width: $screen-md) {
        width: 60%;
        padding: 3em;
        padding-left: 0;
      }
    }

    @media screen and (min-width: $screen-md) {
      padding: 0;
    }
  }

  .banner-blockquote {
    font-size: 1em;

    @media screen and (min-width: $screen-md) {
      font-size: 2em;
      padding: 2.3rem;
    }

    .image-container {
      margin-top: 2rem;

      @media screen and (min-width: $screen-md) {
        margin-top: 1.3rem;
      }

      img {
        border-radius: 50%;
        display: block;
        height: 13rem;
        margin: auto;
        min-width: 95%;
        width: 13rem;

        @media screen and (min-width: $screen-md) {
          height: 14.4rem;
          min-width: unset;
          width: 14.4rem;
        }

        @media screen and (min-width: $screen-md) {
          height: 1.3rem;
          width: 1.3rem;
        }
      }
    }
  }

  .banner-heading {
    .banner-text-container {
      width: 100%;
    }
  }

  .banner-quote-image {
    display: inline-block;
    &.secondary-as-medallion {
      img {
        width: 60px;
        margin-bottom: 20px;
        border-radius: 50%;
      }
    }

    @media screen and (min-width: $screen-md) {
      display: block;
      padding: 10px;
      img {
        display: inline-block;
      }
    }
  }

  .spacer-window-height-0 {
    .banner-gutter-wrapper {
      .banner-text-container {
        @media screen and (min-width: $screen-md) {
          padding: 0;
        }
      }
    }

    @media screen and (min-width: $screen-md) {
      padding: 0;
    }
  }

  // Banner Window Spacer Classes
  .spacer-window-height-1 {
    @media screen and (min-width: $screen-md) {
      min-height: 1em;
      padding-top: 0;

      .banner-gutter-wrapper {
        .banner-text-container {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .spacer-window-height-2 {
    @media screen and (min-width: $screen-md) {
      min-height: 2em;

      .banner-text-container {
        padding: 0;
        margin: 0;
      }
    }
  }

  .spacer-window-height-4 {
    @media screen and (min-width: $screen-md) {
      min-height: 3em;
      padding-top: 1em;

      .banner-text-container {
        padding: 0;
        margin: 0;
      }
    }
  }

  .spacer-window-height-6 {
    @media screen and (min-width: $screen-md) {
      min-height: 4em;
      padding-top: 2em;

      .banner-text-container {
        padding: 0;
        margin: 0;
      }
    }
  }
  .spacer-window-height-8 {
    @media screen and (min-width: $screen-md) {
      min-height: 6em;
      padding-top: 2em;

      .banner-text-container {
        padding: 0;
        margin: 0;
      }
    }
  }

  .spacer-window-height-10 {
    @media screen and (min-width: $screen-md) {
      min-height: 9em;
      padding-top: 3em;
    }
  }

  .spacer-window-height-12 {
    @media screen and (min-width: $screen-md) {
      min-height: 12em;
      padding-top: 3em;
    }
  }

  .spacer-window-height-14 {
    @media screen and (min-width: $screen-md) {
      min-height: 14em;
      padding-top: 4em;
    }
  }

  .spacer-window-height-16 {
    @media screen and (min-width: $screen-md) {
      min-height: 16em;
      padding-top: 4em;
    }
  }

  .spacer-window-height-18 {
    @media screen and (min-width: $screen-md) {
      min-height: 18em;
      padding-top: 5em;
    }
  }

  .spacer-window-height-20 {
    @media screen and (min-width: $screen-md) {
      min-height: 20em;
      padding-top: 5em;
    }
  }

  .spacer-window-height-22 {
    @media screen and (min-width: $screen-md) {
      min-height: 22em;
      padding-top: 5em;
    }
  }

  .spacer-window-height-24 {
    @media screen and (min-width: $screen-md) {
      min-height: 24em;
      padding-top: 6em;
    }
  }

  .spacer-window-height-26 {
    @media screen and (min-width: $screen-md) {
      min-height: 26em;
      padding-top: 6em;
    }
  }

  .spacer-window-height-28 {
    @media screen and (min-width: $screen-md) {
      min-height: 28em;
      padding-top: 6em;
    }
  }

  .spacer-window-height-30 {
    @media screen and (min-width: $screen-md) {
      min-height: 30em;
      padding-top: 7em;
    }
  }

  .spacer-window-height-32 {
    @media screen and (min-width: $screen-md) {
      min-height: 32em;
      padding-top: 7em;
    }
  }

  .spacer-window-height-34 {
    @media screen and (min-width: $screen-md) {
      min-height: 34em;
      padding-top: 7em;
    }
  }

  .spacer-window-height-36 {
    @media screen and (min-width: $screen-md) {
      min-height: 36em;
      padding-top: 8em;
    }
  }

  .spacer-window-height-38 {
    @media screen and (min-width: $screen-md) {
      min-height: 38em;
      padding-top: 8em;
    }
  }

  .spacer-window-height-40 {
    @media screen and (min-width: $screen-md) {
      min-height: 40em;
      padding-top: 8em;
    }
  }
}

.hideElement {
  display: none;
}

.noVideo {
  width: fit-content;
}

.pinterest-home {
  display: flex;
  height: 0;
  justify-content: flex-end;
  position: relative;
  z-index: 2;

  .pinterest-anchor {
    align-items: center;
    display: flex;
    height: rem(60px);
    justify-content: center;
    width: rem(60px);

    &:hover {
      cursor: pointer;
      stroke: aliceblue;
    }
  }

  .pinterest-icon {
    height: rem(24px);
    pointer-events: none;
    width: rem(24px);

    &:hover {
      cursor: pointer;
      stroke: aliceblue;
    }
  }
}
