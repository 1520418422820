.bannerV2 {
  position: relative;

  a {
    text-decoration: none;

    &.banner-link {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .banner-home {
    background-color: var(--banner-bg-color);

    .banner-gutter-container {
      .videoplayer.base {
        display: flex;
        align-items: center;

        .videoplayer {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;

          iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            min-width: unset;
            min-height: unset;
          }
        }
      }

      @media screen and (max-width: $screen-sm-billboard) {
        flex-direction: column;
      }
    }

    &.text-over-bg:not(.fitImage) {
      min-height: 40rem;
    }
  }

  .banner-home,
  .banner-inner-content {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    width: 100%;
    align-items: center;
  }

  .aem-Grid-reverse {
    flex-direction: row;

    .banner-text-container {
      @media screen and (min-width: $screen-sm-billboard) {
        padding-left: 5vw;
      }
    }
  }

  .text-over-bg {
    justify-content: center;

    .banner-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center bottom;
    }

    .banner-text-container {
      justify-content: center;
      margin: 0;
    }

    .desktop-banner-background {
      display: none;
      @media screen and (min-width: $screen-md) {
        display: block;
        background-position: bottom center;
      }
    }

    .mobile-banner-background {
      display: block;
      @media screen and (min-width: $screen-md) {
        display: none;
        background-position: bottom center;
      }
    }

    &.fitImage {
      .text-over-mobile-bg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      @media screen and (min-width: $screen-md) {
        .banner-inner-content {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .desktop-banner-background,
      .mobile-banner-background {
        width: 100%;
      }
    }

    &.text-under-mobile-bg {
      @media screen and (max-width: $screen-md) {
        display: block;
        text-align: center;
        min-height: unset;

        .banner-text-container {
          padding-top: rem(8px);
          padding-bottom: rem(30px);
          width: 100%;
        }

        .mobile-banner-background {
          background: none;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .text-over-bg,
  .text-only {
    @media screen and (min-width: $screen-sm-billboard) {
      &.box-align-left {
        justify-content: left;
      }

      &.box-align-center {
        justify-content: center;
      }

      &.box-align-right {
        justify-content: right;
      }
    }
  }

  .banner-image {
    padding: 0;
  }

  // Banner Text Container Style Class
  .banner-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: rem(34px) 0;

    @media screen and (min-width: $screen-sm-billboard) {
      width: 40%;
      padding: rem(50px) 0;
    }

    .pre-title {
      display: block;
    }
  }

  // Banner Add Gutter Style Class
  .add-gutter {
    .banner-home {
      @media screen and (min-width: $screen-sm) {
        width: 100%;
      }
      @media screen and (min-width: $screen-md) {
        margin: auto;
        max-width: 740px;
      }
      @media screen and (min-width: $screen-lg) {
        margin: auto;
        max-width: 1100px;
      }
    }
  }

  // Banner Button Zone Style Class
  .banner-button-zone {
    position: relative;
    z-index: 1;

    @media screen and (min-width: $screen-md) {
      padding: 0;
    }

    .list-horizontal {
      flex-flow: row wrap;
      @media screen and (max-width: $screen-sm) {
        flex-flow: column;
      }
    }
  }
}
