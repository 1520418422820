$platform-fieldToggle-length-sm: 474px;
.cmp-form {
  display: flex;
  font-size: $font-size;
  font-family: $font-ibm;
  width: 100%;
  max-width: 20.4rem;
  padding: 0 1.3rem;
  margin: auto;
  flex-direction: column;

  @media screen and (min-width: $screen-md) {
    align-items: center;
    flex-flow: column wrap;
  }

  .flexContainer {
    margin-left: 0;
    @media screen and (min-width: $screen-sm) {
      margin-left: -10px;
    }

    .disclaimer-home {
      margin-left: 10px;
      @media screen and (min-width: $screen-sm) {
        margin-left: 0;
      }
    }

    .button {
      margin-left: 10px;
      @media screen and (min-width: $screen-sm) {
        margin-left: 0;
      }
    }
  }

  @media screen and (min-width: $screen-sm) {
    max-width: 34rem;
  }

  @media screen and (min-width: $screen-md) {
    max-width: 49rem;
  }

  &.align-text-left {
    text-align: left;
    margin-left: 10px;
    width: 100%;
    @media screen and (min-width: $screen-sm) {
      margin-left: 180px;
      width: 1220px;
    }
  }

  &.align-text-center {
    text-align: center;
  }

  &.align-text-right {
    text-align: right;
    margin-right: 180px;
    width: 1220px;
  }

  .fieldToggle {
    .cmp-form-options__field--drop-down,
    .cmp-form-options__field {
      margin: 0;
      white-space: normal;
    }

    .combinedinput-home {
      .input-group {
        button {
          .description {
            white-space: normal;
          }
        }
      }
    }

    @media screen and (min-width: $screen-sm) {
      width: $platform-fieldToggle-length-sm;
    }
  }
}
