.card-container-home {
  &.hexBackground {
    background-color: var(--flexcontainer-bg-color);
  }
}
.cbg-flexcontainer__product-container {
  justify-content: flex-start;
  gap: 25px;

  .cbg-colorCard {
    max-width: 174px;

    .cbg-colorCard__swatch-container {
      max-width: 100%;
    }
  }

  &.cbg-layout--scroll {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
  }

  &.cbg-layout--wrap {
    flex-wrap: wrap;
  }
}

.cbg-cmp-card__image-container .card-image {
  width: 100%;
}

.card-holder {
  .videoplayer {
    iframe {
      width: 85%;
      height: 215px;
      margin: 0 auto;
      display: block;
      @media screen and (min-width: $screen-sm-md) {
        min-height: 300px;
      }
      @media screen and (min-width: $screen-sm-billboard) {
        min-height: 400px;
      }
      @media screen and (min-width: $screen-lg-md) {
        min-height: 600px;
      }
      @media screen and (min-width: $screen-xl) {
        min-height: 720px;
      }
      @media screen and (min-width: $screen-xxl) {
        min-height: 850px;
      }
    }
  }
}
