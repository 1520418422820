.related-article {
  a {
    &.cbg-cmp-related-article__title {
      text-decoration: none;
    }

    .small-card-margin {
      .each-text-area {
        margin: 1em;
      }
    }

    .medium-card-margin {
      .each-text-area {
        margin: 2em;
      }
    }

    .large-card-margin {
      .each-text-area {
        margin: 4em;
      }
    }

    .cbg-cmp-related-article__href {
      text-decoration: none;
    }

    .cbg-cmp-related-article {
      .cbg-cmp-related-article__image-container {
        margin: 0 auto;

        @media screen and (min-width: $screen-md) {
          padding: 0;

          .cbg-cmp-related-article__image-container {
            width: auto;

            & img {
              width: 100%;
            }
          }
        }

        .cbg-cmp-related-article__image-div {
          width: fit-content;
          height: fit-content;
        }
      }

      .cbg-cmp-related-article__text-container,
      .cbg-cmp-related-article__image-container {
        position: relative;
      }
    }

    @media screen and (max-width: $screen-md) {
      .cbg-cmp-related-article {
        position: relative;
        display: flex;
        margin: 0;
        flex-direction: column;

        .cbg-cmp-related-article__text-area {
          margin: 0;
          flex: 70%;
          @media screen and (min-width: $screen-md) {
            margin: 0 15px;
          }
        }

        .cbg-cmp-related-article__image-div {
          margin: auto;
        }

        &.small-margin {
          margin: 10px;
          transition: all 100ms ease-out;

          &.style-as-icon {
            @media screen and (max-width: $screen-md) {
              margin-bottom: 10px;
            }
          }
        }

        &.medium-margin {
          margin: 20px;
          transition: all 100ms ease-out;

          &.style-as-icon {
            @media screen and (max-width: $screen-md) {
              margin-bottom: 10px;
            }
          }
        }

        &.large-margin {
          margin: 30px;
          transition: all 100ms ease-out;

          &.style-as-icon {
            @media screen and (max-width: $screen-md) {
              margin-bottom: 10px;
            }
          }
        }

        .cbg-cmp-related-article__image {
          width: 100%;
        }
      }
    }
  }
}
