.cbg-cmp-search-results__content-container-color-family {
  width: 100%;
  margin: 24px 0;
  border: 2px solid $platform-cream;

  a {
    text-decoration: none;
  }
  .cbg-cmp-searchresult--color-family-swatches,
  .cbg-cmp-searchresult--color-family-content {
    width: 50%;
  }

  .swatches__color-family {
    display: flex;
    height: 115px;
  }

  .cbg-cmp-searchresult--color-family-swatch {
    width: 27px;
    height: 100%;
    display: block;
    @media screen and (max-width: $screen-md) {
      max-width: 14.5%;
    }
  }

  .cbg-cmp-searchresult--color-family-content {
    padding-left: 32px;
    font-family: $font-ibm;
    font-size: 16px;
    letter-spacing: 0;

    .title {
      color: #002e5d;
      font-weight: bold;
      line-height: 20px;

      margin-top: 24px;
      margin-bottom: 0;
    }

    .description {
      line-height: 26px;
      color: $platform-gray-text;
      margin-top: 8px;
    }
  }
}
