.info-drawer-container {
  .title {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    @media screen and (min-width: 600px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }

  .info-cta {
    display: flex;
    margin: 0 0 0 3px;
    cursor: pointer;
    svg {
      width: 23px;
      height: 23px;
    }
    background: none;
  }
  .cbg-cmp-toolTip .style-on-same-line .cbg-cmp-toolTip-icon,
  .cbg-cmp-toolTip .small-margin {
    margin: 0;
  }

  .text-segments .cbg-cmp-toolTip-btn-show svg {
    margin: 3px 3px;
    vertical-align: revert;
  }

  .cbg-cmp-toolTip,
  .cbg-cmp-toolTip-icon {
    margin: 0;
  }

  .text-segments {
    padding: 0;
  }

  .small-margin {
    padding: 0;
  }
}

.info-boxes {
  width: 100%;
}

.info-box {
  display: flex;
  flex-direction: column;
  flex: 50%;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 28px;
  width: 50%;

  @media screen and (min-width: 600px) {
    flex: 33%;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    max-width: 33%;
  }

  .info-title {
    font-weight: bold;
    display: flex;
  }
}

.flex-n-wrap-it {
  display: flex;
  flex-wrap: wrap;
}
