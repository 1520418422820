$authoredList-lg-title: 18px;
$authoredList-sm-title: 16px;
$authoredList-sm-font: 16px;
$banner-lg-description: 18px;
$font-family: sans-serif;
$font-family-default: sans-serif;
$font-ibm: sans-serif;
$font-height: 1.5;
$font-bold: bold;
$font-knockout: Knockout-67-Full-Bantamweight, sans-serif;
$font-ibm-regular: "IBMPlexSans-Regular", sans-serif;
$font-ibm-medium: "IBMPlexSans-Medium", arial, "Helvetica Neue", helvetica,
  sans-serif;

$font-size: 16px;
$footer-font-sm: 14px;
$platform-font-size-h1: 5em;
$platform-font-size-h3: 3em;
$platform-font-size-h5: 0.8em;

$rem-size-billboard-full: 8rem;
$rem-size-H1: 7rem;
$rem-size-H2: 6rem;
$rem-size-H2-5: 5rem;
$rem-size-H3: 2.3rem;
$rem-size-H4: 1rem;

$rem-size-mobile-H1: 4rem;
$rem-size-mobile-H2: 6rem;
$rem-size-mobile-H3: 3rem;
$rem-size-mobile-H4: 1rem;
$rem-size-body-text: 1rem;

$font-size-mobile-H1: 48px;
$font-size-mobile-H2: 72px;
$font-size-mobile-H3: 36px;
$font-size-mobile-H3-5: 20px;
$font-size-mobile-H4: 16px;
$font-size-body-text: 16px;

$font-size: 16px;
$link-size: 18px;
$aside-header-size: 18px;
$platform-font-size-h1: 5em;
$platform-font-size-h3: 3em;
$platform-font-size-medium-title: 1.6em;
$platform-font-size-h4: 1.2em;
$platform-font-size-h5: 0.8em;

@font-face {
  src: url("../resources/fonts/woff2/Knockout-67_Web.woff2");
  font-family: Knockout-67-Full-Bantamweight;
}

@font-face {
  src: url("../resources/fonts/IBMPlexSans-Regular.ttf");
  font-family: "IBMPlexSans-Regular";
}

@font-face {
  src: url("../resources/fonts/IBMPlexSans-Medium.ttf");
  font-family: "IBMPlexSans-Medium";
}

@font-face {
  src: url("../resources/fonts/IBMPlexSans-Bold.ttf");
  font-family: "IBMPlexSans-Bold";
}

$swiper-centered-offset-before: 200px;
$swiper-centered-offset-after: 200px;
$swiper-virtual-size: 20px;
$swiper-navigation-size: 16;
