.search {
  .cmp-search[role="search"] {
    position: static;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .cmp-search__form-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    width: 100vw;
    margin-left: calc(-50vw + 50%);

    opacity: 0;
    transition: opacity 250ms ease-in;
    pointer-events: none;

    form {
      max-width: 50%;
      height: 100%;
      margin: 0 auto;

      display: grid;
      align-items: center;

      .cmp-search__field {
        height: auto;
      }

      .cmp-search__input {
        border-radius: 4rem;
      }

      .cmp-search__loading-indicator {
        left: auto;
        right: 0.5rem;
      }
    }
  }

  button.btn-search {
    padding: 0;
    margin: 0 auto;
    width: 45px;
    height: 45px;

    position: relative;

    z-index: $z-level2;

    .cmp-search__clear-icon,
    .hide-text {
      display: none;
    }

    .cmp-search__icon {
      display: block;
    }

    .icon {
      background-repeat: no-repeat;

      position: static;

      margin: 0 auto;
      width: 18px;
      height: 18px;
    }
  }
  .cmp-search__clear {
    display: none;
  }

  &.expanded {
    background-color: red;

    .cmp-search__form-container {
      opacity: 1;
      pointer-events: all;
      z-index: $z-level1;
    }

    button.btn-search {
      .cmp-search__icon {
        display: none;
      }

      .cmp-search__clear-icon {
        display: block;
      }
    }
  }
}

main.container .searchbar {
  .cmp-searchbar__form-container form {
    z-index: $z-level2;
    position: relative;
  }

  .cmp-searchbar__results {
    position: absolute;
    padding-top: 24px;
    margin-top: -30px;
    z-index: $z-level1;
  }
}
