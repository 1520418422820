.product-detail-home {
  color: $platform-gray-text;

  &::after {
    clear: both;
    content: "";
    display: block;
  }

  .back-button {
    display: flex;
    a {
      padding: 0;
    }
  }

  .left-side {
    width: 100%;
    float: left;
    padding: 0;
    margin: auto;
    @media screen and (min-width: $screen-lg-md) {
      padding: 3em;
      width: 50%;
    }
  }

  .right-side {
    float: right;
    width: 100%;
    padding: 1em 1.3rem;

    .sheen-link,
    .calculator-link {
      color: $brand-dark-blue;
      text-decoration-color: $brand-light-blue;
      text-decoration-thickness: 2px;
      text-decoration-line: underline;
      padding: 0;

      &:hover {
        text-decoration-color: $brand-dark-blue;
      }

      &:focus {
        outline-color: $brand-light-gray;
        margin-left: 2px;
      }
    }

    p {
      margin: 0;
    }

    a {
      margin: 10px 0px;
      display: block;
    }

    .detail-link {
      display: inline-block;
    }

    .authored-list-home {
      padding: 0;
      margin: 0;
    }

    @media screen and (min-width: $screen-lg-md) {
      width: 50%;
      padding: 4em 2em;
      max-width: 700px;
    }
  }

  .right-side .product-detail-subtitle,
  .right-side .product-detail-description {
    font-weight: normal;
    line-height: 1.5;
    margin: 1rem 0;
    @media screen and (min-width: $screen-lg-md) {
      line-height: 1.7;
    }
  }

  .right-side .multiple-categories {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    div {
      width: 50%;

      div {
        width: 100%;
      }

      @media screen and (min-width: $screen-lg-md) {
        width: 100%;
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: $screen-lg-md) {
      flex-direction: row;
    }
  }

  .product-detail-image {
    max-width: 100%;

    img {
      width: 100%;
    }

    @media screen and (min-width: $screen-lg-md) {
      //max-width: 50%;
      margin: auto;
      margin-top: 5em;
    }
  }

  ul {
    //font-size: $font-size;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-left: 1.3rem;
    @media screen and (min-width: $screen-lg-md) {
      margin: 0;
      padding: 0;
    }
  }

  &.add-gutter {
    @media screen and (min-width: $screen-sm) {
      width: 100%;
    }
    @media screen and (min-width: $screen-lg) {
      margin: auto;
      max-width: 1220px;
    }
  }
}
