@mixin icon-close($bg-color: transparent, $icon-color: $platform-white) {
  $icon-width: 2px;
  $icon-height: 16px;

  background-color: $bg-color;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - (#{$icon-height * 0.5}));
    left: calc(50% - #{$icon-width});

    background-color: $platform-white;
    width: $icon-width;
    height: $icon-height;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}

a[data-opens-modal] {
  cursor: pointer;
}

@mixin modal-static {
  position: static;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

@mixin modal-fixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body.modal__active {
  overflow: hidden;

  &.modal__fullscreen {
    position: fixed;
    right: 0;
    left: 0;
  }
}

.cbg-cmp-modal {
  @include modal-fixed();
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  transition: opacity 250ms ease-in 500ms;

  .modal__button--close {
    display: none;
  }

  &.modal__active {
    z-index: $z-level10;
    pointer-events: all;
    visibility: visible;
    opacity: 1;

    .modal__overlay {
      @include modal-fixed();
      background-color: rgba($platform-black, 0.75);
      z-index: $z-level5;
    }

    .modal__button--close {
      display: block;

      position: absolute;
      top: 10px;
      right: 10px;

      width: 30px;
      height: 30px;
      border-radius: 30px;
      color: $platform-white;
      font-size: 14px;
      font-weight: bold;

      padding: 12px;
      cursor: pointer;
      z-index: $z-level10;

      .hidden-text {
        text-indent: -9999px;
        display: inline-block;
      }

      @include icon-close($platform-black, $platform-white);
      @media screen and (min-width: $screen-sm) {
        height: 45px;
        width: 45px;
      }
    }

    .modal__inner-container {
      font-size: 16px;
      background-color: $platform-white;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      z-index: $z-level5;
      max-height: 100%;

      @media screen and (min-width: 769px) {
        min-width: 360px;
        max-width: 980px;
        min-height: 400px;
        max-height: 98%;
      }
    }
  }
}

.aem-AuthorLayer-Edit {
  .cbg-cmp-modal.mode__edit {
    display: block;
    visibility: visible;
    pointer-events: all;
    opacity: 1;

    @include modal-static();
    .modal__overlay {
      @include modal-static();
      display: none;
    }
    .modal__inner-container {
      width: auto;
      height: auto;
      min-width: 0;
      max-width: none;
      min-height: 0;
      max-height: none;
    }
  }
}
//Styles for sheen card styles, removes text area spacing on sides
.modal .isSheenModal .each-card.style-as-stacked .each-text-area {
  margin: 0;
}

// Container wrapping modal exit buttons
.isSheenModal .modal__button-container {
  display: flex;
  padding: rem(10px 30px 0px);
}

// Removes Modal back button with arrow and text from document flow on screens larger than 800px
.isSheenModal div[hidden] {
  display: none;

  @media screen and (max-width: $screen-md) {
    display: block;
  }
}

// Removes Modal X button from document flow on screens smaller than 800px
.isSheenModal .modal__button-container .modal__button--close {
  @media screen and (max-width: $screen-md) {
    display: none;
  }
}

// Adjust padding to sheen modal container based on screen size
.isSheenModal .card-container-home {
  padding: 2rem;

  @media screen and (min-width: $screen-md) {
    padding: 3.5rem;
  }
}

//Overrides card around margin only if cards are in modal for phone and tablet
.isSheenModal .each-card {
  @media screen and (max-width: $screen-md) {
    margin: 0px;
  }
}

//Adds bottom padding to cards in a modal
.isSheenModal .card-item {
  padding-bottom: 3rem;
}

// Modal main title styles updates based on screen size this is set in flex container
.isSheenModal .card-container-title {
  font-size: rem(28px);

  @media screen and (min-width: $screen-md) {
    font-size: rem(36px);
  }
}

// Modal main description styles updates based on screen size this is set in flex container
.isSheenModal .card-container-description {
  font-size: rem(16px);

  @media screen and (min-width: $screen-md) {
    font-size: rem(18px);
  }
}

.modal .isSheenModal .each-row {
  display: block;

  @media screen and (min-width: $screen-md) {
    display: flex;
  }
}

// Makes sure modal cards stay container
.isSheenModal .card-container-home .card-holder .card-image {
  max-width: 100%;
}

// Modal card title font-size updates based on screen size
.isSheenModal .each-title {
  font-size: rem(16px);
}

// Modal card subtitle & description font-size updates based on screen size
.isSheenModal .each-subtitle,
.isSheenModal .each-description {
  font-size: rem(16px);

  @media screen and (min-width: $screen-md) {
    font-size: rem(18px);
  }
}

// Sets modal card subtitle to italic only in Sheen Modals
.isSheenModal .each-subtitle {
  font-style: italic;
}

//Modal banner text at bottom of sheen modal this is only rendered when authored
.isSheenModal .modal__inner-banner {
  display: flex;
  justify-content: center;
  padding: rem(20px 0);
}

.isSheenModal .modal__inner-banner-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: rem(16px);

  @media screen and (min-width: $screen-md) {
    display: block;
    font-size: rem(18px);
  }
}

.modal__inner-container.isSheenModal {
  overflow-y: scroll;
}

.modal {
  .cbg-cmp-modal {
    .modal__inner-container {
      &.notification-model {
        color: $brand-light-gray;

        position: absolute;
        top: 12rem;
        height: 17rem;
        min-height: 17rem;
        margin-left: 0;
        overflow: revert;
        width: 100%;
        padding: 10px;
        @media screen and (min-width: $screen-md) {
          margin-left: 12rem;
          width: 30rem;
        }

        .modal__button--close {
          display: none;
        }

        .header {
          margin: auto;
          width: 20rem;
          margin-top: 20px;
        }
        .button--wrapper-inner {
          margin: auto;
          margin-top: 20px;
        }
      }
    }
  }
}
