.cbg-cmp-favorites {
  height: 100%;
  width: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media screen and (max-width: $screen-md) {
    display: none;
  }

  .favorites--icon-container {
    position: relative;
    border-radius: 100%;
    width: 2.7rem;
    height: 2.6rem;
    line-height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      position: absolute;
      width: rem(28px);
      height: rem(26px);
      transition: opacity 0.4s ease;

      &.icon-hover {
        opacity: 0;
      }

      @media screen and (max-width: $screen-sm) {
        width: rem(23px);
        height: rem(21px);
      }
    }

    &:hover {
      min-width: rem(16px);
      border-radius: 50%;
      text-align: center;

      & .icon-default {
        opacity: 0;
      }

      & .icon-hover {
        opacity: 1;
      }
    }

    .favorites--badge {
      pointer-events: none;
      position: absolute;
      min-width: rem(10px);
      padding: rem(8px);
      border-radius: 100%;
      font-size: rem(10px);
      text-align: center;
      line-height: 0.7;
      top: rem(7px);
      left: rem(24px);
      height: rem(17px);
      width: rem(17px);
      display: flex;
      justify-content: center;
      align-items: center;
      border: rem(2px) solid $platform-white;
      transition: opacity 250ms ease-in-out;
      opacity: 1;

      @media screen and (min-width: $screen-lg-md) {
        height: rem(18px);
        width: rem(18px);
        top: rem(5px);
        padding: rem(8px);
      }

      &.display-none {
        opacity: 0;
      }
    }
  }

  .favorites--content {
    position: relative; /* Stay in place */
  }

  .favorites--content-container {
    position: fixed;
    background-color: $platform-white;
    z-index: 1000;
    top: 76px;
    right: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 1.3rem;

    @media screen and (min-width: $tablet-breakpoint) {
      position: absolute;
      top: $font-size;
      right: 0;
      width: 21.3rem;
      min-height: 11.6rem;
    }
  }

  .max-chip {
    @media screen and (min-width: $screen-lg) {
      min-height: 15.6rem;
    }
  }

  .favorites--content-container__message,
  .favorites--content-container__message-removed,
  .favorites--content-container__maxMessage {
    margin: 0;
    font-size: $font-size;
    letter-spacing: 0;
    line-height: 1.5rem;
    @media screen and (min-width: $screen-lg) {
      font-size: 18px;
      line-height: 1.75rem;
    }
  }

  .favorites--content-container__maxMessage {
    margin-top: 10px;
  }

  .favorites--content-container__btn {
    margin-top: 10px;
    align-items: center;
    height: 2.8rem;
    width: 18.4rem;
    @media screen and (min-width: $screen-lg) {
      width: 10.4rem;
      height: 3.1rem;
      margin-top: 1.9rem;
    }
  }

  .favorites--content__button--close {
    position: absolute;
    top: -3px;
    right: 5px;
    border-radius: 1.9rem;
    color: black;
    font-size: 14px;
    font-weight: bold;
    background: transparent;
    padding: 5px;
    cursor: pointer;
    z-index: $z-level10;
    width: fit-content;
  }

  .favorites--content-container__count {
    padding-left: 5px;
  }

  .favorites__close-icon {
    height: 1.9rem;
    width: 12px;
    display: block;
    background-size: contain;
  }
  .default-close-icon {
    background-image: url("../resources/images/svgs/field-close.svg");
    width: 12px;
    height: 12px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
  }

  .favorites--content-container__content.loading {
    &:after {
      content: "";
      display: block;
      width: 48px;
      height: 48px;
      border: 2px solid;
      border-radius: 48px;
      position: absolute;
      border-color: gray transparent gray transparent;
      animation: 1.5s rotating linear infinite;
      top: calc(50% - 8px);
      left: 8px;
    }
  }
}

.cbg-cmp-favorites.context--page {
  width: 100%;
  max-width: 772px;
  margin: 0 auto;
  padding: 20px;

  display: block;

  .cbg-cmp-favorites__header {
    h2 {
      &.title--favorites {
        line-height: 1.25;
      }
    }
  }

  .cbg-cmp-favorites__content {
    padding: 20px 0;
  }

  .favorites--content-container__content-populated {
    display: grid;
    grid-gap: 25px;
    align-items: baseline;
    align-content: center;
    justify-content: center;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (min-width: $screen-sm-md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: $screen-md) {
      grid-template-columns: repeat(4, 1fr);
    }

    .cbg-colorCard {
      position: relative;
      width: calc(100% - 12px);
      height: auto;
      left: auto;
      top: auto;
      bottom: auto;
      transform: none;
      min-width: 157px;
      max-width: 176px;

      .color-detail-link {
        text-decoration: none;
      }

      .cbg-colorCard__info {
        max-height: 95px;
      }

      .cbg-colorCard__tagemblem {
        display: none;
        background: black;
        color: white;
      }
    }

    .cbg-favorite-cta {
      display: block;
    }

    &:not(.content_color-cards) {
      .cbg-favorite-cta {
        background: none;
        height: rem(35px);
        padding: 0;
        right: rem(7.5px);
        top: rem(7.5px);
        width: rem(35px);

        &:after,
        &:before {
          top: 0;
          right: 0;
        }
      }
    }
  }
  &.cbg-favorite-hide {
    display: none;
  }
}

.favorites-cta--button.loading {
  position: relative;
  &:after {
    content: "";
    display: block;
    width: $font-size;
    height: $font-size;
    border: 2px solid;
    border-radius: $font-size;
    position: absolute;
    border-color: gray transparent gray transparent;
    animation: 1.5s rotating linear infinite;
    top: calc(50% - 8px);
    left: 8px;
  }
  .favorites-cta--icon,
  .icon {
    transition: opacity 500ms;
    opacity: 0;
  }
}

.favorites-modal--active {
  main.container .searchbar {
    .cmp-searchbar__form-container form {
      z-index: $z-level0;
    }
  }
}

.favorites--empty-cta {
  max-width: 400px;
  text-align: center;
  background-color: lightgray;
  padding: 1rem;

  .favorites--icon {
    width: 60px;
    height: 60px;
    background-color: $platform-white;
    border-radius: 60px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: relative;
      max-width: 36px;
    }
  }
}

.cbg-cmp-favorites__empty-parsys {
  margin: 20px 0;
  @media screen and (min-width: $screen-lg-md) {
    margin: 30px 0;
  }

  @media screen and (max-width: $screen-lg-md) {
    .button .button-home {
      width: 100%;
    }
  }
}

.standard-link--buy-sample {
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 19.2px;
  padding: 0.5rem 0;

  .icon {
    max-width: 16px;
    max-height: 8px;
  }
}
