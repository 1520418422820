.platform-image-v2 {
  .mobile-platform-image {
    display: block;

    @media screen and (min-width: $screen-sm-billboard) {
      display: none;
    }
  }

  .desktop-platform-image {
    display: none;

    @media screen and (min-width: $screen-sm-billboard) {
      display: block;
    }
  }
}
