.cmp-dynamicCard__card-color-swatch {
  position: relative;
  width: rem(158px);
  height: rem(144px);

  @media screen and (min-width: $screen-md) {
    width: rem(175px);
    height: rem(174px);
  }
}
//operation-scale-down
.cmp-dynamicCard__card-color-name {
  font-size: rem(16px);
  letter-spacing: 0;
  line-height: rem(15px);
}

.cmp-dynamicCard__card-color-id {
  font-size: rem(12px);
  letter-spacing: 0;
  line-height: rem(15px);
  margin: 0;
  padding-top: rem(5px);
}

.cmp-dynamicCard__card-info-container {
  padding: rem(12px 12px 36px);
}

.cmp-dynamicCard__cart-btn {
  display: block;
  margin: 0 auto;
  width: 100%;
  border-radius: 0;
  background: transparent;
}

.cmp-dynamicCard__card-current-msg {
  display: flex;
  justify-content: center;
  font-size: rem(10px);
  letter-spacing: rem(1.67px);
  line-height: rem(11px);
  padding: rem(17px 20px);
}

.hgsw-container {
  .cmp-dynamicCard__cart-btn .cbg-color-card__text.add-text,
  .cmp-dynamicCard__cart-btn .cbg-color-card__text.remove-text {
    @media screen and (min-width: $screen-md) {
      padding-right: 0;
      font-size: rem(12px);
    }
  }

  .cmp-dynamicCard__card-current-msg {
    background-color: #d8dce8;
  }
}

.cmp-dynamicCard__cart-btn .cbg-color-card__text.add-text,
.cmp-dynamicCard__cart-btn .cbg-color-card__text.remove-text {
  font-size: rem(13px);
  letter-spacing: rem(0.41px);
  line-height: rem(19.2px);
  padding-right: 0;

  @media screen and (min-width: $screen-md) {
    padding-right: rem(24px);
  }
}

.cbg-color-card__footer .cmp-dynamicCard__cart-btn {
  background: none;
  height: auto;
}

.cmp-dynamicCard__cart-btn .cart-cta--icon {
  padding: 0 10px;
  background-repeat: no-repeat;
  background-position: left;
}

.cmp-dynamicCard__card-color-swatch .cbg-favorite-cta {
  background: none;
  position: absolute;
  width: rem(50px);
  height: rem(50px);
  z-index: $z-level2;
  pointer-events: none;
  right: rem(0);

  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    right: rem(7.5px);
    top: rem(7.5px);
    cursor: pointer;
    width: rem(35px);
    height: rem(35px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: all;
    transition: opacity 0.4s ease;
    opacity: 0;

    // Inactive State
    &:before {
      background-image: url("../resources/images/svgs/favorite-2-checked.svg");
      opacity: 1;
    }

    // Inactive State
    &:after {
      background-image: url("../resources/images/svgs/favorite-1-checked.svg");
      opacity: 0;
    }
  }

  &:hover,
  &.active {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

//pretty
.icon-add {
  background-image: url("../resources/images/svgs/add-chip.svg");
}

.icon-remove {
  background-image: url("../resources/images/svgs/remove-chip.svg");
}

//Product Card Style
.cmp-dynamicCard__product-card {
  display: flex;
  align-items: center;

  @media screen and (min-width: $screen-md) {
    display: block;
  }
}

.cmp-dynamicCard__product-card img.card-image {
  width: rem(68px);

  @media screen and (min-width: $screen-md) {
    height: rem(144px);
    width: auto;
    margin: 0 auto; //final polish
    display: block;
  }
}

.cmp-dynamicCard__product-card
  .cmp-dynamicCard__image-container.cbg-cmp-card__image-container {
  margin: rem(0px 10px);

  @media screen and (min-width: $screen-md) {
    margin: rem(30px 0 55px);
  }
}

.cmp-dynamicCard__product-card .each-description {
  display: none;
}

.cmp-dynamicFlexContainer__card-item.card-item.product-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}

//Dab version general styles
.cmp-dynamicCard__dab-card-container {
  background-color: white;
  position: absolute;
  .cmp-dynamicCard__dab-card-info-container {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .splotch-img-container {
    .splotch-img {
      height: 140px;
      width: 140px;
    }
  }
}
