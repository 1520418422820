.cbg-cmp-embed--content {
  width: calc(100% - 32px);
  max-width: 1220px;
  margin: auto;

  @media screen and (max-width: $screen-xs) {
    width: calc(100% - 16px);
  }

  &.container-width-small {
    max-width: 800px;
  }

  &.container-width-medium {
    max-width: 1024px;
  }

  &.container-width-large {
    max-width: 1220px;
  }

  &.container-width-full {
    max-width: none;
  }

  &.editing {
    border: 1px dashed dimgray;
    position: relative;
    &:before {
      display: block;
      content: "Embed Content Container";

      background-color: dimgray;
      color: white;
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px;

      font-size: 12px;
      font-family: arial, sans-serif;
      text-transform: uppercase;
    }
  }
}
