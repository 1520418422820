.display-ib {
  display: inline-block;
}

.display-bl {
  display: block;
}

.display-il {
  display: inline;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none !important;
}

.flex--space-bt {
  justify-content: space-between;
}

.margin-0 {
  margin: 0;
}

.p-1 {
  padding: 1em;
}

.p-2 {
  padding: 2em;
}

.m-1 {
  margin: 1em;
}

.m-2 {
  margin: 2em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.ml-1 {
  margin-left: 1em;
}

.ml-2 {
  margin-left: 2em;
}

.cbg__vis-hide {
  visibility: hidden;
  pointer-events: none;
}
