.billboard-container {
  background-color: none;
  padding: 1.3rem 0;
  ////===================================================================================
  ////=============================== Style System Section ==============================
  ////===================================================================================
  //

  &.light-styling {
    background-color: $platform-background-light-gray-scheme;
    color: $platform-dark-gray;
  }

  &.dark-styling {
    background-color: $platform-dark-blue;
    color: $platform-white;
  }

  &.no-styling {
    background-color: $platform-white;
    color: $platform-dark-gray;
  }

  ////===================================================================================
  ////=============================== End of Style System Section ==============================
  ////===================================================================================

  // Billboard Window Spacer Classes
  .spacer-window-height-1 {
    .billboard-text-container-full {
      min-height: 30em;
      padding-top: 7em;
    }
  }

  .spacer-window-height-2 {
    .billboard-text-container-full {
      min-height: 35em;
      padding-top: 8em;
    }
  }

  .spacer-window-height-3 {
    .billboard-text-container-full {
      min-height: 40em;
      padding-top: 8em;
    }
  }

  .spacer-window-height-4 {
    .billboard-text-container-full {
      min-height: 45em;
      padding-top: 8em;
    }
  }

  // Gutter class that adds ability for authorable gutter on the Billboard container
  .add-gutter {
    width: 100%;

    @media screen and (min-width: $screen-lg) {
      width: 1200px;
      margin: auto;
    }
  }

  // Classes that add ability for authorable column selection for individual Billboards
  .column-0 {
    flex: 0 0 53%;

    .billboard-text-container-full {
      padding: 8.2rem 1.3rem;
    }
  }

  .column-2 {
    flex: 0 0 50%;

    .billboard-text-container-full {
      padding: 8.2rem 1.3rem;
    }
  }

  .column-3 {
    flex: 0 0 33%;

    .billboard-text-container-full {
      padding: 20% 4.4rem;
    }
  }

  .column-4 {
    flex: 0 0 25%;

    .billboard-text-container-full {
      padding: 5.5rem 1.3rem;
    }
  }

  // Classes that adds ability for authorable margin to individual Billboards
  .small-margin {
    padding: 10px;
  }

  .medium-margin {
    padding: 20px;
  }

  .large-margin {
    padding: 10px;

    @media screen and (min-width: $screen-md) {
      padding: 25px;
    }
  }

  .extra-large-margin {
    padding: 10px;

    @media screen and (min-width: $screen-md) {
      padding: 35px;
    }
  }

  .billboard-container-title {
    text-align: center;
  }

  // Class that holds all the Billboard Components
  .billboard-holder {
    display: block;

    // Classes that add ability for authorable Billboard Container alignment
    &.alignment-center {
      justify-content: center;
    }

    &.alignment-left {
      justify-content: flex-end;
    }

    &.alignment-right {
      justify-content: flex-start;
    }

    // Classes that add ability for authorable Billboard column direction
    &.billboard-direction-0 {
      flex-flow: row;
      flex-wrap: wrap;
    }

    &.billboard-direction-1 {
      flex-flow: row-reverse;
      flex-wrap: wrap;
    }

    @media screen and (min-width: $screen-sm) {
      display: flex;
    }
  }

  // Billboard class that hold individual Billboards.
  .billboard-textbox {
    .billboard-text-container {
      flex-direction: column;
      align-items: center;
      display: flex;
      padding: 1.3rem 0;
      width: 100%;
      @media screen and (min-width: $screen-lg) {
        padding: 0;
        width: 50%;
        flex-direction: column;
        align-items: center;
      }
    }

    .desktop-billboard-image {
      width: 100%;

      @media screen and (min-width: $screen-lg) {
        width: 50%;
      }
    }

    .billboard-title {
      width: 90%;
      margin-bottom: 0;
    }

    .billboard-subtitle {
      width: 90%;
    }

    .billboard-description {
      margin-bottom: 0.5rem;
      margin-top: 0;
      width: 90%;
    }

    .billboard-button-zone {
      li,
      h3 {
        margin: 0;
      }

      width: 90%;
    }
    @media screen and (min-width: $screen-lg) {
      min-width: 650px;
    }
  }

  // Billboard Container Class for Billboards with an image to the right of text
  .billboard-textbox-right {
    .billboard-textbox {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: $screen-lg) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // Billboard Container Class for Billboards with an image to the left of text
  .billboard-textbox-left {
    .billboard-textbox {
      display: flex;
      flex-direction: column-reverse;
      @media screen and (min-width: $screen-lg) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // --------------- Billboard Container Class for Billboards Full Width Background Image ------- //

  .billboard-text-container-full {
    display: block;
    text-align: center;
    .billboard-subtitle {
      margin: 0 0 1rem 0;
    }
    @media screen and (min-width: $screen-lg) {
      min-width: 25rem;
      min-height: 25rem;
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      margin-top: 3.5rem;
    }
  }

  .billboard-gutter-wrapper .billboard-textbox-full {
    margin: 0 auto;
    background-position: center;
    background-size: cover;
  }

  .billboard-row {
    width: 100%;
    height: 100%;
  }
}
