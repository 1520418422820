.collage-home {
  width: 90%;
  margin: auto;
  .image-container {
    line-height: 0;
    column-count: 1;
    column-gap: 0px;

    @media screen and (min-width: $screen-md) {
      column-count: 2;
      column-gap: 0px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .feature-on-right {
    flex: 1;
    .featured-image {
      margin-right: 0;
    }
    .secondary-image {
      margin-right: 32px;
      margin-bottom: 32px;
    }
    .curio-image {
      margin-right: 32px;
      margin-bottom: 0;
    }
  }

  .feature-on-left {
    flex: 1;
    .featured-image {
      margin-right: 32px;
      margin-bottom: 32px;
    }
    .secondary-image {
      margin-right: 0;
      margin-bottom: 32px;
    }
    .curio-image {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    .image-container {
      flex-direction: column;
    }
  }
}
