.product-description-home {
  color: $platform-dark-blue;
  background: $platform-cream;
  padding-top: 0;

  &.add-gutter {
    @media screen and (min-width: $screen-sm) {
      width: 100%;
    }
    @media screen and (min-width: $screen-lg) {
      margin: auto;
      max-width: 1200px;
    }
  }

  .left-side {
    background: $platform-cream;
    width: 100%;
    padding: 1.3rem 1.3rem;
    @media screen and (min-width: $screen-md) {
      width: 70%;
      padding: 0 3rem 3rem 10px;
    }
  }

  .left-side .product-description-title {
    font-size: $rem-size-mobile-H3;
    line-height: 2.4rem;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    @media screen and (min-width: $screen-md) {
      font-size: $rem-size-H2-5;
      line-height: 4rem;
    }
  }

  .left-side ul {
    padding: 0;
    list-style-type: none;

    p {
      margin: 0;
    }

    @media screen and (min-width: $screen-md) {
      font-size: 18px;
    }
  }

  .description {
    font-family: $font-ibm-regular;
  }

  .right-rail-area {
    width: 100%;
    padding: 0 1.3rem;

    @media screen and (min-width: $screen-md) {
      width: 40%;
    }
  }

  .right-rail-area-top {
    background: $platform-white;
    padding-right: 20px;
    padding-left: 40px;
    padding-top: 0;
    padding-bottom: 0;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding-top: 20px;
      }

      p {
        margin: 0;
      }
    }

    .textTool {
      padding: 15px 0;
      .tool-tip {
        .icon-stack-svg {
          width: 85px;
        }
      }
    }

    @media screen and (min-width: $screen-md) {
      font-size: 18px;
      background: $platform-white;
    }
  }

  .below-right-rail-area {
    margin: auto;
    width: 100%;
    padding-top: 0.8rem;
  }

  @media screen and (min-width: $screen-md) {
    display: flex;
    padding-top: 6rem;
  }
}
