.checkout-component {
  width: 100%;
  max-width: 772px;
  margin: 0 auto;
  .checkout-order-review:not(:empty) {
    padding: 2rem 0;
    .checkout-title {
      font-size: $rem-size-H2-5;
      color: inherit;
      font-family: inherit;
    }
    .checkout-description {
      font-size: 1.5rem;
      color: inherit;
      font-family: inherit;
    }
  }
  .checkout-shipping {
    padding: 2rem 0;
    position: relative;
    .shipping-title {
      display: inline-block;
      font-size: $rem-size-H3;
      color: inherit;
      font-family: inherit;
    }
    .shipping-edit-link {
      float: right;
      padding-top: 0.625rem;
      cursor: pointer;
      font-family: inherit;
      border-bottom: 2px solid green;
      transition: border-width 0.3s ease;
      text-decoration: none;
      &:hover {
        border-width: 4px;
      }
    }
    .shipping-information {
      padding-top: 0.8rem;
      & p {
        margin: 0.3rem 0;
        font-family: inherit;
        &.user-email {
          padding-top: 0.5rem;
        }
      }
    }
    &.hide {
      display: none;
    }
  }

  .checkout-paint-chips {
    position: relative;
    padding: 2rem 0;
    .paint-chips-title {
      font-size: $rem-size-H3;
      display: inline-block;
      font-family: inherit;
    }

    .paint-chips-description {
      padding: 0px;
    }

    .paint-chips-description:not(:empty) {
      padding: 1.5rem 0rem;
      color: #333;
      font-size: 1.125rem;
    }

    .chips-edit-link {
      float: right;
      padding-top: 0.625rem;
      cursor: pointer;
      font-family: inherit;
      border-bottom: 2px solid green;
      transition: border-width 0.3s ease;
      text-decoration: none;
      &:hover {
        border-width: 4px;
      }
    }
    .paint-chips-data {
      padding-top: 0.8rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1.25rem;
      .cart-chip {
        flex: 0 0 47%;
        border: 2px solid #ced1d4;
        position: relative;
        .cart-chip-spacer {
          display: flex;
          margin: 0.5em;
          .cart-chip-hex {
            flex: 0 0 28%;
            .cart-chip-color {
              width: 5rem;
              height: 5rem;
            }
          }
          .cart-chip-name {
            font-size: 16px;
            color: inherit;
            flex: 0 0 64%;
            font-family: inherit;
          }
          .cart-chip-remove {
            flex: 0 0 8%;
            padding-top: 0.3rem;
            .bin-add {
              background-image: url("../resources/images/svgs/delete-icon.svg");
              display: block;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 1.2rem;
              height: 1.2rem;
              cursor: pointer;
            }
          }
          .cart-chip-remove-notice {
            position: absolute;
            inset: 0;
            background-color: rgb(91, 103, 112);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 10;
            flex-direction: column;
            font-size: 1rem;
            .cart-chip-undo-text {
              margin: 0;
              padding-bottom: 0.625rem;
            }
            .cart-chip-undo-remove {
              display: block;
              color: #fff;
              text-decoration: underline;
              font-size: 1rem;
            }
            &.hide {
              display: none;
            }
          }
          .cart-chip-content-hidden {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
      .empty-cart-message {
        font-size: 1.125rem;
        color: #b20000;
        font-family: inherit;
      }
    }
    &.hide {
      display: none;
    }
  }
  /* Responsive adjustments for smaller screens */
  @media screen and (max-width: $screen-md) {
    display: flex;
  }
}

// All error styles at root level
.paint-chips {
  &__error-toast,
  &__error-inventory {
    padding: 0.75rem 1rem;
    margin: 1rem 0;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.4;
    z-index: 1000;
    box-shadow: 0 0.25rem 0.5rem rgba(220, 38, 38, 0.1);

    &--visible {
      display: block;
    }

    &--hidden {
      display: none;
      margin: 0;
      padding: 0;
    }
  }

  &__error-toast {
    background-color: #f0f4f8;
    border-left: 0.25rem solid #002e5d;
    color: #002e5d;
    text-align: center;
  }

  &__error-inventory {
    background-color: #fef2f2;
    border-left: 0.25rem solid #dc2626;
    color: #991b1b;
    display: flex;
    align-items: center;
  }
}
