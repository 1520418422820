.colorBillboard {
  .colorBillboard-background-image {
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    height: 100%;
    min-height: 30rem;
    justify-content: center;
    width: 100%;
    position: relative;

    @media screen and (min-width: $screen-md) {
      min-height: 36rem;
      height: 400px;
      text-align: center;
      padding: 0;
    }
    @media screen and (min-width: $screen-lg) {
      min-height: 40rem;
      height: 500px;
      text-align: center;
      padding: 0;
    }
  }

  .color-card-container {
    background-color: white;
    padding: 2rem;
    height: 60%;
    width: 65%;
    position: absolute;

    @media screen and (max-width: $screen-md) {
      height: 85%;
      width: 85%;
    }
  }

  .colorBillboard-title,
  .card-color-name {
    font-size: 2rem;
    line-height: 1;
    font-weight: normal;
    margin: 10px 0 10px 0;

    @media screen and (max-width: $screen-md) {
      font-size: 1rem;
      text-align: center;
    }
  }

  .card-color-number {
    text-align: center;
  }

  .colorBillboard-subtitle {
    text-align: center;
  }

  .colorBillboard-description {
    text-align: center;

    @media screen and (max-width: $screen-md) {
      font-size: 14px;
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin: auto;

    @media screen and (max-width: $screen-md) {
      width: 100%;
      flex-direction: column;
      gap: 10px;
    }
  }

  .splotch-img-container {
    position: absolute;
    z-index: 10;
    top: 10%;
    left: 15%;
    height: 100%;

    @media screen and (max-width: $screen-md) {
      top: 0;
      left: 0;
    }

    .splotch-img {
      height: 30%;
      rotate: 15deg;

      @media screen and (max-width: $screen-md) {
        height: 20%;
      }
    }
  }

  .cbg-favorite-cta {
    top: 0;
  }
}
