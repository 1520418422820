// Containers Sizes

// General
$desktop-grid: 1220px;
$destop-grid-max: 1220px;
$container-tablet: 720px;
$mobile-grid-max: 375px;

// Header
$global-nav-height: 80px;

//footer
$ft-container-desktop: 1220px;
$ft-container-tablet: 720px;
$ft-container-mobile: 375px;

//banner
$banner-desktop-container: 600px;

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

$brand-primary: darken(#428bca, 6.5%); // #337ab7
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
// deprecating: $screen-sm: 768px;

// deprecating: $screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
// deprecating: $screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
// deprecating: $screen-md: 992px;
// deprecating: $screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
// deprecating: $screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
// deprecating: $screen-lg: 1200px;
// deprecating: $screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
// deprecating: $screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
// deprecating: $screen-xs-max: ($screen-sm-min - 1);
// deprecating: $screen-sm-max: ($screen-md-min - 1);
// deprecating: $screen-md-max: ($screen-lg-min - 1);

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 14px;
$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.pom.
$grid-float-breakpoint: 420px;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);
