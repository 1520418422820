.react-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: $platform-cream;

  .react-holder-container {
    max-width: $desktop-grid;
  }
}
