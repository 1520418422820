.location {
  width: 100%;
}

.cbg-cmp-location {
  text-align: center;
  background-color: $platform-cream;

  .cbg-cmp-location__header-inner {
    width: 100%;
    margin: 0 auto;
    padding: 1em;
    @media screen and (min-width: $screen-sm) {
      padding: 2em 0;
    }
  }

  .title--location {
    font-weight: bold;
    font-size: 3rem;
  }

  .description--location {
    font-size: 1rem;
    margin: 2rem;
    margin-bottom: 0;
  }

  .cbg-cmp-location__iframe {
    padding: 0 16px;
  }

  &.container-margin-small {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  &.container-margin-medium {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  &.container-margin-large {
    padding-top: 6em;
    padding-bottom: 6em;
  }

  &.container-margin-extra-large {
    padding-top: 8em;
    padding-bottom: 8em;
  }
}

.text-alignment-left {
  .brandify-title,
  .brandify-description {
    text-align: left;
    margin-left: 4rem;
  }
}

.text-alignment-center {
  .brandify-title,
  .brandify-description {
    text-align: center;
  }
}

.text-alignment-right {
  .brandify-title,
  .brandify-description {
    text-align: right;
    margin-right: 4rem;
  }
}
