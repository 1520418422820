.cmp-tabs {
  padding: 1.3rem;
  width: 100%;

  .cmp-tabs__tab {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2.94rem;
    &.cmp-tabs__tab--active {
      border: none;
    }

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }
    @media screen and (min-width: $screen-md) {
      min-height: 3.25rem;
    }
  }

  .cmp-tabs__tablist {
    margin-bottom: 0;
  }
}

@media screen and (min-width: $screen-md) {
  .cmp-tabs {
    .cmp-tabs__tab {
      padding: 0.5rem 5rem;
      flex: 1;
      text-align: center;
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .cmp-tabs {
    padding: 0;
    margin: auto;
    width: 1170px;
  }
}
