@use "sass:math";

.tag-display-home {
  margin: 40px 24px;

  .tag-title {
    font-size: 18px;
    line-height: 1.2;

    @media screen and (min-width: $screen-md) {
      font-size: 21px;
    }
  }

  .list-of-emblems {
    padding-top: 20px;

    @media screen and (min-width: $screen-md) {
      padding-top: 12px;
      column-count: 2;
    }
  }

  .emblem-link {
    position: relative;
    display: block;
    max-width: fit-content;
    margin-bottom: 20px;
    padding: 4px 0 4px 1px;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    &:focus::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @media screen and (min-width: $screen-md) {
      margin-bottom: 10px;
    }
  }

  .emblem-text {
    font-size: $font-size-small;
    line-height: math.div(13, 12);
    letter-spacing: 2px;
  }

  @media screen and (min-width: $screen-md) {
    margin: 40px 0;
  }
}
