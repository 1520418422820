$platform-input-length-md: 474px;

.field-toggle-home {
  @media screen and (min-width: $screen-sm-md) {
    width: $platform-input-length-md;
  }

  .cmp-form-options__field--drop-down {
    height: 3em;
    padding: 12px;
    background-image: $icon-dropdown;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center right 12px;
    cursor: pointer;
    appearance: none;
    @media screen and (min-width: $platform-input-length-md) {
      width: $platform-input-length-md;
    }
  }

  .field-toggle-list {
    margin: 12px 0;
  }

  .cmp-form-options {
    fieldset {
      margin: 0;
    }
  }
}

.field-toggle-parsys {
  display: none;

  &.edit-mode {
    display: block;
    border: 2px solid blue;
  }

  h1 {
    display: none;

    &.edit-mode {
      display: block;
    }
  }

  &.active {
    display: block;
  }
}
