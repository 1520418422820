.collection-card-href {
  text-decoration: none;
}
.collection-card-home {
  &.each-card {
    border-bottom: none;
    padding: 0;
    &.container-margin-small {
      @media screen and (min-width: $screen-md) {
        margin-top: 2em;
        margin-bottom: 2em;
      }
    }

    &.container-margin-medium {
      @media screen and (min-width: $screen-md) {
        margin-top: 4em;
        margin-bottom: 4em;
      }
    }

    &.container-margin-large {
      @media screen and (min-width: $screen-md) {
        margin-top: 6em;
        margin-bottom: 6em;
      }
    }

    &.container-margin-extra-large {
      @media screen and (min-width: $screen-md) {
        margin-top: 8em;
        margin-bottom: 8em;
      }
    }
  }
  //kick off build
  &:hover {
    .show-more-container {
      display: none;
    }
    .hide-initially {
      display: block;
    }
  }

  &.text-on-left {
    flex-direction: column-reverse;
    @media screen and (min-width: $screen-md) {
      flex-direction: row;
    }
  }
  &.text-on-right {
    flex-direction: column;
    @media screen and (min-width: $screen-md) {
      flex-direction: row;
    }
  }

  .show-initially {
    display: block;
  }
  .hide-initially {
    display: none;
  }

  .mobile-text-area {
    display: block;
    @media screen and (min-width: $screen-md) {
      display: none;
    }
  }

  .desktop-text-area {
    display: none;
    @media screen and (min-width: $screen-md) {
      display: block;
    }
  }

  .each-text-area {
    width: 100%;
    padding: 40px 20px;

    @media screen and (min-width: $screen-md) {
      width: 75%;
      padding: 40px 80px;
    }
  }

  .collection-chips {
    display: flex;
    align-items: center;
  }

  .list-of-chips {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .show-more-container {
    margin-left: rem(12px);
  }

  .each-chip {
    width: rem(30px);
    height: rem(30px);
    background-color: var(--chip-color);
    clip-path: polygon(0 100%, 0 33.33%, 50% 0, 100% 33.33%, 100% 100%);

    &:not(:first-child) {
      margin-left: rem(-6px);
    }

    @media screen and (min-width: $screen-md) {
      width: rem(38px);
      height: rem(38px);

      &:not(:first-child) {
        margin-left: rem(-12px);
      }
    }
  }
}
