.cbg-cmp-searchbar {
  max-width: 800px;
  margin: 1em auto;

  &.mobile-only {
    display: block;
    @media screen and (min-width: $screen-lg-md) {
      display: none;
    }
  }

  &.desktop-only {
    display: none;
    @media screen and (min-width: $screen-lg-md) {
      display: block;
    }
  }

  &.preview-mode,
  &.edit-mode {
    &.mobile-only,
    &.desktop-only {
      display: block;
    }
  }

  .cmp-searchbar__field {
    height: auto;
    position: relative;
  }

  .cmp-searchbar__input {
    border-radius: 4rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    width: calc(100% - 80px);
    padding-left: 1em;

    @media screen and (max-width: $screen-md) {
      width: calc(100% - 36px);
    }

    &:focus::-webkit-input-placeholder {
      transition: opacity 0.5s 0.5s ease;
      opacity: 0;
    }

    &:hover {
      @media (hover: hover) {
        border-color: #92959d;
      }
    }

    + .cmp-searchbar__clear {
      position: absolute;
      right: 40px;
      padding: 0;
      max-height: 100%;
      background-color: transparent;

      @media screen and (min-width: $screen-md) {
        right: 68px;
      }
    }
  }

  .cmp-searchbar__search {
    position: absolute;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    padding: 0;
    width: 79px;
    height: 100%;
    transition: background-color 0.15s ease-in-out;

    cursor: pointer;

    &:focus {
      box-shadow: inset 0 0 0 2px #ced1d4;
    }

    @media screen and (max-width: $screen-md) {
      width: 44px;
      right: 8px;
    }

    .cmp-searchbar__search-icon {
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .cmp-search[role="search"] {
    position: static;
    display: flex;
    align-items: center;
    height: 100%;
  }

  button.btn-searchbar-toggle {
    display: none;
  }

  .cmp-searchbar__form-container {
    width: 100%;

    form {
      margin: 0 auto;

      display: grid;
      align-items: center;

      .cmp-searchbar__field {
        height: auto;
      }

      .cmp-search__loading-indicator {
        left: auto;
        right: 0.5rem;
      }
    }
  }

  .icon {
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
  }

  .cmp-searchbar__clear {
    opacity: 0;
    transform: translateX(1em);
    transition: all 100ms ease-in;
    transition-delay: 50ms;
    cursor: pointer;
    pointer-events: none;
    margin-right: 1.6em;
    width: 12px;

    &.active {
      transform: translateX(0);
      opacity: 1;
      pointer-events: auto;
    }

    .cmp-searchbar__clear-icon {
      height: 50px;
      width: 12px;
      display: block;
      background-size: contain;
    }
  }

  .cmp-searchbar__results-inner-container {
    max-width: $container-tablet;
    margin: 0 auto;
    padding: 1em;
    display: none;

    &.active {
      display: block;
    }

    &.ais-Hits--empty,
    &.cmp-searchbar__results-inner-container-no-results {
      display: block;
    }

    @media screen and (min-width: $screen-md) {
      padding: 0;
    }
  }
}
