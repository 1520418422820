.content-drawer-home {
  padding: 0;
  padding-bottom: 10px;

  p {
    margin: 0;
  }

  .title {
    font-size: $font-size-h2;
  }

  .description {
    font-size: $font-size-h6;
  }

  .show-less-button {
    margin: 10px auto;
  }

  .button-home {
    &.hidden {
      display: none;
    }
  }

  .hidden-content-fragment-zone {
    .card-holder {
      width: 90%;
      margin: auto;
    }
  }

  .show-in-edit-mode {
    background-color: grey;
    border: 2px double black;
  }

  .show-more-button {
    margin: 10px auto;
  }

  .hidden-content-area {
    [hidden] {
      display: none !important;
    }

    &.open {
      //overflow-y: hidden;
      //max-height: 500px; /* approximate max height */

      transition-property: all;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    &.closed {
      max-height: 0;
    }
  }
}
