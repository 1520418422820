// Base Styles

.cmp-adaptiveform-dropdown-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .cmp-adaptiveform-dropdown {
    display: inline-block;
    position: relative;

    // Dropdown Button Styles
    &__button {
      padding: 10px 15px;
      font-size: 16px;
      color: #333;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 0px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 384px;

      &:hover,
      &:focus {
        border-color: #999;
        outline: none;
      }

      &::after {
        content: "";
        border: 5px solid transparent;
        border-top-color: #333;
        margin-left: 10px;
      }
    }

    // Dropdown Menu Styles
    &__menu {
      display: none; // Initially hidden
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      border-top: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      z-index: 1000;
      padding: 7px 0;
      margin: 0;
      list-style: none;
      cursor: pointer;

      &.visible {
        display: block;
      }

      li.cmp-adaptiveform-dropdown {
        &__option {
          padding: 10px 14px 7px;
          font-size: 16px;
          cursor: pointer;
          color: $platform-dark-gray;

          &:hover,
          :focus {
            background-color: $plaform-gray-fill;
            color: $platform-white;
          }
        }
      }
    }

    // Dropdown Label Styles
    &__label {
      color: $platform-black;
      font-size: 22px;
      font-weight: 700;
      display: block;
      margin-bottom: 5px;
    }

    // Short Description Styles
    &__shortdescription {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
      display: none; // Initially hidden

      &.visible {
        display: block;
      }
    }

    // Responsive Styles
    @media screen and (max-width: $screen-sm-md) {
      &__button {
        font-size: 14px;
        padding: 8px 12px;
      }

      &__menu__option {
        padding: 8px 12px;
      }

      &__label {
        font-size: 16px;
      }
    }

    @media screen and (max-width: $screen-sm) {
      &__button {
        width: 330px;
      }
    }
    @media screen and (max-width: $screen-sm) {
      &__button {
        width: 330px;
      }
    }
  }

  &.experience-fragment-container {
    margin-top: 40px;
    padding: 1rem;
    border: 1px solid #000000;
    background-color: #f9f9f9;
  }
}
