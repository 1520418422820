.product-specs-main {
  width: 100%;
  padding-right: 0.94rem;
  padding-left: 0.94rem;
  margin: 2rem auto 2rem auto;
  background-color: $platform-white;

  .doclink {
    color: $brand-dark-blue;
    text-decoration-color: $brand-light-blue;
    text-decoration-thickness: 2px;

    &:hover {
      text-decoration-color: $brand-dark-blue;
    }

    &:focus {
      outline-color: $brand-light-gray;
      margin-left: 2px;
    }
  }

  @media screen and (min-width: $screen-sm) {
    &.add-gutter {
      width: 100%;
    }
    @media screen and (min-width: $screen-lg) {
      margin: auto;
      max-width: 1200px;
    }
  }
  .button-home {
    justify-content: left;
    margin-left: 13px;
  }

  .product-specs-content {
    display: flex;
    flex-direction: column;
    .specs-page-title {
      text-transform: capitalize;
    }
  }

  .product-specs-top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .product-specs-title {
    color: $platform-dark-navy-blue;
    text-transform: uppercase;
    margin-bottom: 1.9rem;
    font-size: $rem-size-mobile-H3;
    line-height: 2.4rem;
    font-weight: 500;
    width: 100%;
  }

  .product-specs-anchor-container {
    color: $platform-dark-navy-blue;
    font-weight: 500;
    margin-bottom: 1.3rem;
    a {
      color: $platform-dark-navy-blue;
      font-weight: 500;
    }
    span {
      color: $platform-dark-navy-blue;
      font-weight: 500;
    }
  }

  .product-specs-subtitle {
    color: $platform-dark-navy-blue;
    text-align: left;
    font-family: $font-ibm-regular;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  .product-specs-right {
    select {
      appearance: none;
      background-image: url(../resources/images/svgs/chevron-sort.svg);
      background-position: 100%;
      background-size: 1.6rem;
      background-repeat: no-repeat;
      height: 2.8rem;
      width: 10rem;
      box-sizing: border-box;
      border: 2px solid $platform-image-upload-border;
      color: $platform-dark-blue;
      font-size: $font-size;
      font-weight: 500;
      padding-left: 12px;
      outline: none;
      @media screen and (min-width: $screen-lg-md) {
        height: 3rem;
        width: 11rem;
        background-position: 93%;
      }
    }
    select:hover,
    select:focus,
    select:active {
      border: 2px solid $platform-wall-dropdown-menu-border;
    }

    label {
      display: none;
      @media screen and (min-width: $screen-lg-md) {
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.25px;
        padding-right: 8px;
      }
    }
  }

  select::-ms-expand {
    display: none;
  }

  .product-specs-bottom {
    overflow-x: auto;
    width: 100%;
  }

  .product-specs-table {
    table {
      width: 100%;
      color: $platform-dark-blue;
      text-align: left;
      border-collapse: collapse;
      text-indent: initial;
      border: none;
    }

    td {
      border: none;
      padding: 15px 10px;
      white-space: nowrap;
    }

    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: rgba(22, 67, 85, 0.2);
      color: $platform-dark-navy-blue;
      border-bottom: 1px solid rgba(22, 67, 85, 0.2);
    }

    tr:last-of-type {
      border-bottom: none;
    }

    th {
      border: none;
      white-space: nowrap;
      text-align: inherit;
      padding: 15px 10px;
      cursor: pointer;
    }

    th:last-child {
      padding-right: 0;
    }
  }

  @media screen and (min-width: $screen-sm) {
    overflow-x: auto;
    padding-bottom: 2.8rem;
    padding-top: 2.8rem;
    .product-specs-table {
      table {
        margin-top: 1.5rem;
        border-spacing: 0;
        border-collapse: collapse;
        width: 100%;
        font-size: $font-size;
        border: none;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    padding-bottom: 6.3rem;
    padding-top: 6.3rem;
    font-weight: normal;
    letter-spacing: 1px;
    .product-specs-title {
      font-size: $rem-size-H2-5;
      line-height: 3.8rem;
    }
    .product-specs-top {
      justify-content: space-between;
    }

    .product-specs-subtitle {
      flex: 0 0 66%;
      max-width: 66%;
      position: relative;
      width: 100%;
    }
    .product-specs-right {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
  }
  @media screen and (min-width: $screen-md-lg) {
    .product-specs-content {
      display: flex;
      flex-direction: column;
    }
    .product-specs-top {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    .product-specs-bottom {
      margin-bottom: 2.5rem;
    }
    .product-specs-title {
      letter-spacing: 1px;
      margin-bottom: 2rem;
      font-weight: 500;
      margin-top: 0;
      text-align: left;
    }
    .product-specs-subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      flex: 0 0 58%;
    }
    .product-specs-right {
      position: relative;
      padding-right: 0.94rem;
      padding-left: 0.94rem;
    }

    .product-specs-table {
      table {
        width: 100%;
        font-size: 18px;
        margin-top: 1.5rem;
        border-spacing: 0;
        font-weight: 400;
        line-height: 1.5;
        border-collapse: collapse;
        border: none;
      }

      tr {
        display: table-row;
        color: $platform-dark-navy-blue;
      }

      th {
        padding: 0.94rem 10px;
        line-height: 2rem;
        border: none;
      }
    }
  }
  @media screen and (min-width: $screen-lg) {
    .product-specs-title {
      flex: 0 0 58;
      padding-right: 0.94rem;
      padding-left: 0.94rem;
      margin-bottom: 1.9rem;
    }
    .product-specs-anchor-container {
      flex: 0 0 58;
      padding-right: 0.94rem;
      padding-left: 0.94rem;
      margin-bottom: 1.9rem;
    }
    .product-specs-subtitle {
      width: 100%;
      padding-right: 0.94rem;
      padding-left: 0.94rem;
    }
    .product-specs-top {
      margin-right: -0.94rem;
      margin-left: -0.94rem;
    }
    .product-specs-table {
      margin-top: 1.5rem;
      border-spacing: 0;
      border-collapse: collapse;

      thead {
        border-spacing: 0;
        border-collapse: collapse;
      }

      th {
        padding: 0.94rem 10px;
      }
    }
  }
}
.sortable {
  span.sorting {
    padding: 0.25rem 1.313rem 0.25rem 0.25rem;
    pointer-events: none;
    background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==)
      no-repeat center right;
    display: initial;
    height: 30px;
    margin: 0;
  }
}
// Following selectors are ids from sortable (added to sort data sheets on PDPs) npm library which are added for icons dynamically
//Dummy Build
#sorttable_sortrevind,
#sorttable_sortfwdind {
  pointer-events: none;
}

.product-specs-row-header {
  .sorttable_sorted,
  .sorttable_sorted_reverse {
    p {
      position: absolute;
    }
  }
  #sorttable_sortrevind {
    font-size: 1.125rem;
  }
  #sorttable_sortfwdind {
    font-size: 1.125rem;
  }
}
.productSpecs {
  &.product-lookup {
    .product-specs {
      padding-top: 20px;
    }
  }
}
