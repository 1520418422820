// Body
html,
body {
  font-family: $font-family-default;
  color: $platform-font-medium-scheme;
  scroll-behavior: initial;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
