//===================================================================================
//=============================== Style System Section ==============================
//==================================================================================
.prism-color-wall {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.p3m button {
  width: unset;
}
