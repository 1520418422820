.cmp-experiencefragment--header {
  z-index: 25;
  .cbg-cmp-searchbar {
    position: static;

    button.btn-searchbar-toggle {
      background-color: $platform-dark-gray;

      padding: 0;
      margin: 0 auto;
      width: 45px;
      height: 45px;

      position: relative;

      z-index: $z-level2;

      &:hover {
        @media (hover: hover) and (min-width: $screen-md) {
          cursor: pointer;
        }
      }

      .cmp-searchbar__clear-icon,
      .hide-text {
        display: none;
      }

      .cmp-searchbar__search-icon {
        display: block;
      }

      .icon {
        position: static;

        margin: 0 auto;
        width: 18px;
        height: 18px;
      }

      svg {
        max-width: 100%;
        max-height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        path {
          fill: #002e5d;
        }
      }
    }

    .cmp-searchbar__form-container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      width: 100vw;
      margin-left: calc(-50vw + 50%);

      background-color: $plaform-gray-fill;

      opacity: 0;
      transition: opacity 250ms ease-in;
      pointer-events: none;

      form {
        max-width: 760px;
        width: 100%;
        height: 100%;
        margin: 0 auto;

        display: grid;
        align-items: center;

        @media screen and (max-width: $screen-md) {
          margin: 0 1em;
          max-width: calc(100% - 70px);
          position: static;
        }

        .cmp-search__loading-indicator {
          left: auto;
          right: 0.5rem;
        }
      }
    }

    &.expanded {
      .cmp-searchbar__form-container {
        opacity: 1;
        pointer-events: all;
        z-index: $z-level3;
      }

      button.btn-searchbar-toggle .display-block {
        z-index: $z-level4;
        position: absolute;
        right: 8px;
        display: block;

        @media screen and (min-width: $screen-md) {
          display: none;
        }

        .cmp-searchbar__search-icon {
          display: none;
        }

        .cmp-searchbar__clear-icon {
          display: block;
        }
      }

      .cmp-searchbar__input {
        &:hover {
          border-color: $platform-dark-gray;
        }
      }
    }
  }

  .cmp-searchbar__results.active:after {
    content: "";
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
  }

  .one-point-one {
    height: 2.2rem;
    width: 8.1rem;

    button {
      width: 2.4rem;
      height: 2.2rem;
      @media screen and (min-width: $screen-md) {
        height: 2.5rem;
      }
    }

    input {
      width: 5.8rem;
      height: 2.2rem;
      @media screen and (min-width: $screen-md) {
        height: 2.5rem;
        width: 7.6rem;
      }
    }
    @media screen and (min-width: $screen-md) {
      width: 9.9rem;
      height: 2.5rem;
    }
  }

  .search-one-point-one {
    margin: 0 0 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    button.btn-searchbar-toggle .toggle-display-none {
      display: none;
    }
    @media screen and (min-width: $screen-md) {
      margin: 0 20px;
    }
  }
}

.cmp-searchbar__results {
  background-color: $platform-white;
  box-shadow: 0 6px 12px -3px #ccc;

  opacity: 0;
  transition:
    opacity 100ms ease-in,
    height 100ms ease-in 500ms;
  transition-delay: 50ms;
  width: 100%;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .cmp-searchbar__results-inner-container {
    max-width: $container-tablet;
    margin: 0 auto;
    padding: 1em;
    display: none;

    &.active {
      display: block;
      max-height: 250px;
      overflow: scroll;
      height: min-content;
    }

    &.ais-Hits--empty,
    &.cmp-searchbar__results-inner-container-no-results {
      display: block;
    }

    @media screen and (min-width: $screen-md) {
      padding: 0;
    }
  }

  .cmp-searchbar__results-list {
    margin: 0;
    padding: 0;
  }
}

.cmp-searchbar__results-item {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: $platform-cream;
  }

  > a {
    color: inherit;
    font-weight: 500;
    padding: 15px;
    display: block;
    width: 100%;
    text-decoration: none;

    &:focus {
      background-color: $platform-cream;
      outline: none;
      border: 0;
    }

    @media screen and (min-width: $screen-md) {
      padding: 15px 0;
    }
  }

  mark {
    font-weight: bold;
    background-color: transparent;
    color: inherit;
    pointer-events: none;
  }
}
