.cbg-cmp-toolTip {
  &button {
    margin: 0;
    padding: 0;
    background: 0;
  }

  // Tool Tip authorable image icon
  .cbg-cmp-toolTip-icon {
    width: 1.3rem;
    position: relative;
  }

  .style-on-same-line {
    .cbg-cmp-toolTip-icon {
      margin-right: 15px;
      margin-bottom: 4px;
    }
  }

  .cbg-cmp-toolTip-icon-image {
    pointer-events: none;
  }

  .cbg-cmp-toolTip-btn-show {
    cursor: pointer;

    span.icon {
      pointer-events: none;
    }
  }

  .cbg-cmp-icon-image {
    display: inline;
    width: 30px;
  }

  //  Tool Tip content area, set to hidden
  .cbg-cmp-toolTip-inner-container {
    visibility: hidden;
    display: none;
    z-index: 10;
    position: absolute;
  }

  // Alignment classes
  .text-left {
    text-align: left;

    .cbg-cmp-toolTip-btn-close {
      right: 10px;
    }
  }

  .text-right {
    text-align: right;

    .cbg-cmp-toolTip-btn-close {
      left: 10px;
    }
  }

  .text-center {
    text-align: center;

    .cbg-cmp-toolTip-btn-close {
      right: 10px;
    }
  }

  .text-segments {
    &.has-icon {
      margin-left: -10px;
    }
  }

  .segment-anchor {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: $platform-light-gray-outline;
    color: $platform-font-gray-scheme;

    &:hover {
      text-decoration-color: $platform-dark-gray;
    }
  }

  // Background color classes
  .dark-color {
    background-color: $platform-dark-blue;
    color: $platform-white;
  }

  .light-color {
    background-color: $platform-white;
    color: $platform-dark-gray;
  }

  .neutral-color {
    background-color: $platform-background-light-gray-scheme;
    color: $platform-font-gray-scheme;
  }

  // Authorable Margin Classes
  .small-margin {
    margin: 5px 0 5px 0;
  }

  .medium-margin {
    margin: 10px 0 10px 0;
  }

  .large-margin {
    margin: 20px 0 20px 0;
  }

  //Tooltip textSegment sizing
  .icon-size-small {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  .icon-size-medium {
    svg {
      height: 30px;
      width: 30px;
    }
  }

  .icon-size-large {
    svg {
      height: 40px;
      width: 40px;
    }
  }

  //Tool Tip authorable content area positioning
  .open-above {
    .cbg-cmp-toolTip-inner-container {
      transform: translate(-44%, -125%);
      top: 24px;
      left: 4.3rem;
      @media screen and (min-width: $screen-lg-md) {
        left: -12px;
        top: 20px;
      }
    }

    .cbg-cmp-toolTip-inner-container:after {
      left: 2.2rem;
      border-right: 2px solid $platform-dark-blue;
      border-bottom: 2px solid $platform-dark-blue;
      bottom: -11px;
      @media screen and (min-width: $screen-lg-md) {
        left: 9.7rem;
        bottom: -12px;
      }
    }
  }

  .open-below {
    .cbg-cmp-toolTip-inner-container {
      top: -81px;
      left: 67px;
      @media screen and (min-width: $screen-lg-md) {
        left: 11px;
        top: -35px;
      }
      transform: translate(-44%, 60%);
    }

    .cbg-cmp-toolTip-inner-container:after {
      left: 2.5rem;
      bottom: 103px;
      top: -12px;
      border-left: 2px solid $black;
      border-top: 2px solid $black;

      @media screen and (min-width: $screen-lg-md) {
        left: 9rem;
      }
    }
  }

  // Left and right position classes
  .open-left {
    &.open-above {
      .cbg-cmp-toolTip-inner-container {
        transform: translate(-3%, -120%);
        top: 15px;
        left: -0.1rem;
        @media screen and (min-width: $screen-lg-md) {
          top: 5px;
          left: -10px;
        }
      }

      .cbg-cmp-toolTip-inner-container:after {
        left: 1rem;
      }
    }

    &.open-below {
      .cbg-cmp-toolTip-inner-container {
        transform: translate(-3%, 30%);
        top: -13px;
        left: -1px;
        @media screen and (min-width: $screen-lg-md) {
          top: 6px;
          left: 2px;
        }
      }

      .cbg-cmp-toolTip-inner-container:after {
        left: 1rem;
      }
    }
  }

  .open-right {
    &.open-above {
      .cbg-cmp-toolTip-inner-container {
        transform: translate(-87%, -120%);
        left: 7rem;
        top: 13px;
        @media screen and (min-width: $screen-lg-md) {
          top: 10px;
          left: -12px;
        }
      }

      .cbg-cmp-toolTip-inner-container:after {
        left: 40px;
        @media screen and (min-width: $screen-lg-md) {
          left: 18.7rem;
        }
      }
    }

    &.open-below {
      .cbg-cmp-toolTip-inner-container {
        transform: translate(-87%, 30%);
        top: -14px;
        left: 170px;
        @media screen and (min-width: $screen-lg-md) {
          top: -6px;
          left: -12px;
        }
      }

      .cbg-cmp-toolTip-inner-container:after {
        left: 2rem;
        @media screen and (min-width: $screen-lg-md) {
          left: 18.7rem;
        }
      }
    }
  }

  .close-btn-image {
    pointer-events: none;
  }
}

// ============ Show Tool Tip when clicked class ============ //
.show-tooltip {
  .cbg-cmp-toolTip-inner-container {
    box-sizing: border-box;
    height: fit-content;
    width: 14rem;
    border: 2px solid $black;
    padding: 20px;
    display: inline-block;
    visibility: visible;
    transition: all 0.2s ease;
    position: absolute;
    left: 198%;
    z-index: 10;
    @media screen and (min-width: $screen-lg-md) {
      width: 21rem;
    }
  }

  // Tool Tip triangle
  .cbg-cmp-toolTip-inner-container:after {
    content: "";
    position: absolute;
    width: 1.3rem;
    height: 1.3rem;
    background: inherit;
    transform: rotate(45deg);
  }

  // Tool tip title class
  .cbg-cmp-toolTip-title {
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.5rem;
    font-size: $font-size;
    font-family: $font-ibm-regular;
  }

  // Tool tip description class
  .cbg-cmp-toolTip-description {
    font-size: $font-size;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin: 0;
    font-family: $font-ibm-regular;

    a {
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-color: $platform-light-gray-outline;
      color: $platform-font-gray-scheme;

      &:hover {
        text-decoration-color: $platform-dark-gray;
      }
    }
  }

  .cbg-cmp-toolTip-text-container {
    a {
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-color: $platform-light-gray-outline;
      color: $platform-font-gray-scheme;

      &:hover {
        text-decoration-color: $platform-dark-gray;
      }
    }
  }

  // Tool tip authorable close button icon class
  .cbg-cmp-toolTip-btn-close {
    background: inherit;
    position: absolute;
    cursor: pointer;
    top: 5px;
    padding: 0;
    width: 20px;
    height: 20px;
    z-index: 10;

    * {
      pointer-events: none;
    }

    .icon svg {
      margin: 0;
    }
  }
}

// ============ End of Show Tool Tip Class ============ //

// ============ Text Segment Class ============ //
.text-segments {
  vertical-align: middle;
  align-items: center;
  padding: 0 5px;

  .cbg-cmp-toolTip-btn-show {
    svg {
      height: 20px;
      width: 20px;
      margin: 2px 10px 0 10px;
      vertical-align: middle;
    }

    .icon-spacer {
      height: 20px;
      width: 20px;
      margin: 2px 10px 0 10px;
      vertical-align: middle;
    }
  }

  &.style-on-same-line {
    display: flex;

    .same-line {
      display: block;
      margin: 0 4px;
    }
  }

  .same-line,
  .tooltip-button {
    display: inline;
  }

  br {
    display: none;
  }

  .segment {
    //padding-left: 4px;
    //padding-bottom: 4px;
  }

  .text-segments-image-container {
    padding-left: 4px;
    cursor: pointer;
    position: relative;

    svg {
      height: 20px;
      width: 20px;
      margin: -3px 4px 0;
      vertical-align: middle;
    }

    &.icon-size-small {
      svg {
        height: 20px;
        width: 20px;
      }
    }

    &.icon-size-medium {
      svg {
        height: 30px;
        width: 30px;
      }
    }

    &.icon-size-large {
      svg {
        height: 40px;
        width: 40px;
      }
    }

    .icon-spacer {
      height: 20px;
      width: 20px;
      margin: 2px 10px 0 10px;
      vertical-align: middle;
    }

    span {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .removeCursor {
    cursor: auto;
  }

  .image-segment,
  .cbg-cmp-toolTip-icon-image {
    height: 40px;
    position: relative;
    top: 15px;
    display: inline;
  }
}

.icon-stack {
  display: flex;
  align-items: center;

  .icon-stack-svg {
    width: 70px;
    margin-top: 10px;
    svg {
      height: 40px;
      width: 40px;
    }
  }
}

// ============ End of Text Segment Class ============ //

// Text alignment classes for Text segment
.text-align-left {
  .text-segments {
    text-align: left;
    justify-content: flex-start;
  }
}

.text-align-center {
  .text-segments {
    text-align: center;
    justify-content: center;
  }
}

.text-align-right {
  .text-segments {
    text-align: right;
    justify-content: flex-end;
  }
}

// Margin classes for Text segment
.segment-margin-0 {
  .text-segments {
    margin: 0;
  }
}

.segment-margin-5 {
  .text-segments {
    margin: 5px;
  }
}

.segment-margin-10 {
  .text-segments {
    margin: 10px;
  }
}

.segment-margin-20 {
  .text-segments {
    margin: 20px;
  }
}

// Padding classes for Text segment
.segment-padding-0 {
  .text-segments {
    padding: 0;
  }
}

.segment-padding-5 {
  .text-segments {
    padding: 5px;
  }
}

.segment-padding-10 {
  .text-segments {
    padding: 10px;
  }
}

.segment-padding-20 {
  .text-segments {
    padding: 20px;
  }
}
