.newsletter-component {
  /* Container Variables */
  --newsletter-max-width: 70%;
  --newsletter-padding: #{rem(20px)};

  /* Title Variables */
  --newsletter-title-font-size: #{rem(32px)};
  --newsletter-title-font-weight: bold;
  --newsletter-title-margin-bottom: #{rem(20px)};
  --newsletter-title-color: #333;

  /* Description Variables */
  --newsletter-description-font-size: 1.25rem;
  --newsletter-description-margin-bottom: #{rem(30px)};
  --newsletter-description-color: #555;
  --newsletter-description-line-height: 1.6;

  /* Content Variables */
  --news-item-font-size: 1.25rem;
  --news-item-padding: 1.5rem 0;
  --news-item-gap: 1.5rem;
  --news-item-margin-top: 4rem;

  /* Date and Source Variables */
  --news-date-flex-basis: 30%;
  --news-date-font-family: #{$brand-primary};
  --news-date-font-size: inherit;
  --news-date-color: inherit;
  --newsletter-source-flex-basis: 70%;

  /* News Item Title Variables */
  --news-item-title-margin-bottom: #{rem(16px)};
  --news-item-title-font-size: 1.2rem;
  --news-item-title-color: inherit;

  /* News Item Description Variables */
  --news-item-description-margin-top: 0;
  --news-item-description-margin-bottom: 0;
  --news-item-description-font-size: inherit;
  --news-item-description-font-family: inherit;

  /* Link Variables for Source Links */
  --newsletter-source-link-font-weight: normal;
  --newsletter-source-link-font-family: inherit;
  --newsletter-source-link-border-bottom: none;
  --newsletter-source-link-padding-bottom: 0;
  --newsletter-source-link-font-size: 1rem;
  --newsletter-source-link-text-decoration: none;
  --newsletter-source-link-color: inherit;
  --newsletter-source-link-margin-bottom: var(--news-item-title-margin-bottom);
  --newsletter-source-link-display: inline;
  --newsletter-source-link-width: auto;
  --newsletter-source-link-hover-border-bottom: none;

  /* Variables for Mobile */
  --newsletter-max-width-mobile: 92%;
  --newsletter-date-padding-bottom-mobile: 0.6rem;
  --news-item-padding-mobile: 0.625rem;
  --news-item-font-size-mobile: 1.1rem;

  margin: 0 auto;
  max-width: var(--newsletter-max-width);
  padding: var(--newsletter-padding);
  text-align: justify;

  .newsletter-title {
    font-size: var(--newsletter-title-font-size);
    font-weight: var(--newsletter-title-font-weight);
    margin-bottom: var(--newsletter-title-margin-bottom);
    color: var(--newsletter-title-color);
  }

  .newsletter-description {
    font-size: var(--newsletter-description-font-size);
    margin-bottom: var(--newsletter-description-margin-bottom);
    color: var(--newsletter-description-color);
    line-height: var(--newsletter-description-line-height);
  }

  .newsletter-content {
    margin-top: var(--news-item-margin-top);
    .news-item {
      font-size: var(--news-item-font-size);
      display: flex;
      flex-direction: row;
      padding: var(--news-item-padding);
      gap: var(--news-item-gap);

      .news-date {
        flex: 0 1 var(--news-date-flex-basis);
        font-family: var(--news-date-font-family);
        text-align: left;
        font-size: var(--news-date-font-size);
        color: var(--news-date-color);
      }

      .newsletter-source {
        flex: 0 1 var(--newsletter-source-flex-basis);
        font-size: var(--news-item-font-size);
        line-height: 1.5em;

        a {
          font-weight: var(--newsletter-source-link-font-weight);
          font-family: var(--newsletter-source-link-font-family);
          border-bottom: var(--newsletter-source-link-border-bottom);
          padding-bottom: var(--newsletter-source-link-padding-bottom);
          font-size: var(--newsletter-source-link-font-size);
          text-decoration: var(--newsletter-source-link-text-decoration);
          color: var(--newsletter-source-link-color);
          margin-bottom: var(--newsletter-source-link-margin-bottom);
          display: var(--newsletter-source-link-display);
          width: var(--newsletter-source-link-width);

          &:hover {
            border-bottom: var(--newsletter-source-link-hover-border-bottom);
            cursor: pointer;
          }

          &.news-item-title {
            font-size: var(--news-item-title-font-size);
            margin-bottom: var(--news-item-title-margin-bottom);
            & + .news-item-description {
              padding-top: 1rem;
            }
          }
        }
        .news-item-description p {
          margin-top: 0;
          color: var(--news-item-title-color);
          font-size: var(--news-item-title-font-size);
          font-family: var(--news-item-description-font-family);
          font-weight: initial;
        }
      }
    }
  }
  /* Responsive adjustments for smaller screens */
  @media screen and (max-width: $screen-md) {
    max-width: var(--newsletter-max-width-mobile);
    padding: var(--news-item-padding-mobile);

    .newsletter-content .news-item {
      flex-wrap: wrap;
      gap: 0;

      .news-date {
        padding-bottom: var(--newsletter-date-padding-bottom-mobile);
        flex: 0 0 100%;
      }

      .newsletter-source {
        flex: 0 0 100%;
        .news-item-description p {
          font-size: var(--news-item-font-size-mobile);
        }
      }
    }
  }
}
