.add-gutter .cbg-cmp-colorPalette {
  @media screen and (min-width: $screen-md) {
    margin: 0 auto;
    width: rem(1220px);
  }
}

.cbg-cmp-colorPalette {
  padding: rem(0 20px);
}

.cmp-colorPalette__title {
  font-size: rem(28px);

  @media screen and (min-width: $screen-md) {
    margin: 0;
    font-size: rem(36px);
  }
}

.cmp-colorPalette__description {
  font-size: rem(16px);

  @media screen and (min-width: $screen-md) {
    margin: 0;
    font-size: rem(18px);
  }
}

.cmp-colorPalette__main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: rem(20px 0);

  @media screen and (min-width: $screen-md) {
    justify-content: flex-start;
    flex-direction: row;
  }
}

.cmp-colorPalette__color-container {
  display: flex;
  width: 80%;
  flex-direction: column;
}

.cmp-colorPalette__color-main,
.cmp-colorPalette__color-primary,
.cmp-colorPalette__color-secondary,
.cmp-colorPalette__color-tertiary {
  height: rem(85px);
}

.cmp-colorPalette__color-main {
  height: rem(250px);

  @media screen and (min-width: $screen-md) {
    height: 100%;
  }
}

.cmp-colorPalette__coordinating-container > * {
  flex: 1 0 50%;
}

.cmp-colorPalette__coordinating-color {
  flex: 1 0;

  @media screen and (min-width: $screen-md) {
    flex: 1 0 auto;
  }
}

.cmp-colorPalette__card-container .swatch-container {
  display: flex;
  flex-direction: column;
  transform: none;
  border: none;
  width: rem(175px);

  @media screen and (min-width: $screen-md) {
    width: rem(158px);
    height: rem(144px);
  }
}

.cmp-colorPalette__card-container.each-row {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;

  @media screen and (min-width: $screen-md) {
    justify-content: flex-end;
    flex-direction: row;
    gap: rem(25px);
  }

  &.twoUpMobile {
    @media screen and (max-width: $screen-md) {
      flex-wrap: wrap;
      flex-direction: row;
      max-width: rem(324px);
      margin-left: auto;
      margin-right: auto;
      gap: 0.5rem;

      .cmp-colorPalette__card-item.card-item {
        flex-basis: calc(50% - 0.5rem);
        max-width: calc(50% - 0.5rem);

        .cmp-dynamicCard__link {
          display: inline;
        }

        .cmp-dynamicCard__parentContainer,
        .cmp-dynamicCard__card-color-swatch {
          width: 100%;
        }

        .cmp-dynamicCard__card-color-swatch {
          height: 0;
          padding-top: 100%;
        }
      }

      &.horizontal-container {
        padding: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.cmp-dynamicCard__container.cbg-cmp-card.each-card {
  margin: 0;
}

//Overrides display: block !important on container horizontal scrolling
.cmp-colorPalette__card-container.each-row.horizontal-container {
  display: flex !important;
  flex-direction: row;
  gap: rem(10px);
  margin: rem(20px 0);
  padding-bottom: 25px;
  @media screen and (min-width: $screen-md) {
    gap: rem(25px);
    margin: 0;
    padding: 0;
  }
}

.cmp-colorPalette__card-item .cmp-dynamicCard__parentContainer {
  width: rem(162px);
  @media screen and (min-width: $screen-md) {
    width: rem(179px);
  }
}

.cmp-colorPalette__card-item .card-item {
  align-items: center;
  margin: rem(0 10px);
  display: flex;
}

.cmp-colorPalette__card-item .card-item:last-child {
  margin-right: 20px;
}

.cmp-colorPalette__card-item .cbg-cmp-card__image-container {
  display: flex;
  gap: rem(20px);
}

.cmp-colorPalette__card-item .card-image-div {
  display: flex;
  gap: 0;

  @media screen and (min-width: $screen-md) {
    gap: rem(20px);
  }
}

.cmp-colorPalette__card-item .cbg-colorCard__info {
  padding: rem(12px);
}

//This hides the footer on cards atm .cbg-colorCard__footer styles are breaking color palette color card styles
.cmp-colorPalette__card-item .cbg-colorCard__footer {
  display: none;
}

// Image layout
.cmp-colorPalette__coordinating-container.cmp-colorPalette__lifestyle-image-layout {
  display: flex;
  width: auto;
  margin: rem(10px 20px);
  min-height: rem(250px);

  @media screen and (min-width: $screen-md) {
    min-height: rem(310px);
    width: 80%;
    margin: 0;
  }
}

//Left Layout
.cmp-colorPalette__coordinating-container.cmp-colorPalette__left-layout {
  display: flex;
  width: auto;
  margin: rem(10px 0px);
  min-height: rem(250px);

  @media screen and (min-width: $screen-md) {
    min-height: rem(310px);
    width: 80%;
    margin: 0;
  }
}

.cmp-colorPalette__left-layout .cmp-colorPalette__color-main {
  width: 50%;
  display: flex;
}

.cmp-colorPalette__left-layout .cmp-colorPalette__coordinating-color-container {
  display: flex;
  flex-direction: column;
}

//Top & Bottom layout

.cmp-colorPalette__coordinating-container.cmp-colorPalette__top-layout,
.cmp-colorPalette__coordinating-container.cmp-colorPalette__bottom-layout {
  display: flex;
  min-height: rem(350px);
  min-width: rem(300px);
  flex-direction: column;
  margin: rem(10px 20px);

  @media screen and (min-width: $screen-md) {
    min-height: rem(500px);
    min-width: rem(450px);
    margin: 0;
  }
}

.cmp-colorPalette__top-layout .cmp-colorPalette__coordinating-color-container,
.cmp-colorPalette__bottom-layout
  .cmp-colorPalette__coordinating-color-container {
  display: flex;
}

.cmp-colorPalette__bottom-layout .cmp-colorPalette__color-main {
  order: 1;
}

// For mobile horizontal scrolling
// Used important tags to ensure that all of the class selectors for it would be applied.
.cmp-colorPalette__card-container.horizontal-container {
  @media screen and (max-width: $screen-md) {
    display: flex !important;
    overflow-x: auto !important;
    justify-content: flex-start;
    margin-left: rem(20px);
    padding: rem(0 20px 0 0);
  }
}

.cmp-dynamicCard__card-color-swatch .cbg-favorite-cta {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $z-level2;
  pointer-events: none;

  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    right: rem(10px);
    top: rem(10px);
    cursor: pointer;
    width: rem(35px);
    height: rem(35px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: all;
    transition: opacity 0.4s ease;
    opacity: 0;
  }

  // Inactive State
  &:before {
    background-image: url("../resources/images/svgs/favorite-2-checked.svg");
    opacity: 1;
  }

  // Inactive State
  &:after {
    background-image: url("../resources/images/svgs/favorite-1-checked.svg");
    opacity: 0;
  }

  &:hover,
  &.active {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

.icon-add {
  background-image: url("../resources/images/svgs/add-chip.svg");
}

.icon-remove {
  background-image: url("../resources/images/svgs/remove-chip.svg");
}

.lowes-inspiration {
  .cmp-colorPalette__card-container.each-row {
    align-items: normal;
  }
}
