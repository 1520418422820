.carouselNotificationsBanner {
  &:not(.base) {
    opacity: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 0;
    background: #eeeeee;
    color: #000000;
    z-index: 10;

    &.hexBackground {
      background-color: var(--banner-bg-color);
    }

    &.hexText,
    &.hexText .carouselNotificationsBanner-link {
      color: var(--banner-text-color);
    }
  }

  .carouselNotificationsBanner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: rem(480px);
    width: 75%;
    text-align: center;
    padding: 0.6rem;
    gap: 0.5rem;
    pointer-events: none;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;

    .message-container {
      width: 100%;
      display: flex;
      overflow: scroll;
      scroll-behavior: smooth;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .slide-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      border-radius: unset;
      display: block;
      background-color: transparent;
      padding: 0;

      &:hover:before {
        opacity: 0.8;
      }

      &:before {
        display: block;
        margin: 1.875rem auto;
        width: 0.7rem;
        height: 0.7rem;
        border-top: 0.125rem solid var(--banner-icon-color);
        border-left: 0.125rem solid var(--banner-icon-color);
        opacity: 0.5;
        content: "";
      }
    }

    .carousel-notification-banner-slide-arrow-prev {
      left: 0;

      &:before {
        transform: rotate(-45deg);
      }
    }

    .carousel-notification-banner-slide-arrow-next {
      right: 0;

      &:before {
        transform: rotate(135deg);
      }
    }

    .carouselNotificationsBanner-message-track {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 300ms ease-in-out;
    }

    .carouselNotificationsBanner-slide {
      position: relative;
      display: inline;
      min-width: 100%;
      min-height: 100%;
      padding: 0 0.5rem;

      @media screen and (min-width: $screen-md) {
        padding: 0;
      }

      p,
      .carouselNotificationsBanner-link,
      .notificationBanner-content {
        display: inline;
        margin-top: 0;
        margin-bottom: 0;
        font-size: var(--banner-font-size-mobile);

        @media screen and (min-width: $screen-md) {
          font-size: var(--banner-font-size);
        }
      }
    }

    .carouselNotificationsBanner-message {
      margin: 0;
    }

    .carouselNotificationsBanner-link {
      text-decoration: underline;

      @media screen and (min-width: $screen-md) {
        margin-left: 0.5rem;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .backButton,
    .forwardButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1000;
    }

    .backIcon,
    .forwardIcon {
      width: 1rem;
      height: 1rem;
      fill: var(--button-bg-color);
    }

    @media screen and (min-width: $screen-md) {
      width: 90%;
      max-width: rem(800px);
      margin: auto;
      flex-direction: row;
    }
  }

  .cbg-cmp-carouselNotificationsBanner-btn-close {
    position: absolute;
    right: 0;
    margin-right: 0.25rem;
    background: unset;
    opacity: 0.5;
    height: 100%;
    width: 1rem;
    @media screen and (min-width: $screen-md) {
      margin-right: 1rem;
    }

    .close-icon {
      height: 100%;
    }

    &:hover {
      opacity: 0.8;
    }

    &:before,
    &:after {
      position: absolute;
      left: 0.938rem;
      content: "";
      height: 1.25rem;
      width: 0.125rem;
      background-color: var(--banner-icon-color, #eee);
      top: 50%;
    }

    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  @media screen and (min-width: $screen-md-lg) {
    top: rem(100px);
  }

  &.fade-in {
    opacity: 1;
    height: 3.125rem;
    pointer-events: all;
    transition: opacity 0.4s linear;
    z-index: 100;

    .carouselNotificationsBanner-content {
      pointer-events: all;
    }

    .cbg-cmp-notificationsBanner-btn-close {
      opacity: 1;
      display: none;
      transition: opacity 0s linear;

      @media screen and (min-width: $screen-md) {
        display: block;
      }

      .close-icon {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .carouselNotificationsBanner-btn {
    margin-top: 0.125rem;
  }

  &[data-notifications-banner-event="fav"] {
    position: fixed;
    max-width: rem(750px);
    width: 95%;
    height: rem(80px);
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: auto;
  }
}

/* header component styling impacted by carouselNotificationBanner */
.cmp-container:has(.carouselNotificationsBanner) {
  /*  Header v1 */
  .cmp-experiencefragment--header
    .navigation
    .cmp-navigation-container
    nav
    ~ .cmp-navigation__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    top: 100%;
  }

  /* header v3 */
  .header
    .header-home.header-utility-navigation-container
    .main-menu-navigation {
    .main-menu-mobile-overlay {
      height: 100vh;
    }

    ul.main-menu-list {
      height: 100vh;
      top: 100%;
      margin-top: 0.125rem;
    }
  }

  .header .primary-navigation-wrapper {
    position: relative;
  }
}

//Cart Pages
.homepage:has(.cart-footer) .carouselNotificationsBanner {
  display: none;
}

//AEM add to Cart popup
.cart-modal--active {
  .carouselNotificationsBanner {
    display: none;
  }
}
