.cbg-cmp-searchbar {
  max-width: 800px;
  margin: 1em auto;

  header {
    &.cmp-searchbar--header {
      font-size: 18px;
    }
  }

  .product-lookup-container {
    input {
      height: 50px;
      width: calc(100% - 80px);
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 34.8px;

      border-radius: 4rem;

      padding-left: 1em;

      @media screen and (max-width: $screen-sm) {
        height: 45px;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26.1px;
      }

      &:focus::-webkit-input-placeholder {
        transition: opacity 0.5s 0.5s ease;
        opacity: 0;
      }

      &::-webkit-search-cancel-button {
        position: relative;
        right: 20px;
      }
    }

    ul {
      width: calc(100% - 80px);
      max-height: 300px;
      will-change: scroll-position;
      overflow: scroll;
      padding-left: 15px;

      @media screen and (max-width: $screen-sm) {
        width: 100%;
      }

      li {
        padding: 12px 0 12px 15px;
        list-style: none;
        font-size: 16px;
        font-weight: bold;

        a {
          text-decoration-line: none;
        }
      }
    }
  }
}

.cbg-cmp-searchbar .hidden {
  display: none;
}

.product-lookup-container {
  input {
    font-size: 18px;
  }
}
