.cbg-cmp-utility-nav {
  a.authored-icons {
    text-decoration: none;
  }
  @media screen and (min-width: $screen-lg-md) {
    width: auto;
  }
  &.mobile-only {
    .cbg-cmp-utility-nav-wrapper {
      height: 75vh;
      @media screen and (min-width: $desktop-breakpoint) {
        display: none;
      }
      ul.utility-nav-items {
        display: flex;
        flex-flow: column;
        color: $platform-white;
        padding-top: 25px;

        li.utility-nav-item {
          list-style: none;
          justify-content: center;

          span {
            display: flex;
            align-items: center;
          }

          .authored-icons {
            width: 20px;
            margin-right: 11px;
          }
        }
      }
    }
  }

  &.desktop-only {
    .cbg-cmp-utility-nav-wrapper {
      font-family: "IBMPlexSans-Medium", sans-serif;
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      align-items: center;
      max-width: 892pt;
      margin: 0 auto;

      ul.utility-nav-items {
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 50px;

        li.utility-nav-item {
          padding: 0 1.25rem;
          list-style: none;
        }
      }
    }
  }
}
