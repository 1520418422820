.cbg-cmp--tag-emblem {
  $emblem-padding: 12px;
  $emblem-size: 65px;
  $emblem-size-mobile: 50px;

  width: 100%;
  height: 100%;
  min-height: $emblem-size;
  min-width: $emblem-size;

  padding: $emblem-padding;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .cbg-cmp--tag-emblem-inner {
    position: absolute;

    background-color: $platform-white;
    color: $platform-dark-blue;
    border: 2px solid $platform-dark-blue;

    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: $screen-md) {
      font-size: 16px;
      letter-spacing: 0;
    }

    .cbg-cmp--tag-emblem-text {
      @media screen and (min-width: $screen-md) {
        letter-spacing: 1.67px;
      }
    }
  }

  &.long-text {
    .cbg-cmp--tag-emblem-inner {
      font-size: 8px;
      letter-spacing: 0;
      line-height: 10px;
      height: 16px;

      @media screen and (min-width: $screen-md) {
        font-size: 10px;
        letter-spacing: 0.78px;
        line-height: 13px;
      }
    }
  }

  &.cbg-cmp--tag-emblem__medallion {
    .cbg-cmp--tag-emblem-inner {
      width: 100%;
      height: 100%;
      max-width: $emblem-size-mobile;
      max-height: $emblem-size-mobile;
      border-radius: $emblem-size-mobile * 0.5;

      @media screen and (min-width: $screen-md) {
        max-width: $emblem-size;
        max-height: $emblem-size;
        border-radius: $emblem-size * 0.5;
      }
    }

    .cbg-cmp--tag-emblem-text {
      display: block;
      letter-spacing: 1.67px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  /* Long format style */
  &.cbg-cmp--tag-emblem__long-format {
    .cbg-cmp--tag-emblem-inner {
      padding: 2px 3px 2px 5px;
      border-radius: 0;
      border: none;
      @media screen and (min-width: $screen-lg) {
        padding: 2px 8px;
      }
    }
  }

  /* Horizontal Positioning */
  &.cbg-cmp--tag-emblem__horizontal-left {
    .cbg-cmp--tag-emblem-inner {
      left: 0;
      margin-left: $emblem-padding;
    }
  }

  &.cbg-cmp--tag-emblem__horizontal-center {
    .cbg-cmp--tag-emblem-inner {
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
    }
  }

  &.cbg-cmp--tag-emblem__horizontal-right {
    .cbg-cmp--tag-emblem-inner {
      left: auto;
      right: 0;
      margin-right: $emblem-padding;
    }
  }

  /* Vertical Positioning */
  &.cbg-cmp--tag-emblem__vertical-top {
    .cbg-cmp--tag-emblem-inner {
      top: 0;
      margin-top: $emblem-padding;
    }
  }

  &.cbg-cmp--tag-emblem__vertical-bottom {
    .cbg-cmp--tag-emblem-inner {
      top: auto;
      bottom: 0;
      margin: 0 0 8px 8px;
      @media screen and (min-width: 460px) {
        margin: 0 0 $emblem-padding $emblem-padding;
      }
    }
  }
}
