//== Mixins

//== General
@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

//== Accessibility
@mixin accessible-hide {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

//== Authored List
@mixin authored-list-title($font-color, $text-size) {
  color: $font-color;
  font-size: $text-size;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.6rem;
  margin-bottom: 0.8rem;
  @media screen and (min-width: $screen-md) {
    font-size: $authoredList-lg-title;
    letter-spacing: 0.5px;
  }
}

@mixin authored-list-item {
  color: $platform-dark-gray;
  font-size: $font-size;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-decoration: none;
}

@mixin authored-list-btm-title($font-color) {
  color: $font-color;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.75rem;
  opacity: 0.9;
}

//== Footer Specific
@mixin footer-items-padding {
  padding-right: 0.9rem;
}

@mixin footer-col-setting {
  flex: 0 0 50%;
  max-width: 50%;
  @include footer-items-padding;
}

@mixin footer-row-setting {
  display: flex;
  flex-wrap: wrap;
}

@mixin edit-footer-top-bottom($flex-direction, $bg-color) {
  align-items: center;
  background: $bg-color;
  color: $white;
  display: flex;
  flex-direction: $flex-direction;
  height: 100%;
  justify-content: center;
  margin: 5px;
  text-align: center;
  width: 100%;
  .edit-footer-parsys {
    width: 100%;
  }

  p {
    width: 100%;
  }
}

@mixin edit-footer-left-right($bg-color, $m-rt) {
  align-items: center;
  background: $bg-color;
  border: 2px solid black;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: $m-rt;
  width: 100%;

  .edit-footer-parsys {
    width: 100%;
  }

  p {
    width: 100%;
  }
}

// hover and transition mixin for link underline

@mixin transition($values...) {
  transition: $values;
}

@mixin underline-hover($color, $style, $width) {
  border-bottom-color: $color;
  border-bottom-style: $style;
  border-bottom-width: 0;

  @include transition(border-bottom-width, 0.1s);
  &:hover {
    border-bottom-width: $width;
    cursor: pointer;
  }
}
