.cbg-cmp-search-results__content-container-product {
  a {
    text-decoration: none;
    color: inherit;
  }

  .title {
    color: #002e5d;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  .description {
    color: #5b6770;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 26px;
  }

  .cbg-cmp-searchresult--product {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin-bottom: 40px;

    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
    }
  }

  .img-container {
    max-width: 100%;
    display: flex;
    position: relative;
    align-self: center;
    align-items: center;

    img {
      max-width: 360px;
    }

    @media screen and (max-width: $screen-xs) {
      img {
        max-width: 240px;
      }
    }

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      align-self: initial;
      width: 100%;
      max-width: 276px;
      max-height: 400px;

      img {
        max-width: 100%;
      }
    }
    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    color: #002e5d;
    width: 100%;
    justify-content: center;

    ul {
      display: block;
      margin-left: -1em;

      li {
        text-indent: unset;
        padding-left: 0.5rem;
        @media screen and (min-width: $screen-md-lg) {
          max-width: 25rem;
        }
      }
    }

    .learn-more {
      padding-top: 1.3rem;
    }

    .button-link,
    .button-secondary {
      text-decoration: none;
      margin-left: 0;
    }

    .button-home {
      margin-bottom: 1.3rem;
    }

    .more-features span:nth-child(2) {
      @media screen and (min-width: $screen-md-lg) {
        display: inline-block;
      }
    }

    ul,
    h4,
    p {
      @media screen and (min-width: $screen-md-lg) {
        display: inline-block;
      }
    }

    @media screen and (min-width: $screen-lg) {
      margin-left: 1.5rem;
    }
  }
}
