.videoplayer {
  iframe {
    @media screen and (min-width: $screen-md) {
      // youtube embed api recommended minimums
      min-width: 30rem;
      min-height: 16.9rem;
    }
  }

  &.align-text-left {
    text-align: left;
  }

  &.align-text-center {
    text-align: center;
  }

  &.align-text-right {
    text-align: right;
  }
}

.modal {
  .videoplayer {
    iframe {
      display: flex;
      width: 100%;
      height: 100%;
      z-index: -1;
      min-height: 30rem;
      @media screen and (min-width: $screen-sm) {
        // youtube embed api recommended minimums
        display: block;
        min-width: 30rem;
        padding: 16px 16px 45px;
      }

      @media screen and (min-width: $screen-md-lg) {
        min-height: 33rem;
        padding: 0 $font-size $font-size $font-size;
      }
    }

    @media screen and (min-width: $screen-sm) {
      .header-section {
        min-height: 4.1rem;
      }
    }
  }
}
