.row {
  @include footer-row-setting;
}

.col {
  @include footer-col-setting;
}

.footer {
  color: $black;
  width: 100%;

  #footer-reserved {
    margin-left: 10px;
  }

  //===================================================================================
  //=============================== Style System Section ==============================
  //==================================================================================

  &.list-style-dark {
    background-color: $platform-dark-blue;

    .authored-list-title {
      color: #fff;
    }
  }

  &.list-style-light {
    background-color: $platform-white;
  }

  &.add-gutter {
    .footer-container {
      margin: 4rem 1.3rem 2rem 1.3rem;
      @media screen and (min-width: $screen-md) {
        margin: auto;
        margin-top: 4rem;
        margin-bottom: 2rem;
      }
    }

    @media screen and (min-width: $screen-lg) {
      .footer-left-zone {
        width: 65%;
      }
    }
  }

  //===================================================================================
  //=============================== Style System Section ==============================
  //==================================================================================

  .footer-container {
    font-size: $font-size;
    margin: 4rem 1rem 2rem 1rem;
  }

  .text p {
    font-size: $font-size;
    @media screen and (min-width: $screen-sm) {
      font-size: $font-size;
    }
  }

  .footer-header-zone {
    font-family: $font-ibm-regular;
    @include flex-wrap;
    width: 11rem;
  }

  .footer-header-zone h2,
  h1 {
    font-size: 2.3rem;
    height: 100%;
    letter-spacing: 2px;
    line-height: 2rem;
    width: 100%;
  }

  .footer-header {
    height: 100%;
    width: 100%;
  }

  .footer-mid-zone {
    @include flex-wrap;
    margin-top: 2.5rem;
  }

  .footer-mid-zone li span,
  .footer-bottom-zone li span {
    margin: 1rem 0;
  }

  .footer-left-zone {
    @include flex-wrap;
    width: 100%;
  }

  .footer-left-zone div:nth-child(1),
  .footer-left-zone div:nth-child(2) {
    flex: 50%;
  }

  .footer-left-zone div:nth-child(3) {
    margin-top: 3.5rem;
  }

  .footer-left-zone > div:nth-of-type(3) > div {
    margin-right: 0;
  }

  .footer-right-zone {
    margin-top: 3rem;
    width: 100%;
  }

  .footer-bottom-zone {
    margin: 3rem 0 2rem 0;
    width: fit-content;
    display: block;
    .text {
      margin-left: 10px;
    }

    &.row {
      ul {
        @media screen and (max-width: $screen-sm) {
          flex-flow: column;
        }
      }
    }
  }

  .footer-bottom-zone h3 {
    font-weight: 300;
  }

  .footer-bottom-zone li span {
    margin: 1rem 0;
  }

  .footer-bottom-zone > div > div {
    flex-flow: column;
    height: 100%;
    width: fit-content;
  }

  .footer-bottom-zone > div > div > ul {
    max-height: 18rem;
    width: fit-content;
    @media screen and (min-width: $screen-sm) {
      flex-flow: row wrap;
    }
  }

  .footer-bottom-zone ul > li > a > span {
    font-size: $footer-font-sm;
    @media screen and (min-width: $screen-sm) {
      font-size: $font-size;
    }
  }

  .footer-bottom-zone > div > div > ul > li:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: $ft-container-tablet) {
    .footer-container {
      justify-content: space-between;
      padding: 0;
      width: $ft-container-tablet;
    }

    .footer-mid-zone {
      margin-top: 4.5rem;
    }

    .footer-left-zone {
      margin-bottom: 3rem;
    }

    .footer-bottom-zone {
      margin-top: 3rem;
    }
    .footer-bottom-zone > div > div {
      max-width: 100%;
    }

    .footer-bottom-zone > div > div > ul {
      flex-flow: row wrap;
      justify-content: flex-start;
      max-width: 100%;
    }

    .footer-left-zone div:nth-child(1),
    .footer-left-zone div:nth-child(2) {
      flex: 1%;
    }
    .footer-left-zone div:nth-child(3) {
      flex: 32%;
      margin-top: 0;
    }
  }

  @media screen and (min-width: $screen-lg) {
    .footer-container {
      width: $ft-container-desktop;
    }
    .footer-left-zone {
      margin-bottom: 0;
      //width: 60%;
    }
  }

  .combinedinput-home {
    .text-group {
      .title {
        font-size: 18px;
        line-height: 1.5;
      }

      padding: 0;
      margin: 0;
    }
  }
}

.edit-mode-container {
  width: 80%;
  margin: auto;

  .edit-mode-header {
    background-color: $platform-light-gray-outline;
    font-family: $font-ibm;
    padding: 20px;
    padding-top: 40px;
    border-top: 2px solid gray;
    border-left: 2px solid gray;
    border-right: 2px solid gray;
  }

  .edit-mode-section {
    padding: 30px;
    border: 2px solid gray;
    margin-bottom: 50px;
  }
}
