$icon-x: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
$icon-check: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20px' width='15px' %3E%3Ctext x='0' y='15' fill='#268736'%3E✓%3C/text%3E%3C/svg%3E");

//@font-face {
//  font-family: spritesvg;
//  src: url("../resources/fonts/sprite.svg") format("svg");
//  //url('/apps/cbg-platform/components/platform/authoring/iconselect/resources/fonts/icomoon.svg') format('svg');
//
//  font-weight: normal;
//  font-style: normal;
//}
//
//[class^="icon-"],
//[class*=" icon-"] {
//  /* use !important to prevent issues with browser extensions that change fonts */
//  font-family: "spritesvg", sans-serif !important;
//  speak: never;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1;
//
//  /* Better Font Rendering =========== */
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

.icon-paint-calculator-dark:before {
  content: "\e900";
  color: #f6f8f8;
}
.icon-quicktip-dark:before {
  content: "\e901";
  color: #fff;
}
.icon-quicktip .path1:before {
  content: "\e902";
  color: rgb(28, 53, 99);
}
.icon-quicktip .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(101, 180, 228);
}
.icon-quicktip .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path5:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(101, 180, 228);
}
.icon-quicktip .path6:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path7:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(101, 180, 228);
}
.icon-quicktip .path8:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path9:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path10:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path11:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(101, 180, 228);
}
.icon-quicktip .path12:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path13:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(101, 180, 228);
}
.icon-quicktip .path14:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-quicktip .path15:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(28, 53, 99);
}
.icon-correct:before {
  content: "\e911";
}
.icon-error:before {
  content: "\e912";
}
.icon-favorite:before {
  content: "\e913";
  color: #fff;
}
.icon-next-path:before {
  content: "\e914";
}
.icon-trash:before {
  content: "\e915";
}
.icon-favorite-2-checked .path1:before {
  content: "\e916";
  color: rgb(91, 103, 112);
}
.icon-favorite-2-checked .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-favorite-2-unchecked:before {
  content: "\e918";
  color: #fff;
}
.icon-remove-chip:before {
  content: "\e919";
}
.icon-add-chip:before {
  content: "\e91a";
}
.icon-cart-added-cart_icon_added .path1:before {
  content: "\e91b";
  color: rgb(98, 181, 229);
}
.icon-cart-added-cart_icon_added .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-cart-added-cart_icon_added .path3:before {
  content: "\e91d";
  margin-left: -1em;
  color: none;
}
.icon-cart-added-cart_icon_added .path4:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-cart-added-cart_icon_added .path5:before {
  content: "\e91f";
  margin-left: -1em;
  color: none;
}
.icon-cart-added-cart_icon_added .path6:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-cart-cart_icon:before {
  content: "\e921";
}
.icon-favorites:before {
  content: "\e922";
}
.icon-recoat:before {
  content: "\e923";
  color: #92959d;
}
.icon-dry-to-touch:before {
  content: "\e924";
  color: #92959d;
}
.icon-recommended-coats:before {
  content: "\e925";
  color: #92959d;
}
.icon-application-temp:before {
  content: "\e926";
  color: #92959d;
}
.icon-love-your-color-guarantee:before {
  content: "\e927";
  color: #62b5e5;
}
.icon-mildew-resistant .path1:before {
  content: "\e928";
  color: rgb(98, 181, 229);
}
.icon-mildew-resistant .path2:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path3:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path4:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path5:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path6:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path7:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path8:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path9:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path10:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path11:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(0, 46, 93);
}
.icon-mildew-resistant .path12:before {
  content: "\e933";
  margin-left: -1em;
  color: none;
}
.icon-stands-up-to-anything:before {
  content: "\e934";
  color: #62b5e5;
}
.icon-high-hiding-coverage:before {
  content: "\e935";
  color: #62b5e5;
}
.icon-standup-finish:before {
  content: "\e936";
  color: #62b5e5;
}
.icon-scuff-shielf:before {
  content: "\e937";
  color: #62b5e5;
}
.icon-thumb-video:before {
  content: "\e938";
  color: #bcbec0;
}
.icon-thumb-image:before {
  content: "\e939";
  color: #bcbec0;
}
.icon-reset---light:before {
  content: "\e93a";
  color: #ced1d4;
}
.icon-expand---light-1 .path1:before {
  content: "\e93b";
  color: rgb(206, 209, 212);
}
.icon-expand---light-1 .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(91, 103, 112);
}
.icon-collapse---light-2 .path1:before {
  content: "\e93d";
  color: rgb(206, 209, 212);
}
.icon-collapse---light-2 .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(91, 103, 112);
}
.icon-closed-2:before {
  content: "\e93f";
  color: #62b5e5;
}
.icon-Unfilled:before {
  content: "\e940";
  color: #d1d6df;
}
.icon-Filled:before {
  content: "\e941";
  color: #79cb90;
}
.icon-shipping-available:before {
  content: "\e942";
  color: #5b6770;
}
.icon-store-pickup:before {
  content: "\e943";
  color: #5b6770;
}
.icon-more-info .path1:before {
  content: "\e944";
  color: rgb(206, 209, 212);
}
.icon-more-info .path2:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(91, 103, 112);
}
.icon-flat:before {
  content: "\e946";
  color: #5b6770;
}
.icon-satin .path1:before {
  content: "\e947";
  color: rgb(91, 103, 112);
}
.icon-satin .path2:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(216, 216, 216);
  opacity: 0.15;
}
.icon-eggshell .path1:before {
  content: "\e949";
  color: rgb(91, 103, 112);
}
.icon-eggshell .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(216, 216, 216);
  opacity: 0.33;
}
.icon-semigloss .path1:before {
  content: "\e94b";
  color: rgb(91, 103, 112);
}
.icon-semigloss .path2:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(216, 216, 216);
  opacity: 0.66;
}
.icon-favorite-1-checked:before {
  content: "\e94d";
  color: #5b6770;
}
.icon-favorite-1-unchecked:before {
  content: "\e94e";
}
.icon-lowes-logo .path1:before {
  content: "\e94f";
  color: rgb(0, 73, 144);
}
.icon-lowes-logo .path2:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lowes-logo .path3:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lowes-logo .path4:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lowes-logo .path5:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lowes-logo .path6:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lowes-logo .path7:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lowes-logo .path8:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-expand---light:before {
  content: "\e957";
}
.icon-collapse---light-1:before {
  content: "\e958";
}
.icon-collapse---light:before {
  content: "\e959";
  color: #ced1d4;
}
.icon-radio-checked .path1:before {
  content: "\e95a";
  color: rgb(0, 46, 93);
}
.icon-radio-checked .path2:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(98, 181, 229);
}
.icon-radio-unchecked:before {
  content: "\e95c";
  color: #fff;
}
.icon-checkbox-checked .path1:before {
  content: "\e95d";
  color: rgb(0, 46, 93);
}
.icon-checkbox-checked .path2:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(98, 181, 229);
}
.icon-checkbox-unchecked:before {
  content: "\e95f";
  color: #fff;
}
.icon-field-close:before {
  content: "\e960";
  color: #002e5d;
}
.icon-field-dropdown:before {
  content: "\e961";
}
.icon-language:before {
  content: "\e962";
  color: #5b6770;
}
.icon-social-youtube:before {
  content: "\e963";
  color: #5b6770;
}
.icon-social-twitter:before {
  content: "\e964";
  color: #5b6770;
}
.icon-social-pinterest:before {
  content: "\e965";
  color: #5b6770;
}
.icon-social-instagram:before {
  content: "\e966";
  color: #5b6770;
}
.icon-social-facebook:before {
  content: "\e967";
  color: #5b6770;
}
.icon-paint-calculator:before {
  content: "\e968";
  color: #5b6770;
}
.icon-closed-1:before {
  content: "\e969";
  color: #62b5e5;
}
.icon-arrow--next-1:before {
  content: "\e96a";
}
.icon-arrow--next:before {
  content: "\e96b";
}
.icon-dropdown-arrow-close:before {
  content: "\e96c";
}
.icon-dropdown-arrow-open:before {
  content: "\e96d";
}
.icon-search:before {
  content: "\e96e";
  color: #62b5e5;
}

$icon-dropdown: url(../resources/images/svgs/field-dropdown.svg);
