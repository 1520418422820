.card-container-home {
  padding-top: 1em;
  padding-bottom: 1em;

  &.container-margin-small {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  &.container-margin-medium {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  &.container-margin-large {
    padding-top: 6em;
    padding-bottom: 6em;
  }

  &.container-margin-extra-large {
    padding-top: 8em;
    padding-bottom: 8em;
  }

  &.flex-type-card {
    min-width: 250px;
  }

  &.flex-type-button {
    .mobile-card-holder {
      .each-mobile-row {
        margin: 0 10px;

        .mobile-card-item {
          margin: 10px 0;
        }
      }
    }
  }

  .each-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 100%;
    margin: auto;
    //justify-content: space-between;
  }

  .each-mobile-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 800px) {
    .each-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: auto;
    }
    &.cbg-cmp-card__small {
      flex-direction: row;
    }

    .each-card {
      display: flex;
      align-items: center;
    }
  }

  .card-item {
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    .pinterest-home {
      left: -15px;
      @media screen and (min-width: $screen-sm) {
        left: calc(100% - 55px);
      }
    }
    @media screen and (min-width: $screen-md) {
      padding-right: 22px;
    }
  }
  .mobile-card-item {
    margin: 20px 0;
  }

  .cbg-cmp-card__text-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    @media screen and (min-width: $screen-md) {
      width: 100%;
    }

    p,
    h1 {
      width: 100%;
    }

    @media screen and (min-width: $screen-md) {
      flex-flow: column;
    }
  }

  .small-margin {
    .each-card,
    .authored-list-home {
      @media screen and (min-width: $screen-md) {
        //margin-left: 10px;
        //margin-right: 10px;
        margin: 10px;
      }
    }
    .card-item {
      margin: 20px 0;
    }
  }
  .medium-margin {
    .each-card,
    .authored-list-home {
      @media screen and (min-width: $screen-md) {
        //margin-left: 20px;
        //margin-right: 20px;
        margin: 20px;
      }
    }
    .card-item {
      margin: 30px 0;
    }
  }
  .large-margin {
    .each-card,
    .authored-list-home {
      @media screen and (min-width: $screen-md) {
        //margin-left: 45px;
        //margin-right: 45px;
        margin: 45px;
      }
    }
    .card-item {
      margin: 50px 0;
    }
  }
  .xl-margin {
    .each-card,
    .authored-list-home {
      @media screen and (min-width: $screen-md) {
        //margin-left: 75px;
        //margin-right: 75px;
        margin: 75px;
      }
    }
    .card-item {
      margin: 70px 0;
    }
  }
}
// For mobile horizontal scrolling
// Used important tags to ensure that all of the class selectors forwould be applied.
.horizontal-container {
  @media screen and (max-width: $screen-md) {
    overflow-x: auto !important;
    display: block !important;
  }
}
.horizontal-item {
  @media screen and (max-width: $screen-md) {
    min-width: 62.2rem;
    flex-direction: row !important;
  }

  @media screen and (min-width: $screen-md-lg) {
    min-width: $screen-lg-md;
  }
}
