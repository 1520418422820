$anim_std_duration: 250ms;
$animation_slide_in_left: $anim_std_duration cbg_slideinleft
  cubic-bezier(0.07, 0.94, 0.36, 0.86) 50ms forwards;
$animation_slide_out_left: $anim_std_duration cbg_slideinleft
  cubic-bezier(0.07, 0.94, 0.36, 0.86) 50ms reverse;
$animation_slide_in_left_alt: $anim_std_duration cbg_slideinleftalt 50ms ease-in
  forwards;
$animation_slide_in_right: $anim_std_duration cbg_slideinright
  cubic-bezier(0.07, 0.94, 0.36, 0.86) 50ms forwards;
$animation_slide_in_right_alt: $anim_std_duration cbg_slideinrightalt
  cubic-bezier(0.07, 0.94, 0.36, 0.86) 50ms forwards;
$animation_slide_down: 0.75s cbg_slidedown ease-in 50ms forwards;

@keyframes cbg_slideinleft {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes cbg_slideinleftalt {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(200%);
  }
}

@keyframes cbg_slideinright {
  from {
    opacity: 0;
    transform: translateX(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes cbg_slideinrightalt {
  from {
    opacity: 0;
    transform: translateX(200%);
  }

  to {
    opacity: 1;
    transform: translateX(100%);
  }
}

@keyframes cbg_carouselslideleftshow {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes cbg_carouselsliderightshow {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes cbg_slidedown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
