.favorite-button {
  background: no-repeat;
  display: block;
  align-items: center;

  @media screen and (min-width: $screen-md) {
    display: flex;
  }

  .favorite-button-icon {
    height: 14px;
    width: 30px;
    padding-right: 10px;
  }
}

//Alignment applied through style systems

.center-align .favorite-button {
  margin: 0 auto;
}

.right-align .favorite-button {
  margin: 20px auto;
  width: fit-content;

  @media screen and (min-width: $screen-md) {
    margin: 0 0 0 auto;
  }
}
