.slider-iframe-class {
  .cmp-container .experiencefragment {
    display: none;
  }
}

.coral-Form-fieldset {
  border: 1px solid red;
  padding: 10px;
  margin: 0;
  border-radius: 11px;
}

.square-styling {
  ul {
    list-style: square inside;
  }
}

.circle-styling {
  ul {
    list-style: circle inside;
  }
}

.disc-styling {
  ul {
    list-style: disc inside;
  }
}

.hide-bullets {
  ul {
    list-style: none;
  }

  ol {
    list-style: none;
  }
}

.authoredList {
  //===================================================================================
  //=============================== Style System Section ==============================
  //===================================================================================
  &.list-style-dark {
    background-color: $platform-dark-blue;

    .authored-list-title {
      color: #fff;
    }

    .authored-list-list li > a {
      @include underline-hover($platform-black, solid, 2px);
    }

    .authored-list-list .authored-list-listItem .authoredlist-image {
      @include underline-hover(transparent, solid, 0px);
    }

    .authored-list-listItemAnchor {
      color: $platform-white;
    }

    .authored-list-list {
      color: $platform-white;
    }
  }

  &.list-style-light {
    background-color: $platform-white;

    .authored-list-title {
      color: $platform-dark-gray;
    }

    .authored-list-list li > a {
      @include underline-hover($platform-black, solid, 2px);
    }

    .authored-list-list .authored-list-listItem .authoredlist-image {
      @include underline-hover(transparent, solid, 0px);
    }

    .authored-list-listItemAnchor {
      color: $platform-dark-gray;
    }

    .authored-list-list {
      color: $platform-dark-gray;
    }
  }

  &.list-style-inherit {
    background-color: inherit;

    .authored-list-title {
      color: inherit;
    }

    .authored-list-list li > a {
      @include underline-hover($platform-black, solid, 2px);
    }

    .authored-list-list .authored-list-listItem .authoredlist-image {
      @include underline-hover(transparent, solid, 0px);
    }

    .authored-list-listItemAnchor {
      color: inherit;
    }

    .authored-list-list {
      color: inherit;
    }
  }

  &.align-text-left {
    text-align: left;
  }

  &.align-text-center {
    text-align: center;
  }

  &.align-text-right {
    text-align: right;
  }

  &.list-centered {
    text-align: center;

    ul {
      padding-left: 0;
    }
  }

  &.list-right {
    text-align: right;
  }

  &.list-left {
    text-align: left;
  }

  &.list-style-circle {
    .authored-list-list {
      ul {
        list-style: disc inside none;
      }
    }
  }

  &.list-style-square {
    .authored-list-list {
      ul {
        list-style: square inside none;
      }
    }
  }

  &.list-style-icon {
    ul {
      list-style-image: none;
    }
  }

  //===================================================================================
  //=============================== Style System Section ==============================
  //===================================================================================

  .authored-icons {
    &.icon-size-small {
      width: 28px;
      height: 28px;
    }

    &.icon-size-medium {
      width: 42px;
      height: 42px;
    }

    &.icon-size-large {
      width: 48px;
      height: 48px;
    }
  }

  .authored-list-home {
    position: relative;
    margin: 10px;

    ol {
      &.authored-list-list {
        margin-left: 1rem;
      }
    }
  }

  .authored-list-title {
    @include authored-list-title($platform-gray-text, $authoredList-sm-title);
  }

  .authored-list-list {
    display: flex;
    flex-flow: column;
    margin-top: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-left: 0;
  }

  .authored-list-list li:not(:last-child) {
    margin-bottom: 0.8rem;

    &.contains-image {
      margin: 0;
      padding: 0;
    }
  }

  .authoredlist-image {
    height: 2rem;
    width: 2rem;
    margin-bottom: 0.8rem;

    img {
      min-height: 100%;
      min-width: 100%;
    }
  }

  .authored-list-list li {
    & > a {
      @include underline-hover($platform-black, solid, 2px);
    }

    & > p {
      display: inline;
      margin: 0;
    }
  }

  .authored-list-list .authored-list-listItem .authoredlist-image {
    @include underline-hover(transparent, solid, 0px);
  }

  .authored-list-listItemAnchor {
    @include authored-list-item;
  }

  .authored-list-listItemAnchor a {
    color: $platform-dark-gray;
    width: 100%;
  }

  .authored-list-listItemAnchor .authored-icons {
    overflow: inherit;
  }

  .list-list {
    padding: 0;

    .list-right & {
      direction: rtl;
    }
  }

  .list-stacked {
    padding: 0;
  }

  .list-listItem {
    font-size: 2rem;
    padding: 0.25em 0;
  }

  .list-svg {
    margin: 0 0.25em 0 0;

    .list-right & {
      float: right;
      margin: 0 0 0 0.25em;
      transform: translateY(30%);
    }
  }

  .list-horizontal {
    flex-flow: wrap;
    max-width: 100%;
    width: fit-content;

    &:last-child {
      margin-right: 0;
    }

    ul {
      margin-bottom: 0;
      display: inline-block;
      list-style: outside disc;
      margin-left: 1em;
    }

    li {
      margin: 0 1em 0 0;
      max-width: max-content;
      width: 100%;

      &:last-child {
        margin-right: 0;

        & > div > img {
          margin-right: 0;
        }
      }
    }

    .authored-list-listItem {
      display: flex;
    }

    .authored-list-listItemAnchor {
      display: block;
    }

    .authoredlist-image {
      margin-right: 1.2rem;
    }
  }

  .list-unordered {
    list-style: none;
  }

  .hide-bullets {
    list-style: none;
  }

  .copy-right-text {
    @include authored-list-btm-title($platform-dark-gray);
  }
}
