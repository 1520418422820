.filteroption {
  max-width: 100%;
  color: $platform-dark-gray;
  font-family: $font-ibm-regular;
  padding: 0 0.2rem;

  /* Do not Remove the below. Removing will cause margin to be added to p tags,
   thereby messing up color buttons spacing */
  p {
    margin: 0;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  li {
    list-style: none;
    padding-bottom: 3px;
  }

  ul {
    padding: 0;
  }

  .color-filter {
    display: flex;
    flex-flow: wrap;
    padding: 1.25rem 0;
    margin: 0;
    max-width: 20rem;

    li {
      margin-bottom: 5px;
      width: 42%;
      margin-right: 1.5rem;
      @media screen and (min-width: $screen-md) {
        flex: 35%;
        margin-right: 0;
        width: 45%;
      }
    }

    @media screen and (min-width: $screen-md) {
      max-width: 19.1rem;
      padding-left: 5px;
    }
  }

  .button-list {
    input {
      @include accessible-hide;

      &:focus-visible + span::after {
        border-radius: rem(48px);
        content: "";
        outline: $porcelain solid 3px;
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: -2px;
        width: calc(100% + 4px);
      }
    }

    .color-icon {
      height: 1.25rem;
      width: 1.25rem;
      display: block;
      border-radius: 50%;
      margin-top: 2px;
    }
  }

  .color-button {
    border: 2px solid $platform-image-upload-border;
    border-radius: 1.6rem;
    padding: 10px 1.25rem;
    display: flex;
    padding-left: 10px;
    position: relative;
    outline: none;
    width: 9rem;
    cursor: pointer;

    @media screen and (min-width: $screen-md) {
      width: 7.5rem;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    .color-button:hover {
      border: 2px solid $plaform-gray-fill;
    }
  }

  .activeClass {
    border: 2px solid $plaform-gray-fill;
  }

  .color-icon {
    margin: 0px 10px 0 0;
  }

  .filter-click--container {
    text-decoration: none;
  }

  .filter-checkbox,
  .filter-checkbox-variations {
    &.has-hidden-filters {
      margin-bottom: 0;

      input {
        &:focus-visible {
          outline: $porcelain solid 3px;
          outline-offset: 0px;
        }

        &:hover {
          border-color: $platform-wall-dropdown-menu-border;
        }
      }
    }
  }

  .has-hidden-filters {
    .filter-checkbox-variations__section-ul {
      margin-top: 0;
    }
  }

  .filter-checkbox-variations__show-more-link,
  .filter-checkbox-variations__hide-more-link {
    padding-top: 0;
    padding-left: 0;
  }

  svg.filterOptionExpandIcon {
    display: none;
  }

  svg.filterOptionCollapseIcon {
    display: inline;
  }

  .cmp-filterOption__icon {
    align-self: center;
    position: relative;
    height: 19px;
    width: 19px;
    border: solid 2px $platform-full-width-btn;
    border-radius: 50%;
    margin-right: 10px;
  }

  .cmp-filterOption__icon:after {
    content: "";
    display: block;
    width: 0.49rem;
    background: $platform-dark-blue;
    height: 1.5px;
    top: 0px;
    bottom: 0;
    left: 1px;
    right: 0;
    margin: auto;
    transform: rotate(0deg);
    position: absolute;
    @media screen and (min-width: $screen-lg-md) {
      left: 0px;
    }
  }

  .cmp-filterOption__icon:before {
    content: "";
    display: block;
    background: $platform-dark-blue;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: rotate(-90deg);
    position: absolute;
    top: 0;
    left: 55%;
    width: 1.8px;
    height: 50%;
    margin-left: -1px;
  }

  .cmp-filterOption__icon:before,
  .cmp-filterOption__icon:after {
    background-color: $platform-dark-navy-blue;
    transition: transform 0.2s ease-out;
  }

  .hide-options {
    .filter-option--button {
      // Toggle the state of the button
      color: $platform-dark-blue;

      content: "+";
    }

    .filter-option--button:after {
      // Toggle the state of the button

      content: "";
      display: block;
      width: 0.6rem;
      background: $platform-dark-blue;
      border-radius: 0.18rem;
      height: 2px;
      top: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(90deg);
      position: absolute;
    }

    .filter-option--inner {
      max-height: 0;
      transition: max-height 0.2s ease;
      .filter-radio {
        .styled-radio {
          .filter-radio__title {
            margin-left: 30px;
            padding: 0;
          }
        }
        .filter-radio__label {
          margin: 0;
        }
      }
    }

    .cmp-filterOption__icon {
      &:before,
      &:after {
        transition: transform 0.2s ease-out;
        background-color: $platform-dark-navy-blue;
      }

      &:before {
        transform: rotate(0deg);
      }

      &:after {
        transform: rotate(180deg);
      }

      svg.filterOptionExpandIcon {
        display: inline;
      }

      svg.filterOptionCollapseIcon {
        display: none;
      }
    }

    .cmp-filterOption__button {
      display: flex;
      justify-content: space-between;
      background: inherit;
      width: fit-content;
      outline: none;
      &:focus-visible {
        outline: black solid 1px;
      }
    }

    svg.filterOptionExpandIcon {
      display: inline;
    }

    svg.filterOptionCollapseIcon {
      display: none;
    }
  }

  .hide-additional-options {
    &.filter-checkbox-variations__show-more-link {
      display: none;
    }

    &.filter-checkbox-variations__hide-more-link {
      display: none;
    }

    &.filter-checkbox-variations {
      max-height: 0;
      transition: max-height 0.2s ease;
    }

    &.has-hidden-filters {
      display: none;
    }
  }

  // INNER PROPERTY
  .filter-option--inner {
    transition: max-height 0.3s ease;
    overflow: hidden;
    padding-left: 14px;
    .filter-radio {
      .styled-radio {
        .filter-radio__title {
          margin-left: 30px;
          margin-bottom: 10px;
          padding: 0;
        }
      }
      .filter-radio__label {
        margin: 0;
      }
    }
  }

  .text-btn {
    border: none;
    background-color: inherit;
    color: $platform-full-width-btn;
    font-size: $font-size;
    display: inline-block;
    margin-left: 5px;
  }

  .text-btn:hover {
    cursor: pointer;
    color: $platform-dark-blue;
  }

  .filter-option--title {
    background: $platform-background-light-gray-scheme;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    outline: none;
    padding: 7px;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    button {
      padding: 0;
      background: none;
      width: fit-content;
    }

    .filter-option--heading {
      padding: 0 10px;
    }
  }

  .filter-option--description {
    background: $platform-cream;
    margin: 0;

    p {
      margin: 0 12px;
      font-weight: normal;
      font-size: 10pt;
    }
  }

  .filter-option--button {
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    align-self: center;
    border: 2px solid $platform-full-width-btn;
    position: relative;
    outline: none;

    &:after,
    &:before {
      content: "";
      display: block;
      background: $platform-dark-blue;
      border-radius: 0.18rem;
      width: 0.6rem;
      height: 2px;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.2s linear;
      position: absolute;
    }
  }

  .filter-radio {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  .styled-radio {
    display: block;
    position: relative;
    margin-left: 20px;
    margin-top: 15px;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: $screen-sm) {
      margin-left: 10px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border-radius: 50%;

      &:after {
        top: 4px;
        left: 4px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        content: "";
        position: absolute;
        display: none;
      }
    }

    input {
      /* Hide the browser's default radio button */
      position: absolute;
      opacity: 0;
      cursor: pointer;

      /* Show the indicator (dot/circle) when checked */
      &:checked {
        ~ .checkmark {
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .filter-toggle li {
    display: flex;
  }

  .filter-radio__label,
  .filter-toggle__label,
  .filter-checkbox__label,
  .filter-checkbox-variations__label {
    cursor: pointer;
    display: inline-flex;
    position: relative;
    &.filter-disable {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .filter-color__label.filter-disable {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  .filter-radio__label > span,
  .filter-checkbox__label > span,
  .filter-toggle__label > span,
  .filter-checkbox-variations__label > span {
    color: $platform-dark-gray;
    padding: 0.15rem;
  }

  .filter-radio__label > span:nth-child(2),
  .filter-checkbox__label > span:nth-child(2),
  .filter-toggle__label > span:nth-child(2),
  .filter-checkbox-variations__label > span:nth-child(2) {
    content: "";
    display: inline-block;
    z-index: 1;
    height: 100%;
    position: absolute;
    left: 0;
    padding-left: 2rem;
    width: 18rem;
  }

  .filter-checkbox__label > input,
  .filter-toggle__label > input,
  .filter-checkbox-variations__label > input {
    appearance: none;
    background-color: transparent;
    border: 2px solid $platform-background-light-gray-scheme;
    cursor: pointer;
    height: 1.2rem;
    outline: none;
    transition-duration: 0.15s;
    width: 1.2rem;
    margin-top: 6px;
  }

  .filter-radio__label > input {
    background-size: 1.25rem 1.25rem;
    border: none;
    height: 1.25rem;
    width: 1.25rem;
  }

  .filter-toggle__label > input:checked,
  .filter-radio__label > input:checked,
  .filter-checkbox__label > input:checked,
  .filter-checkbox-variations__label > input:checked {
    background-color: $platform-dark-blue;
    border: 1px solid $platform-dark-blue;
  }

  .filter-radio__label > input:checked + span::before {
    background-color: $platform-dark-blue;
    background-image: url(../ui.frontend/src/main/resources/images/svgs/radio-selected.svg);
    border-color: $platform-dark-blue;
  }

  .filter-toggle__label > input:checked + span::before,
  .filter-checkbox__label > input:checked + span::before,
  .filter-checkbox-variations__label > input:checked + span::before {
    color: $platform-full-width-btn;
    content: "\2713";
    display: block;
    left: 0.4rem;
    position: absolute;
    text-align: center;
  }

  .filter-radio__input > input:active,
  .filter-checkbox__label > input:active,
  .filter-checkbox-variations__label > input:active {
    border: 2px solid $platform-dark-blue;
  }

  .filter-checkbox__brand-name {
    font-size: 18px;
  }

  @media screen and (min-width: $screen-lg-md) {
    display: block;
    width: 18rem !important; // Important used to override aem-GridColumn width styling.
    padding-bottom: 0.2rem;
    padding-right: 0;
    padding-top: 0rem;

    .filter-option--heading {
      font-size: $font-size;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.6rem;
      text-transform: none;
    }
    .filter-radio,
    .filter-toggle {
      padding: 10px 0 10px;
    }

    .filter-radio__title {
      margin-left: 0.2rem;
    }
  }

  .cmp-filterOption__button {
    &:focus-visible {
      outline: black solid 1px;
    }
  }
}
