//hot fix
.cbg-colorCard {
  /* This is the rectangle containing the actual color. */
  .cbg-colorCard__cart-btn {
    background: transparent;
    padding: 9.5px;
    border-radius: 0;
    display: flex;
    align-items: center;
    width: 9.5rem;
    @media screen and (min-width: $screen-sm) {
      width: 10.5rem;
    }
    @media screen and (min-width: $screen-lg) {
      width: 12.5rem;
    }
  }

  /* Plus and minus icons */
  .icon-add {
    background-image: url("../resources/images/svgs/add-chip.svg");
  }

  .icon-remove {
    background-image: url("../resources/images/svgs/remove-chip.svg");
  }

  .color-anchor {
    color: $platform-text-color-blue;
    text-decoration: none;

    .cbg-cmp--tag-emblem {
      min-height: 40px;
    }
  }

  .cbg-color-swatch--collection {
    width: 158px;

    .color-collection-swatches {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 100%;
      overflow: hidden;
      width: calc(100% + 1px);
      height: 100%;

      .color-collection-swatch {
        height: 100%;
        max-width: 34px;
        pointer-events: none;

        &:nth-of-type(2n) {
          display: none;
        }
      }
    }

    & ~ .cbg-colorCard__info {
      max-height: 94px;
    }
  }
}

.cbg-colorCard__icon {
  background-repeat: no-repeat;
  background-position: center;
  height: rem(13px);
  width: rem(13px);
  margin-right: rem(2px);
  pointer-events: none;
  flex-shrink: 0;
}

.cbg-colorCard__text {
  font-size: 13px;
  letter-spacing: 0.41px;
  line-height: 19.2px;
  pointer-events: none;
}

.swatch-container {
  top: 50%;
  left: 50%;
  width: rem(157px);
  max-width: 100%;
  min-height: rem(244px);
  background-color: $platform-white;
  transform: translate(-50%, -50%);

  @media screen and (min-width: $screen-md) {
    width: 199px;
    left: 25px;
  }

  @media screen and (max-width: $screen-xs) {
    bottom: 16px;
  }

  .cbg-color-swatch {
    height: 144px;

    @media screen and (min-width: $screen-md) {
      width: 199px;
      height: 154px;
      margin: 0;
    }
  }

  .cbg-fave-button {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 34px;
    height: 34px;
    border-radius: 16px;
    background-color: $platform-white;
    cursor: pointer;

    @media screen and (min-width: $screen-md) {
      display: block;
    }

    .icon--favorite {
      background-image: url(../resources/images/png/favorite-icon.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 18px;

      display: block;

      width: 100%;
      height: 100%;
    }
  }

  .cbg-colorCard__info {
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    @media screen and (min-width: $screen-sm) {
      padding: 12px;
    }
  }

  .cbg-colorCard__color-name {
    font-size: 12px;
    line-height: 12px;
    font-family: $font-ibm;
    font-weight: bold;
    margin: 0;
    @media screen and (min-width: $screen-sm) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .cbg-colorCard__color-id {
    font-size: 12px;
    line-height: 15px;
    font-family: $font-ibm;
    font-weight: normal;
    margin: 0;
    margin-bottom: 31px;

    @media screen and (max-width: $screen-lg-md) {
      line-height: 18px;
    }
    @media screen and (max-width: $screen-sm) {
      line-height: 30px;
    }
  }

  .cbg-cmp--tag-emblem {
    padding: 8px;

    .cbg-cmp--tag-emblem-inner {
      bottom: 8px;
      left: 8px;
    }
  }

  button {
    &.cbg-colorCard__cart-btn {
      width: 100%;
      border: 2px solid #f6f8f8;
      border-top: none;
    }
  }
}

.cbg-colorCard__swatch-container {
  transform: none;
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}

.cbg-colorCard__footer {
  width: 100%;
  height: 2.25rem;
  display: block;
  box-sizing: border-box;
}

.cbg-colorCard__info-msg {
  display: block;
  width: 100%;
  height: 100%;
  background-color: $platform-background-light-gray-scheme;
  color: $platform-dark-gray;
  font-size: 10px;
  line-height: 2.25rem;
  letter-spacing: 1.67px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
//updates
.cbg-favorite-cta {
  background: none;
  pointer-events: none;
  position: absolute;
  width: rem(50px);
  height: rem(50px);
  z-index: $z-level1;
  right: rem(0);

  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    right: rem(7.5px);
    top: rem(7.5px);
    cursor: pointer;
    width: rem(35px);
    height: rem(35px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: all;
    transition: opacity 0.4s ease;
    opacity: 0;
  }

  // Inactive State
  &:before {
    background-image: url("../resources/images/svgs/favorite-2-checked.svg");
    opacity: 1;
  }

  // Inactive State
  &:after {
    background-image: url("../resources/images/svgs/favorite-1-checked.svg");
    opacity: 0;
  }

  &.loading:after {
    opacity: 1;
    background-image: none;
    left: auto;
    top: 10px;
    width: 35px;
    height: 35px;
    border-color: lightgray transparent;
  }

  @media (pointer: fine) {
    &:hover,
    &.active {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  @media (pointer: coarse) {
    &.active {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}
.minwax-container {
  .cbg-favorite-cta {
    // Inactive State
    &:before {
      background-image: url("../resources/images/svgs/favorite_unselected.svg");
    }
    // Active State
    &:after {
      background-image: url("../resources/images/svgs/favorite_select.svg");
    }
  }
}
