.cmp-card__item {
  display: none;
}

.cmp-card__item--active {
  display: block;
}

.single-card-home {
  background-color: $platform-background-medium-scheme;
}

.card {
  a {
    text-decoration: none;
    color: $platform-background-dark-scheme;
  }

  .small-card-margin {
    .each-text-area {
      margin: 1em;
    }
  }

  .medium-card-margin {
    .each-text-area {
      margin: 2em;
    }
  }

  .large-card-margin {
    .each-text-area {
      margin: 4em;
    }
  }
}

.cbg-cmp-card {
  &.image-only,
  &.style-as-stacked {
    .cbg-cmp-card__image-container {
      margin: 0 auto;
    }

    &.text-on-top {
      flex-direction: column-reverse;
    }

    .each-title {
      margin: 20px 0;
      width: 100%;
    }
  }

  &.each-card {
    &.use-as-background-image {
      display: flex;
      flex-direction: column;

      .background-image-option {
        width: 350px;
        height: 450px;
        background-size: cover;
      }
    }

    &.small-card-margin {
      //might need to put these in media queries too
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &.medium-card-margin {
      //might need to put these in media queries too
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &.large-card-margin {
      @media screen and (min-width: $screen-md-lg) {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

    &.text-only {
      margin: 0 20px;
      padding: 25px;
      margin-bottom: 20px;
    }

    &.product-styling {
      flex-direction: row;
      margin: 10px;
      padding: 0 10px;
      background-color: $platform-white;

      @media screen and (min-width: $screen-md) {
        flex-direction: column;
        margin: 0;
        padding: 0;
      }

      .cbg-cmp-card__image-container {
        .card-image {
          width: 6em;
          margin: 5px;
          display: block;

          @media screen and (min-width: $screen-md) {
            width: 200px;
            margin: 15px;
          }
        }
      }

      @media screen and (min-width: $screen-md) {
        width: 100%;
      }

      .each-description {
        display: none;
        @media screen and (min-width: $screen-md) {
          display: block;
          margin: 0;
        }
      }

      .cbg-cmp-card__text-container {
        .each-text-area {
          flex-basis: 70%;
          display: flex;
          align-items: center;
          width: 100%;
          margin-left: 10px;
        }
      }
    }
  }

  &.style-as-side-by-side {
    .each-href {
      display: contents;
    }

    .cbg-cmp-card__text-container,
    .cbg-cmp-card__image-container {
      width: 75%;
      @media screen and (min-width: $screen-md) {
        width: 50%;
      }
    }

    .cbg-cmp-card__image-container {
      img {
        width: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }

    &.text-on-left {
      flex-direction: row-reverse;

      .cbg-cmp-card__text-container {
        padding-right: 1em;
      }
    }

    &.text-on-right .cbg-cmp-card__text-container {
      padding-left: 1em;
    }
  }

  &.image-only {
    display: block;

    margin: 0 auto;

    @media screen and (min-width: $screen-md) {
      padding: 0;
    }

    .cbg-cmp-card__image-container {
      width: auto;

      img {
        width: auto;
      }
    }

    .card-image-div {
      width: fit-content;
      height: fit-content;
    }
  }

  .cbg-cmp-card__text-container,
  .cbg-cmp-card__image-container {
    position: relative;
  }

  .card-link--button-style {
    text-decoration: none;
  }

  &.cbg-cmp-card__small {
    flex-direction: row;
    margin: 10px;
    padding: 8px;
    background-color: $platform-white;

    &.text-on-left,
    &.text-on-right {
      border: 2px solid $platform-light-gray-outline;
      transition: border-color 250ms ease;
    }

    &.text-only {
      margin: 0;
      padding: 0;
      top: 50%;
      transform: translateY(-50%);

      h2 {
        font-size: 28px;
        line-height: 38px;
      }
    }

    .cbg-cmp-card__image-container {
      max-width: 88px;
      max-height: 84px;
      margin-right: 8px;
      overflow: hidden;
    }

    &.each-card.style-as-side-by-side {
      .each-text-area {
        width: auto;
        margin: 0;
        padding: 0;
      }

      .pretitle {
        text-decoration: none;
        font-size: 1rem;
        font-weight: bold;
        padding: 0;
        margin: 0;
        width: 100%;

        .icon {
          max-width: 8px;
          max-height: 8px;
        }
      }

      .textTool .text-segments {
        margin: 0;
        padding: 0;
      }

      .text p,
      .pretitle {
        @media screen and (min-width: $screen-md) and (max-width: $screen-lg-md) {
          font-size: 11px;
        }

        @media screen and (min-width: $screen-md) and (max-width: $screen-lg-md) {
          font-size: 11px;
        }
      }
    }

    .cbg-cmp-card__text-container {
      .each-text-area {
        flex-basis: 70%;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 10px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.detail-page-styling {
  &.flexContainer {
    .title-section {
      padding: 0.5em;
      margin-bottom: 0;
      @media screen and (min-width: $screen-md) {
        padding: 1em 6em;
      }
    }

    .card-container-title {
      font-size: 3rem;
      line-height: 2.4rem;
      letter-spacing: 2px;
      margin-bottom: 0;
      font-weight: 500;
      width: 100%;
      padding: 1.3rem 1.3rem;
      @media screen and (min-width: $screen-md) {
        font-size: 4.5rem;
        line-height: 4rem;
        width: 70%;
        padding: 0 3rem 3rem 10px;
      }
    }

    .card-container-description {
      font-family: $font-ibm;
      color: $platform-dark-gray;
    }
  }
}

.flexContainer {
  &.add-gutter {
    .each-card {
      &.style-as-side-by-side:not(.cbg-cmp-card__small) {
        padding: 1rem;
      }
    }

    .card-holder {
      width: 95%;
      margin: auto;
      @media screen and (min-width: $screen-lg) {
        width: 1220px;
        max-width: 100%;
      }

      .cmp-tabs__tablist {
        .experiencefragment {
          @media screen and (min-width: $screen-md) {
            width: 80%;
          }
          @media screen and (min-width: $screen-xl) {
            width: 100%;
          }
        }
      }
    }

    .title-section {
      width: 95%;
      padding: 1.3rem;
      margin: 0 auto;

      @media screen and (min-width: $screen-sm) {
        width: 95%;
      }
      @media screen and (min-width: $screen-md) {
        width: 70%;
        padding: 0 3rem 3rem 10px;
      }
      @media screen and (min-width: $screen-lg) {
        width: 1220px;
        max-width: 100%;
      }
    }
  }

  &.half-width {
    .card-holder {
      width: 60%;
      margin: auto;
      @media screen and (max-width: $screen-md) {
        width: 100%;
      }
      @media screen and (min-width: $screen-lg) {
        width: 60%;
      }
    }

    .title-section {
      width: 95%;
      margin: auto;
      margin-bottom: 40px;
      @media screen and (min-width: $screen-md) {
        width: 60%;
      }
    }
  }

  &.three-quarter {
    .card-holder {
      width: 95%;
      margin: auto;

      @media screen and (min-width: $screen-md) {
        width: 75%;
      }
    }

    .title-section {
      width: 95%;
      margin: auto;
      margin-bottom: 40px;
      @media screen and (min-width: $screen-md) {
        width: 75%;
      }
    }
  }

  &.align-text-right {
    .title-section {
      text-align: right;

      .card-container-description {
        width: 80%;
      }
    }
  }

  &.align-text-left {
    .title-section {
      text-align: left;

      .card-container-description {
        width: 80%;
      }
    }
  }

  &.align-text-center {
    .title-section {
      text-align: center;
      margin: auto;
    }
  }
}

.card-container {
  background-color: transparent;
  padding: 2em;

  &.light-background {
    background-color: $platform-background-light-gray-scheme;
  }
}

.each-href {
  &.hover-true {
    &:hover {
      .pretitle {
        text-underline-offset: 8px;
        text-decoration: underline;
        text-decoration-color: $platform-dark-gray;
      }
    }
  }
}

.card-holder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  width: 100%;

  .card-item {
    flex-grow: 1;
    margin-right: 30px;
    flex-basis: 0;
    // .hide-bullets class of authoredList component.
    // When inside of a card item, remove padding.
    .hide-bullets {
      .authored-list-list {
        padding: 0;
      }
    }

    &:first-of-type {
      @media screen and (min-width: $screen-md) {
        .each-card {
          margin-left: 0;
        }
      }
    }

    &:nth-last-of-type(1) {
      @media screen and (min-width: $screen-md) {
        .each-card {
          margin-right: 0;
        }
      }
    }
  }

  &.snap-pretitle-container {
    position: relative;

    .each-card {
      height: 100%;

      .cbg-cmp-card__text-area {
        margin-bottom: 50px;
      }

      .button-home {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  &.true {
    display: block;
  }

  @media screen and (min-width: $screen-md) {
    margin-top: 15px;
  }

  @media screen and (min-width: $screen-lg) {
    margin-top: 15px;
  }

  &.add-gutter {
    width: 100%;
    margin: auto;

    @media screen and (max-width: $screen-lg) {
      width: 100%;
    }
  }

  .each-href {
    &.style-as-image {
      transition: all 100ms ease-out;
    }
  }

  &.mobile-columns {
    display: none;
    @media screen and (min-width: $screen-md) {
      display: flex;
      flex-direction: column;
    }
  }

  &.small-card-margin {
    .each-text-area {
      margin: 1em;
    }
  }

  &.medium-card-margin {
    .each-text-area {
      margin: 2em;
    }
  }

  &.large-card-margin {
    .each-text-area {
      margin: 4em;
    }
  }
}

.mobile-card-holder {
  display: flex;
  justify-content: center;

  .each-href {
    text-decoration: none;
  }

  &.mobile-columns {
    display: flex;
    width: 100%;

    .card-image {
      @media screen and (max-width: $screen-sm) {
        max-width: 200px;
        display: block;
      }
    }

    @media screen and (min-width: $screen-md) {
      display: none;
    }
  }
}

.card-shape-0 {
  .each-card {
    background-color: $platform-white;
    color: $platform-black;
    border-color: $platform-black;
  }
}

.card-shape-1 {
  .each-card {
    background-color: $platform-background-dark-scheme;
    color: $platform-black;
    border-color: $platform-black;
  }
}

.card-shape-2 {
  .each-card {
    background-color: $platform-background-light-scheme;
    color: $platform-black;
    border-color: $platform-black;
  }
}

.card-shape-3 {
  .each-card {
    background-color: $platform-background-light-scheme;
    color: $platform-white;
    border-color: $platform-white;
  }
}

.card-shape-4 {
  .each-card {
    background-color: $platform-white;
    color: $platform-font-light-scheme;
    border-color: $platform-background-light-scheme;
  }
}

.card-shadow-0 {
  .each-card {
    box-shadow: 4px 4px 10px 4px #ccc;

    &.style-as-image {
      .each-column {
        padding: 10px 0;
      }

      .each-title {
        margin-left: 15px;
      }

      .each-description {
        margin-left: 15px;
      }

      .pretitle {
        padding-left: 15px;
      }
    }
  }
}

.card-shadow-1 {
  .each-card {
    box-shadow: 8px 8px 12px 4px #ccc;

    &.style-as-image {
      .each-column {
        padding: 10px 0;
      }

      .each-title {
        margin-left: 15px;
      }

      .each-description {
        margin-left: 15px;
      }

      .pretitle {
        padding-left: 15px;
      }
    }
  }
}

.card-shadow-2 {
  .each-card {
    box-shadow: 15px 15px 15px 4px #ccc;

    &.style-as-image {
      .each-column {
        padding: 10px 0;
      }

      .each-title {
        margin-left: 15px;
      }

      .each-description {
        margin-left: 15px;
      }

      .pretitle {
        padding-left: 15px;
      }
    }
  }
}

.card-border-color-black {
  .each-card {
    border-color: $platform-black;
  }
}

.card-border-color-yellow {
  .each-card {
    border-color: $platform-background-dark-scheme;
  }
}

.card-border-color-white {
  .each-card {
    border-color: $platform-white;
  }
}

.each-row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: $screen-md) {
    display: block;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    color: $platform-black;
    display: inline-flex;
  }

  &.card-width-0 {
    width: 100%;
    justify-content: center;
    margin: auto;
  }

  &.card-width-1 {
    width: 400px;
    margin: auto;
    justify-content: center;
  }

  &.card-width-2 {
    width: 605px;
    margin: auto;
    justify-content: center;
  }

  &.card-width-3 {
    width: 800px;
    margin: auto;
    justify-content: center;
  }

  &.card-width-4 {
    width: 1000px;
    margin: auto;
    justify-content: center;
  }
}

.each-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 100%;
  padding: 10px 0;
}

.each-card {
  position: relative;
  display: flex;

  @media screen and (max-width: $screen-md) {
    margin: 20px;
  }

  .cbg__text-align--center {
    .pretitle {
      margin: auto;
    }
  }

  &.card-alignment-left {
    text-align: left;
  }

  &.card-alignment-center {
    text-align: center;
  }

  &.card-alignment-right {
    text-align: right;
  }

  &.style-as-stacked {
    flex-direction: column;
    font-size: 0.9em;
    background-color: $platform-white;
    max-width: 100%;

    .each-text-area {
      margin: 0;
      font-size: 16px;
      flex: 70%;
      @media screen and (min-width: $screen-md) {
        margin: 0 15px;
      }
    }

    .card-image-div {
      margin: auto;
    }
  }

  .pretitle {
    font-weight: bold;
    margin: 10px;
    margin-left: 0;

    &.has-an-icon {
      padding-top: 0;
    }

    .icon-for-pretitle {
      height: 25px;
      width: 25px;
      top: 5px;
      left: 7px;
      position: relative;
      margin-left: 10px;
    }
  }

  &.style-as-side-by-side {
    flex-direction: column;
    align-items: center;

    &.cbg-cmp-card__small {
      flex-direction: row;
      padding: 8px;
      height: auto;
    }

    .pretitle {
      padding-bottom: 2px;
      width: 50%;
    }

    a {
      &:hover {
        .pretitle {
          text-underline-offset: 8px;
          text-decoration: underline;
          text-decoration-color: $platform-dark-gray;
        }
      }
    }

    &.text-only {
      background-color: $platform-white;
      padding: 10px;

      .each-text-area {
        width: 87%;
        max-width: 200px;
        margin: 2em 1em;
        @media screen and (min-width: $screen-md) {
          max-width: 320px;
          width: 90%;
        }
      }
    }

    .card-image {
      width: 70%;
      margin: auto;
    }

    .each-text-area {
      margin: 1em 3em;
      @media screen and (min-width: $screen-md) {
        margin: 1em 4em;
      }
    }

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      .card-image {
        width: 70%;
        margin: auto;
      }
      .each-text-area {
        margin: 0.5em 1em;
        margin-left: 0;
        width: 50%;
      }
    }

    .card-image-div {
      flex-direction: column;
      position: relative;
    }

    .each-column {
      padding: 10px 0;
      flex-direction: column;
    }
  }

  &.style-as-image {
    height: 470px;

    .card-image {
      width: 100%;
      border-radius: 0;

      &.true {
        width: 20%;
      }
    }

    @media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
      width: 75%;
      margin: auto;
      height: 575px;
    }

    @media screen and (max-width: $screen-sm) {
      margin: 10px;
      height: 460px;
    }
  }

  &.small-margin {
    margin: 10px;
    transition: all 100ms ease-out;

    &.style-as-icon {
      @media screen and (max-width: $screen-md) {
        margin-bottom: 10px;
      }
    }
  }

  &.medium-margin {
    margin: 20px;
    transition: all 100ms ease-out;

    &.style-as-icon {
      @media screen and (max-width: $screen-md) {
        margin-bottom: 10px;
      }
    }
  }

  &.large-margin {
    margin: 30px;
    transition: all 100ms ease-out;

    &.style-as-icon {
      @media screen and (max-width: $screen-md) {
        margin-bottom: 10px;
      }
    }
  }

  .each-title {
    margin-left: 0;
    margin-bottom: 16px;
    margin-top: 5px;
  }

  .each-subtitle {
    font-style: italic;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .each-description {
    font-size: 1em;
    margin: 20px 0;
    padding-right: 10px;
  }

  .snap-pretitle {
    position: absolute;
    bottom: 30px;

    @media screen and (max-width: $screen-md) {
      position: relative;
      bottom: 5px;
    }
  }
}

.spcmp-carousel {
  .snap-pretitle {
    position: absolute;
    bottom: 30px;
  }

  .each-card {
    &:hover {
      @media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
        margin: auto;
      }
    }

    &.style-as-image {
      height: 445px;

      @media screen and (min-width: $screen-lg) {
        height: 525px;
      }
    }
  }
}

.cmp-teaser-2 {
  flex-direction: column;
  flex-grow: 2;
}

.cmp-teaser-4 {
  flex-direction: column;
  flex-grow: 4;
}

.spcmp-base__id {
  margin: 1em;
  width: 25em;
}

.each-card-edit-mode {
  height: 200px;
  padding: 20px;
  margin: 20px;
  background-color: #464643;
  color: #f5f5f5;
  border: 20px double #757572;

  .edit-title {
    font-size: 2em;
    margin-bottom: 10px;
  }

  .card-image {
    width: 200px;
    float: left;
  }
}

.card-container-subtitle {
  padding-bottom: 0.5em;

  @media screen and (min-width: $screen-md) {
    font-size: 36px;
  }
}

.card-container-description {
  font-size: $rem-size-body-text;

  @media screen and (min-width: $screen-md) {
    font-size: $rem-size-body-text;
  }
}

.title-section {
  //margin: 1em;
  margin-bottom: 2.5em;
  display: block;
  font-family: $font-family-default;

  @media screen and (min-width: $screen-md) {
    margin-bottom: 2em;
  }
}

.cbg-cmp-card__swatch-container {
  width: 157px;
  min-height: 244px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $platform-white;

  .cbg-colorCard__info {
    padding: 12px 0 12px 12px;
    box-sizing: border-box;

    .cbg-colorCard__color-name {
      font-size: 12px;
      line-height: 12px;
      font-family: $font-ibm;
      font-weight: bold;
      margin: 0;

      .cbg-colorCard__color-id {
        font-size: 12px;
        line-height: 15px;
        font-family: $font-ibm;
        font-weight: normal;
        margin: 0;
        margin-bottom: 31px;
      }

      @media screen and (min-width: $screen-sm) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    @media screen and (min-width: $screen-sm) {
      padding: 12px;
    }
  }

  .favorite-cta-wrapper,
  .cmp-card__cart-btn-wrapper {
    display: none;
  }

  @media screen and (min-width: $screen-md) {
    width: 199px;
    height: 254px;
    left: 25px;
  }

  @media screen and (max-width: $screen-xs) {
    bottom: 16px;
  }

  .cbg-cmp--tag-emblem {
    padding: 8px;

    .cbg-cmp--tag-emblem-inner {
      bottom: 8px;
      left: 8px;
    }
  }

  .cbg-color-swatch {
    height: rem(144px);
    max-width: 100%;
    background: var(--chip-color);

    @media screen and (min-width: $screen-md) {
      width: rem(199px);
      height: rem(154px);
      margin: 0;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .cbg-cmp-card__swatch-container {
    top: auto;
    bottom: 29px;
    transform: none;

    &.cbg-cmp-card__swatch-container--align-left {
      left: 25px;
    }

    &.cbg-cmp-card__swatch-container--align-center {
      left: 50%;
      transform: translateX(-50%);
    }

    &.cbg-cmp-card__swatch-container--align-right {
      left: auto;
      right: 25px;
      transform: none;
    }
  }
}

.column-count-2,
.column-count-3,
.column-count-4 {
  .card-image {
    max-width: 100%;
  }
}

.cmp-card__footer-container {
  display: flex;

  .cmp-card__icon-wrapper {
    background: none;
    line-height: 0;

    &.icon-add {
      .add-icon {
        display: inline;
      }

      .remove-icon {
        display: none;
      }
    }

    &.icon-remove {
      .add-icon {
        display: none;
      }

      .remove-icon {
        display: inline;
      }
    }
  }
}

.cart-cta--icon {
  height: rem(20px);
  width: rem(20px);
}
