.cmp-form-button {
  border-radius: 3em;
  border-width: 0;
  padding: 0.8em 2em;
  width: 100%;
  font-size: $font-size;
  margin: 3.75rem 0;
  @media screen and (min-width: $screen-md) {
    width: auto;
  }

  img {
    display: inline;
    width: 0.6em;
  }
}

.disable {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}
