.notificationsBanner:not(.base) {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  display: flex;
  justify-content: center;
  position: fixed;
  top: rem(50px);
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.6rem);
  z-index: $z-level4;
  padding: 5px;
  @media screen and (min-width: $screen-md) {
    padding: 1rem;
  }

  .notificationsBanner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 5.4rem;
    height: 10%;
    font-size: $font-size;
    flex-direction: column;
    width: 100%;
    max-width: rem(480px);
    text-align: center;
    padding: 0.6rem;
    gap: 0.5rem;
    pointer-events: none;
    box-sizing: content-box;

    .notificationsBanner-message {
      margin: 0;
      .notificationsBanner-color {
        padding-right: 0.5rem;
      }
    }

    .notificationsBanner-link {
      text-decoration: none;

      .notificationsBanner-link-text {
        line-height: $font-size;
        margin: 0;
      }
    }

    @media screen and (min-width: $screen-md-lg) {
      max-width: rem(773px);
      min-height: rem(68px);
      margin: auto;
      flex-direction: row;
      font-size: $banner-lg-description;
      .notificationsBanner-message {
        margin: 20px 9.5px 20px 0;
        .notificationsBanner-color {
          padding-right: 0.5rem;
        }
      }
      .notificationsBanner-link {
        .notificationsBanner-link-text {
          line-height: 19.2px;
        }
      }
    }
  }

  .cbg-cmp-notificationsBanner-btn-close {
    width: 52px;
    position: absolute;
    background: transparent;
    top: 0;
    border-radius: 0;
    right: 0;
    font-size: 1rem;
    height: 52px;
    display: none;

    svg {
      height: 27px;
      width: 27px;
    }
  }

  @media screen and (min-width: $screen-md-lg) {
    top: rem(100px);
  }

  &.fade-in {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.4s linear;

    .notificationsBanner-content {
      pointer-events: all;
    }
    .cbg-cmp-notificationsBanner-btn-close {
      opacity: 1;
      display: none;
      transition: opacity 0s linear;
      @media screen and (min-width: $screen-md) {
        display: block;
      }
    }
  }
}

.notificationsBanner-btn {
  margin-top: 2px;
}

.notification-zone {
  width: 100%;
  text-align: center;

  .cmp-carousel {
    height: 60px;
    .cmp-carousel__action--next {
      margin-right: 0;
      @media screen and (min-width: $screen-md) {
        margin-right: 35px;
      }
    }
    .cmp-carousel__actions {
      top: 60%;
      .tns-nav {
        display: none;
      }
    }

    .my-slider {
      .each-card {
        height: 55px;
        margin: 0;
        padding: 0;

        .each-text-area {
          text-align: center;
          line-height: 0;
          font-size: 0.8rem;
          margin-top: 13px;
          @media screen and (min-width: $screen-md) {
            margin-top: 9px;
          }
          .pretitle {
            margin-top: 17px;
            @media screen and (min-width: $screen-md) {
              margin-top: 20px;
            }
          }
          .each-title {
            margin: 0;
            line-height: 16px;
            font-size: 0.8rem;
            @media screen and (min-width: $screen-md) {
              font-size: 1rem;
              line-height: 20px;
            }
          }
          .each-subtitle {
            margin: 0;
            line-height: 16px;
            font-size: 0.8rem;
            @media screen and (min-width: $screen-md) {
              font-size: 1rem;
              line-height: 20px;
            }
          }
          .each-description {
            margin: 0;
            line-height: 16px;
            font-size: 0.8rem;
            @media screen and (min-width: $screen-md) {
              font-size: 1rem;
              line-height: 20px;
            }
          }
        }
        .text-segments {
          justify-content: center;
          margin-top: 10px;
        }
      }
    }
  } //kick
  .carousel {
    .cmp-carousel__action {
      &.cmp-carousel__action--previous {
        left: 0;
        top: 45px;
        display: block;
      }
      &.cmp-carousel__action--next {
        right: 0;
        top: 45px;
        display: block;
      }
    }
  }
}
