.roomCard {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  overflow: hidden;

  .room-card {
    .room-card__images {
      display: grid;
      grid-template-columns: 1fr 10px 30%;

      .room-card__image--left {
        grid-column: 1;

        .room-card__primary {
          max-width: 100%;
        }
      }

      .room-card__image--right {
        grid-column: 3;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .room-card__secondary {
          &.primary-colorId {
            height: 100%;

            &.has-secondary-colorId {
              height: 50%;
            }
          }

          &.secondary-colorId {
            height: 50%;
          }
        }
      }
    }

    .room-card__link {
      display: flex;
      justify-content: center;
      padding: 10px;

      .room-card__text {
        box-sizing: border-box;
        border-radius: 19.3588px;
        padding: 0.15em 1.75em;
        color: black;
        font-weight: 500;
        font-size: 1em;
      }

      .room-card__btn {
        border: 1px solid #1c1c1c;
        text-decoration: none;
      }
    }
  }
}
