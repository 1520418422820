.color-detail {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 1.3rem;

  .back-button {
    display: flex;
  }

  p {
    margin: 0;
  }

  .desktop-view {
    display: none;
    @media screen and (min-width: $screen-md-lg) {
      display: block;
    }
  }

  .mobile-view {
    display: block;
    @media screen and (min-width: $screen-md-lg) {
      display: none;
    }
  }

  button {
    padding: 0.4rem;

    .button-home {
      .button-text {
        margin: 0;
      }
    }

    @media screen and (min-width: $screen-md-lg) {
      padding: 0.6rem;
    }
  }

  .button-home {
    .button-text {
      margin: 0;
    }
  }

  .button-content {
    justify-content: center;
    align-items: center;
    height: 30px;
  }

  .icon-spacer {
    margin: 0;
    width: 30px;
  }

  .icon {
    display: flex;
  }

  .color-detail-btn,
  .color-category-btn {
    max-height: 2.8rem;
    @media screen and (min-width: $screen-md-lg) {
      width: 12.9rem;
      max-height: 3.2rem;
    }
  }

  .colordetail-btn-container {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: 30rem;
    @media screen and (max-width: $screen-sm) {
      display: block;
    }

    .contentAware {
      margin-bottom: 10px;
      width: 100%;
      @media screen and (min-width: $screen-md-lg) {
        width: fit-content;
      }
    }

    @media screen and (min-width: $screen-md-lg) {
      margin: 0;
      align-items: center;
      .contentAware {
        margin-bottom: 0;
      }
    }

    .button {
      width: 100%;
      @media screen and (min-width: $screen-lg-md) {
        display: block;
        width: fit-content;
        margin: 1.2rem 0 1rem 0;
      }
    }
  }

  .colordetail-btn-container button:nth-of-type(1) {
    @media screen and (min-width: $screen-lg-md) {
      margin-right: 20px;
    }
  }

  .colorDetailIcon {
    display: inline;
    height: 10px;
    width: 15px;
    margin: 1px 3px 0;
  }

  svg {
    display: inline;
    height: 10px;
    margin: 1px 03px 0;
  }

  .colordetail-content {
    p {
      line-height: 1.75rem;
      margin: 0;
    }
  }

  .colordetail-details {
    @media screen and (min-width: $screen-md-lg) {
      padding-bottom: 1rem;
    }
  }

  .details-title {
    padding-bottom: 0;
    font-size: 3em;
    line-height: 2.4rem;
    margin-bottom: 10px;
    @media screen and (min-width: $screen-md-lg) {
      padding-bottom: 0;
      line-height: 3.75rem;
    }
  }

  .colordetail-information {
    .each-card {
      margin: 0;
      margin-bottom: 20px;
      width: 100%;
      @media screen and (min-width: $screen-md) {
        width: 80%;
        //build failed
      }
    }

    .info-cta {
      @media screen and (max-width: $screen-sm) {
        width: 10%;
      }
    }

    @media screen and (min-width: $screen-md-lg) {
      width: 100%;
    }

    @media screen and (min-width: $screen-lg-md) {
      width: 36.4rem;
    }
  }

  .colordetail-information-firstLine {
    display: block;
  }

  .colordetail-information-secondLine {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $screen-md-lg) {
      flex-direction: row;
    }
  }

  .information-title {
    margin-bottom: 1.25rem;
    @media screen and (min-width: $screen-md-lg) {
      margin-bottom: 10px;
    }
  }

  .colordetail-categories {
    margin-top: 0;
    width: 100%;
    display: flex;
  }

  .categories-title {
    margin-bottom: 10px;
  }

  .categories-details {
    width: 100%;
    margin-bottom: 1.25rem;

    .contentAware {
      margin-bottom: 1.25rem;
    }

    @media screen and (min-width: $screen-md-lg) {
      margin-bottom: 2rem;
      .contentAware {
        margin-bottom: 2rem;
      }
    }
  }

  .colordetail-right-side {
    @media screen and (min-width: $screen-md-lg) {
      padding-left: 2.2rem;
      padding-top: 3.1rem;
    }
    @media screen and (min-width: $screen-lg-md) {
      padding-left: 6.2rem;
    }
  }

  .colordetail-left-side {
    padding-top: 1.6rem;

    .back-button {
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;

      a {
        cursor: pointer;
        text-decoration: none;
      }

      @media screen and (min-width: $screen-md-lg) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    @media screen and (min-width: $screen-md-lg) {
      padding-top: 0;
    }
  }

  .colordetail-block {
    display: block;
    height: 21rem;
    position: relative;
    @media screen and (min-width: $screen-md-lg) {
      height: 30rem;
      width: 31rem;
    }
  }

  .colordetail-button-container {
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    right: 0;
    gap: 1.5rem;
    margin: 1rem;

    .cbg-favorite-cta,
    .cmp-colordetail__sharing-button {
      position: unset;
    }
  }

  .cbg-favorite-cta {
    &:before,
    &:after {
      width: 45px;
      height: 45px;
      right: 0;
      top: 0;
      @media screen and (min-width: $screen-md-lg) {
        width: 50px;
        height: 50px;
      }
    }
  }

  .colordetail-image {
    max-width: 31rem;
    padding: 1.25rem 0 2.5rem 0;
    margin: auto;
    @media screen and (min-width: $screen-md-lg) {
      padding-top: 1.25rem;
    }
  }

  .cd-cart-btn {
    min-height: 45px;
    @media screen and (min-width: $screen-md-lg) {
      min-width: 192px;
      min-height: 50px;
      padding-top: 10.8px;
    }
  }

  @media screen and (min-width: $screen-md-lg) {
    flex-flow: row;
    padding-top: 2.5rem;
  }

  @media screen and (min-width: $screen-sm-md) {
    .back-button .button-home {
      padding: 0;
      margin: 0;
      letter-spacing: 0.5px;
    }
  }

  .notificationsBanner {
    .notificationsBanner-content {
      top: 10rem;
    }
  }
}
