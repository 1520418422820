.cbg-cmp-cart {
  height: 100%;
  width: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cart--icon-container {
    border-radius: 100%;
    width: 2.7rem;
    height: 2.6rem;
    line-height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(7px 0px 6px);
    position: relative;

    &:hover {
      min-width: 1em; /* em unit */
      border-radius: 50%;
      text-align: center;
    }

    .icon {
      width: 28px;
      height: 26px;
      @media screen and (max-width: $screen-sm) {
        width: 23px;
        height: 21px;
      }
    }

    .cart--badge {
      pointer-events: none;
      position: absolute;
      min-width: 1em;
      margin: 0;
      padding: 0.5rem;
      border-radius: 100%;
      font-size: rem(10);
      text-align: center;
      line-height: 0.7em;
      top: rem(6px);
      right: rem(1px);
      height: rem(17px);
      width: rem(17px);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $platform-white;
      transition: opacity 250ms ease-in-out;
      opacity: 1;

      @media screen and (min-width: $screen-lg-md) {
        top: rem(5px);
        right: 0;
      }

      &.display-none {
        opacity: 0;
      }
    }
  }

  .cart__overlay {
    position: fixed;
    left: 0;
    top: $global-nav-height;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    background-color: $platform-black;
    z-index: $z-level1;
  }

  .cart--content {
    background: none;
    border: none;
    padding: 0;
    top: $global-nav-height;
    width: 100%;
  }

  .cart--content-container {
    align-items: center;
    background-color: $platform-white;
    display: flex;
    justify-content: center;
    margin-left: auto;
    padding: rem(12px 20px);
    position: relative;
    top: 0;
    z-index: $z-level3;

    @media screen and (min-width: $tablet-breakpoint) {
      right: rem(20px);
      width: 21.3rem;
    }
  }

  .cart--content-container__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 1.25rem 0;

    @media screen and (min-width: $tablet-breakpoint) {
      min-height: 11.6rem;
      max-width: 15.8rem;
    }

    .button-home {
      width: 18.4rem;
      height: 2.8rem;
      margin: rem(15px) 0 0;

      @media screen and (min-width: $tablet-breakpoint) {
        height: 3.1rem;
        min-width: 10.4rem;
        width: 100%;
      }
    }
  }

  .cart--content-container__message,
  .cart--content-container__message-removed,
  .cart--content-container__maxMessage {
    margin: 0;
    font-size: $font-size;
    letter-spacing: 0;
    line-height: 1.5rem;
    @media screen and (min-width: $screen-lg) {
      font-size: 18px;
      line-height: 1.75rem;
    }
  }

  .cart--content-container__maxMessage {
    margin-top: 10px;
  }

  .cart--content-container__btn {
    @media screen and (min-width: $screen-lg) {
      font-size: rem(
        15px
      ); // Added this in for when there are 10 items in the cart.
    }
  }

  .cart--content__button--close {
    align-items: center;
    background: none;
    cursor: pointer;
    display: flex;
    height: rem(44px);
    justify-content: center;
    position: absolute;
    right: 0;
    top: rem(2px);
    width: rem(44px);
  }

  .cart--content-container__count {
    padding-left: 5px;
  }

  .cart__close-icon {
    height: 1.9rem;
    width: 12px;
    display: block;
    background-size: contain;
  }
  .default-close-icon {
    background-image: url("../resources/images/svgs/field-close.svg");
    width: 12px;
    height: 12px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.collection-cart-button.loading,
.cart-cta--button.loading {
  position: relative;
  &:after {
    content: "";
    display: block;
    width: $font-size;
    height: $font-size;
    border: 2px solid;
    border-radius: $font-size;
    position: absolute;
    border-color: gray transparent gray transparent;
    animation: 1.5s rotating linear infinite;
    top: calc(50% - 8px);
    left: 8px;
  }

  .collection-cart-button.icon,
  .cart-cta--icon,
  .icon {
    transition: opacity 500ms;
    opacity: 0;
  }
}

.collection-cart-button.loading {
  &:after {
    left: 0;
  }
}

.cart-modal--active {
  main.container .searchbar {
    .cmp-searchbar__form-container form {
      z-index: $z-level0;
    }
  }
}
