.ratings-reviews--bvReviews {
  padding: 6rem 0;
  width: 100%;
  font-family: $font-knockout;

  .ratings-reviews__container {
    margin: 0 auto;
    padding: 0 1rem;

    @media screen and (min-width: $screen-sm) {
      max-width: $screen-sm;
    }

    @media screen and (min-width: $screen-md) {
      max-width: $screen-md;
    }

    @media screen and (min-width: $screen-md-lg) {
      max-width: $screen-md-lg;
    }

    @media screen and (min-width: $screen-lg) {
      max-width: $screen-lg;
    }

    .bv-content-list-container {
      margin: 10px !important;
      @media screen and (min-width: $screen-md) {
        margin: 40px !important;
      }
      .bv-header {
        padding: 0 30px !important;
        .bv-action-bar {
          padding: 0 !important;
        }
      }
      .bv-content-placeholder {
        padding: 0 30px !important;
        margin: 0 !important;
        #first-to-write {
          &.bv-first-to-review {
            background-color: unset !important;
            padding-left: 0 !important;
            &:focus {
              outline: none !important;
            }
          }
        }
      }
      .bv-off-screen:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    }

    .ratings-reviews-cta-first-text {
      margin: 0 0 20px 0 !important;
      font-size: 18px !important;
      color: $platform-dark-navy-blue !important;
      text-decoration: none !important;
      position: relative !important;
      top: 0 !important;
    }

    .ratings-reviews-cta-container {
      float: left !important;
      text-decoration: none !important;
      height: 36px !important;
      .ratings-reviews-cta-first-btn {
        padding: 16px 32px !important;
      }
    }
  }

  .ratings-reviews__title {
    font-size: $rem-size-mobile-H3;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
    margin-left: 20px;
    @media screen and (min-width: $screen-lg) {
      font-size: $rem-size-H2-5;
    }
  }

  .ratings-reviews__description {
    font-size: $font-size;
  }
}

@mixin text-link {
  text-decoration: none !important;
  border-bottom: 1px solid $brand-accent-main !important;
  padding-bottom: 2px !important;
}

@mixin parenthesis-wrap {
  &:before,
  &:after {
    display: inline !important;
  }

  &:before {
    content: "(";
  }

  &:after {
    content: ")";
  }
}

.valspar-container {
  [data-bv-show="rating_summary"]
    .bv_main_container
    .bv_avgRating_component_container,
  [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text,
  [data-bv-show="rating_summary"]
    .bv_button_component_container
    .bv_button_buttonMinimalist,
  .bv_avgRating_component_container {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  [data-bv-show="rating_summary"]
    .bv_button_component_container
    .bv_button_buttonMinimalist {
    @include text-link;
    &:hover {
      text-decoration: none !important;
      border-bottom: 3px solid $brand-accent-main !important;
    }
  }

  [data-bv-show="rating_summary"]
    .bv_main_container
    button.bv_main_container_row_flex {
    outline: 0 !important;
    &:hover {
      padding-right: 0.5rem !important;
      margin-right: 0.5rem !important;
      box-shadow: 0 0 0 4px $brand-ratings-hover-outline !important;
    }
  }

  // BV Ratings Modal
  div.bv_modal_component_container[data-bv-modal] {
    margin-top: 8px;

    .bv_button_buttonFull {
      margin-top: 15px;
      padding: 0.75em 2em 0.75em 2em !important;
    }

    .bv_modal_outer_content {
      border: $brand-ratings-border;
      font-size: initial;
      padding-bottom: 1.75em !important;
      padding-top: 1.5em !important;

      &:before {
        border-right: $brand-ratings-border;
        border-bottom: $brand-ratings-border;
        height: 17px !important;
        width: 17px !important;
        top: -8px !important;
      }
    }

    .bv_modal_inner_content {
      .bv_histogram_component_container > div > .bv_histogram_row_container {
        height: 28px !important;
        display: flex !important;
        margin-bottom: 8px !important;
        align-items: center;

        &:hover {
          background-color: rgba($brand-ratings-row-bg-color, 0.4) !important;
        }

        .bv_histogram_row_prefix {
          border-bottom: 2px solid $brand-ratings-accent-color !important;
          display: block !important;
          &:after {
            content: "-star" !important;
          }
        }

        .bv_histogram_row_star {
          display: none !important;
        }

        .bv_histogram_row_full_bar {
          max-width: 125px;
        }

        .bv_histogram_row_rating {
          @include parenthesis-wrap;
        }

        .bv_histogram_row_bar_empty,
        .bv_histogram_row_bar_filled {
          border-radius: 0 !important;
        }
      }
    }
  }

  #bv-reviews {
    padding-bottom: 100px;
    padding-top: 100px;

    .bv-content-container .bv-content-core {
      .bv-content-data li .bv-content-data-icon img {
        height: 15px !important;
        width: 15px !important;
      }
    }
  }

  .product-list-item .bv-inline-rating {
    margin-top: -10px;
  }

  .bv-cv2-cleanslate
    .bv-core-container-131
    .bv-submission
    .bv-fieldsets
    .bv-fieldset.bv-fieldset-agreements {
    .bv-fieldset-inner {
      margin-top: 10px !important;

      .bv-checkbox-container {
        @media (max-width: 486px) {
          min-width: 100% !important;
        }

        .bv-checkbox {
          @media (max-width: 486px) {
            margin-left: -23px !important;
          }
        }
      }
    }

    label {
      &::before {
        margin-top: 15px !important;
        margin-left: 20px !important;

        @media (max-width: 486px) {
          margin-top: 21px !important;
          margin-left: 10px !important;
        }
      }
    }
  }

  /**
   * Styling the review summary at the top of a product page
   */
  #bv-summary [data-bv-show="rating_summary"] .bv_main_container {
    div,
    .bv_button_buttonMinimalist,
    .bv_button_buttonMinimalist:hover {
      text-decoration-line: none !important;
    }

    .bv_stars_component_container {
      margin-right: 1em !important;
      padding-right: 0 !important;
    }

    button#ratings-summary {
      margin-right: 1em !important;
      outline: none !important;
      padding: 4px !important;

      &:hover,
      &[aria-expanded="true"] {
        box-shadow: 0 0 0 4px #8c99ae !important;
      }
    }

    .bv_button_buttonMinimalist {
      border-bottom: 2px solid $brand-ratings-text-underline-color !important;
      margin-top: 3px !important;
      padding-bottom: 4px !important;
    }
  }

  // BV Filters
  #BVRRSearchContainer
    .bv-cv2-cleanslate
    .bv-content-search
    .bv-masthead
    .bv-masthead-product
    .bv-rating-label {
    @include parenthesis-wrap;
    text-decoration: none !important;
    padding-right: 0 !important;
  }

  // BV Inline ratings
  .bv-cv2-cleanslate
    .bv-flex-container-column
    .bv-inline-histogram-ratings-star-container {
    .bv-inline-histogram-ratings-bar {
      margin-left: 8px !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    .bv-inline-histogram-ratings-star {
      letter-spacing: 0 !important;
      &:after {
        content: "-star";
        margin-left: -4px;
      }
      .bv-glyph {
        display: none !important;
      }
      .bv-off-screen {
        display: none !important;
      }
    }

    .bv-content-secondary-ratings-container {
      border-radius: 0 !important;
    }

    .bv-inline-histogram-ratings-score {
      > span {
        letter-spacing: -1.5px !important;
        @include parenthesis-wrap;
      }
    }
  }

  #BVRRContainer .bv-cv2-cleanslate .bv-content-list-container {
    .bv-content-filter .bv-active-filters-list-item button,
    .bv-content-list .bv-active-feedback button.bv-focusable {
      background-color: transparent !important;
      border: none !important;
      border-radius: 25px !important;
      color: $brand-accent-main !important;
      font-size: 16px !important;
      height: 40px !important;

      span.bv-close-icon {
        display: inline-block !important;
        left: -80px !important;
        position: relative !important;
      }

      &.bv-active-filter-button-clear span.bv-close-icon {
        left: -92px !important;
      }
    }

    .bv-control-bar-count,
    .bv-control-bar-sort {
      font-size: 16px !important;
    }

    // Sort dropdown
    .bv-control-bar {
      color: $color-input-text !important;
      font-size: 24px !important;

      .bv-dropdown .bv-dropdown-target {
        appearance: none !important;
        background: #fff !important;
        background-image: url($icon-arrows-select) !important;
        background-position: center right 12px !important;
        background-repeat: no-repeat !important;
        background-size: 16px 16px !important;
        border: $brand-ratings-border;
        border-radius: 30px !important;
        cursor: pointer !important;
        margin: 0 !important;
        min-width: 180px !important;
        outline: none !important;
        padding: 10px 30px 10px 20px !important;

        &::-ms-expand {
          display: none !important;
        }

        button {
          font-weight: 400 !important;

          .bv-dropdown-arrow {
            display: none !important;
          }
        }
      }
    }
  }

  .bv-dropdown-in .bv-content-sort-dropdown {
    ul#bv-dropdown-select-reviews-sortby {
      border: $brand-ratings-border;
    }
  }

  .bv-section-summary-inline
    .bv-secondary-rating-summary
    .bv-secondary-rating-summary-bars-container
    .bv-secondary-rating-summary-container
    .bv-secondary-rating-summary-bars {
    border-radius: 0 !important;
    box-shadow: none !important;
    &:first-child {
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }

  .bv-cv2-cleanslate .bv-action-bar .bv-action-bar-header {
    font-family: $font-knockout !important;
    font-size: 24px !important;
    letter-spacing: 1px !important;
    @media screen and (min-width: $screen-md) {
      font-size: 36px !important;
    }
  }
}

.ratings-reviews__container {
  &.add-gutter {
    @media screen and (min-width: $screen-sm) {
      width: 100%;
    }
    @media screen and (min-width: $screen-lg) {
      margin: auto;
      max-width: 1200px;
    }
  }
}
