.cbg-cmp-search-results__content-container-content {
  .cbg-cmp-searchresult--content {
    margin-bottom: 36px;
    line-height: 1.2;

    a {
      text-decoration: none;
      color: inherit;
    }

    .title {
      color: #002e5d;
      display: inline-block;

      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.56px;
      line-height: 19.2px;

      border-bottom: 2px solid #62b5e5;
    }

    .description {
      color: #5b6770;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 26px;
      margin-left: 0;
    }
  }
}
