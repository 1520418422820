// Tools: Get rem
// ================================
/**
* Convert px units into rem values.
* @param {string} $values - Can be single, mix, or group of 3 or 4.
* @param {string} $fontBase [$base-multiplier-rem] - Set unit base, or defaults to defined value in settings.
*
* @example scss - Use with single or multiple units
*  font-size: rem(20px);
*  padding: rem(20px 50px);
*
* @example scss - Use with a base unit size
*  font-size: rem(20px, 10px);
*/
// ================================

@use "sass:math";

// stylelint-disable-next-line annotation-no-unknown
$base-multiplier-rem: 16px !default;

// Function to get rem values

@function rem($values, $fontBase: $base-multiplier-rem) {
  $list: ();

  @each $value in $values {
    // Check to see if unit is 0, auto, or inherited
    // If so, do nothing and output unit instead

    @if $value == 0 or $value == auto or $value == inherit {
      $list: append($list, $value);
    }

    // If the unit is a pixel, convert to rem

    @else if unit($value) == "px" {
      $list: append($list, #{math.div($value, $fontBase)}rem);
    }

    // If the value is unitless, assume it is a px value and convert to rem value

    @else if unitless($value) {
      $list: append($list, #{($value/strip-unit($fontBase))}rem);
    }

    // For everything else, keep as is

    @else {
      $list: append($list, $value);
    }
  }

  @return $list;
}
