@import "colorresults";
@import "colorfamilyresults";
@import "contentresults";
@import "productresults";

.cbg-cmp-search-results__container {
  max-width: 800px;
  margin: 0 auto;

  .cbg-cmp-search-results__inner-container {
    border-bottom: 2px solid #ced1d4;
    padding: 40px 0 50px;
    display: none;

    &.no-border {
      border: none;
    }

    &.active {
      display: block;
    }

    &.cbg-searchresults--no-results-container {
      border: none;
    }
  }

  .cbg-cmp-search-placeholder__inner-container {
    border-bottom: 2px solid #ced1d4;
    padding: 40px 0 50px;
    display: none;

    &.no-border {
      border: none;
    }

    &.active {
      display: block;
    }

    &.cbg-searchresults--no-results-container {
      border: none;
    }
  }

  .cbg-cmp-search-results__content-container {
    display: none;

    &.active {
      display: block;
    }
  }

  .cbg-cmp-search-results__load-more-button {
    display: block;
    margin: 40px auto 0;
    width: 50%;
    max-width: 240px;
    cursor: pointer;

    background-color: white;
    border: 2px solid #ced1d4;
    color: #002e5d;
    font-family: $font-ibm;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    letter-spacing: 0.5px;
    text-align: center;

    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    &[disabled] {
      opacity: 0;
    }

    &:hover {
      border-color: #002e5d;
    }

    @media screen and (max-width: $screen-xs) {
      width: 75%;
    }
  }
}

.cbg-cmp-search-results__no-results-text {
  color: #5b6770;
  font-family: $font-ibm;
  font-size: 18px;
  line-height: 28px;
}

.searchresults {
  &.align-text-left {
    .cbg-cmp-search-results__header {
      text-align: left;
    }
  }

  &.align-text-center {
    .cbg-cmp-search-results__header {
      text-align: center;
    }
  }

  &.align-text-right {
    .cbg-cmp-search-results__header {
      text-align: right;
    }
  }
}
