.cbg__text-align--center {
  text-align: center;
}

.cbg__text-align--left {
  text-align: left;
}

.cbg__text-align--right {
  text-align: left;
}

.cbg__text-lc {
  text-transform: lowercase;
}

.cbg__text-cap {
  text-transform: capitalize;
}

.cbg__hidden-text {
  text-indent: -9999px;
}

.m-tb-1 {
  margin: 1rem 0;
}
