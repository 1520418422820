.timeline-home {
  height: auto;
  padding: 0;
  @media screen and (min-width: $screen-md) {
    padding: 0 20px;
  }
}

.timeline-group {
  display: block;
  padding: 0;

  @media screen and (min-width: $screen-sm) {
    padding: 2em;
  }
}

.group-title {
  font-weight: 900;
}

.news-item-group {
  margin-bottom: 33px;

  .news-item {
    display: flex;

    @media screen and (min-width: $screen-sm) {
      padding: 2em;
    }

    a {
      text-decoration: none;
    }

    .item-date {
      width: 35%;
      margin-right: 1em;

      @media screen and (min-width: $screen-sm) {
        width: 17%;
      }
    }

    .item-info {
      width: 100%;
      font-size: 0.8em;

      @media screen and (min-width: $screen-sm) {
        font-size: 1em;
        width: 80%;
      }
    }

    .item-description {
      font-weight: normal;
    }
  }
}

.main-description {
  font-weight: normal;
  font-size: 0.8em;

  @media screen and (min-width: $screen-md) {
    font-size: 1em;
    width: 80%;
  }
}

.add-gutter {
  .timeline-home {
    @media screen and (min-width: $screen-sm) {
      width: 100%;
    }
    @media screen and (min-width: $screen-md) {
      margin: auto;
      max-width: 740px;
    }
    @media screen and (min-width: $screen-lg) {
      margin: auto;
      max-width: 1100px;
    }
  }
}

.text-alignment-left {
  .heading-section {
    text-align: left;
  }
}

.text-alignment-center {
  .heading-section {
    text-align: center;
  }
}

.text-alignment-right {
  .heading-section {
    text-align: right;
  }
}
