.combinedinput-home {
  display: flex;
  flex-direction: column;
  font-size: $font-size;

  .preferred-location-container {
    .input-group {
      display: flex;

      .clear-input + button .cmp-locationbar__clear-icon {
        padding: 0 rem(15px);
        background-image: $icon-x;
        background-size: 1.5rem;
        background-origin: border-box;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        height: 20px;
      }

      button:not(.cmp-locationbar__clear) {
        width: auto;
      }
    }
  }

  form {
    display: flex;
  }

  img {
    display: inline;
    max-width: 1.2rem;
  }

  .input-wrapper {
    display: flex;
  }

  input {
    flex-grow: 1;
    width: 66%;
    height: auto;
    padding: 0.375rem 0.375rem 0.375rem 1rem;
    margin: 0;
    border-width: 1px;
    border-radius: 3rem 0 0 3rem;
    outline: 0;
    color: $platform-black;
    font-size: $font-size;
    font-weight: 500;
    line-height: 1.2;

    // placeholder text size reduced for longer text in mobile
    &::placeholder {
      font-size: 12px;

      @media screen and (min-width: $screen-md) {
        font-size: 16px;
      }
    }

    &.component-width-small {
      @media screen and (min-width: $screen-md) {
        min-width: 7em;
        width: 100%;
      }
    }

    &.component-width-medium {
      @media screen and (min-width: $screen-md) {
        width: 16em;

        + .cmp-locationbar__clear {
          right: 165px;
        }
      }
    }

    &.component-width-large {
      @media screen and (min-width: $screen-md) {
        width: 24em;

        + .cmp-locationbar__clear {
          right: 145px;
        }
      }
    }

    &.component-width-xl {
      @media screen and (min-width: $screen-md) {
        width: 30em;

        + .cmp-locationbar__clear {
          right: 135px;
        }
      }
    }

    & + button.clear-input {
      background: $platform-white;
      margin: 0;
      border: 0;
      cursor: default;
      background-position: center;
      border-radius: 0;
      background-repeat: no-repeat;
    }

    &.show-input {
      & + button.clear-input {
        padding: 0 rem(12px);
        background-image: $icon-x;
        background-size: 1.5rem;
        background-origin: border-box;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          background-image: $icon-x;
          background-position: center;
          background-repeat: no-repeat;
          background-origin: border-box;
        }
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: max-content;
    font-size: $font-size;
    overflow: visible;
    border: 0;
    cursor: pointer;
    padding: 0 1.5rem 0 0.375rem;
    background: $platform-background-light-gray-scheme;
    border-radius: 0 3rem 3rem 0;
    height: 3rem;
    margin: 0;
    margin-right: 1rem;
    width: 33%;

    @media screen and (max-width: $screen-md) {
      margin-right: 0;
      width: 100%;
    }

    &.button-width-small {
      @media screen and (min-width: $screen-md) {
        min-width: 6em;
      }
    }

    &.button-width-medium {
      @media screen and (min-width: $screen-md) {
        min-width: 10em;
      }
    }

    &.button-width-large {
      @media screen and (min-width: $screen-md) {
        min-width: 14em;
      }
    }

    &.button-width-xl {
      @media screen and (min-width: $screen-md) {
        min-width: 20em;
      }
    }
  }

  .primary-description {
    margin: 0;
  }

  .error-message {
    display: none;
    align-self: flex-start;

    &.invalid {
      display: flex;
      font-size: 14px;
      margin: 0 10px;
    }
  }

  &.stacked-layout {
    form {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .text-group {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-bottom: 1rem;
    }

    @media screen and (max-width: $screen-sm) {
      .text-group {
        padding-bottom: 0;
      }
    }

    .input-group-wrapper {
      display: flex;
      width: 100%;
    }

    .error-message {
      align-self: flex-start;
    }
  }

  &.side-by-side {
    .text-group {
      display: flex;
      flex-direction: column;
      align-self: center;
      padding-right: 1rem;
      margin-bottom: 10px;
      @media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
        height: 40px;
      }
    }

    .input-group-wrapper {
      display: flex;
      width: 100%;
    }
  }

  &.center-align {
    form {
      justify-content: center;
    }

    .text-group {
      align-items: center;
    }

    .input-group {
      justify-content: center;
    }

    .error-message {
      align-self: center;
    }
  }

  &.right-align {
    form {
      justify-content: flex-end;
    }

    .text-group {
      align-items: flex-end;
    }

    .input-group {
      justify-content: flex-end;
    }

    .error-message {
      align-self: flex-end;
    }
  }

  margin: 0.5em 0;
  .input-group {
    position: relative;
  }
}

@media screen and (max-width: $screen-sm) {
  .text-group {
    .title {
      letter-spacing: 1px;
      line-height: 28px;
    }
  }
}

.description {
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 23px;
}

.results-group {
  width: 100%;
}

.preferred-location {
  display: flex;
  width: 66%;
  max-width: fit-content;
  flex-direction: row;
  margin: 1rem;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;

  @media screen and (max-width: $screen-sm) {
    height: fit-content;
    width: 100%;
    margin: 0.5rem 0.5rem 0 0;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .address-box {
    width: 300px;
    margin-left: 60px;
    padding-top: 10px;
    height: fit-content;

    @media screen and (max-width: $screen-sm) {
      margin-left: 50px;
    }

    .address-name {
      font-weight: bold;
    }

    span {
      display: block;
    }
  }

  .distance {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100px;
    height: 100%;

    span {
      display: flex;
      padding-top: 15px;
      font-weight: bold;
      align-self: center;
    }

    label {
      padding-top: 10px;
      align-self: center;
    }
  }

  /* Style the indicator (dot/circle) */
  .styled-radio {
    display: block;
    position: relative;
    margin-left: 20px;
    margin-top: 15px;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: $screen-sm) {
      margin-left: 10px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border-radius: 50%;

      &:hover {
        /* Create the indicator (the dot/circle - hidden when not checked) */
        &:after {
          top: 6px;
          left: 6px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:after {
        top: 6px;
        left: 6px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        content: "";
        position: absolute;
        display: none;
      }
    }

    input {
      /* Hide the browser's default radio button */
      position: absolute;
      opacity: 0;
      cursor: pointer;

      /* Show the indicator (dot/circle) when checked */
      &:checked,
      &:hover {
        ~ .checkmark {
          &:after {
            display: block;
          }
        }
      }
    }
  }
}

.show-more {
  width: 66%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.error-message-spacer {
  display: none;
}

.banner-slender {
  .banner-gutter-wrapper {
    .banner-text-container {
      .banner-button-zone {
        width: 100%;
      }
    }
    .combinedinput-home {
      .title {
        font-weight: normal;
      }
    }
  }

  // error container.
  .error-message-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    &.error-hide {
      display: none;
    }
  }

  .error-message-spacer {
    @media screen and (min-width: $screen-md) {
      display: inline-flex;
    }
  }

  form {
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: $screen-md) {
      flex-direction: row;
    }
  }

  .combinedinput-home {
    align-items: center;
    font-size: 24px;
    margin-top: 0;
    @media screen and (min-width: $screen-md) {
      margin-top: 0.5em;
    }
  }

  .text-group {
    min-width: 260px;
    text-align: center;
    @media screen and (min-width: $screen-md) {
      text-align: right;
      height: 40px;
      margin-bottom: 10px;
    }
  }
}

.button-content {
  margin: 0;
  display: flex;

  svg {
    height: 20px;
    width: 20px;
    margin: 2px 10px 0 10px;
    vertical-align: middle;
  }

  .icon-spacer {
    height: 20px;
    width: 30px;
    vertical-align: middle;
  }
}

button.cmp-locationbar__clear {
  padding: 0;
  margin: 0 auto;
  z-index: $z-level2;
  border-left: none;
  border-right: none;
  position: absolute;
  right: 120px;
  max-width: 2em;
  max-height: 100%;
  background-color: transparent !important;

  @media screen and (max-width: $screen-md) {
    right: 176px;
  }
}

.cmp-locationbar__clear-icon {
  background-repeat: no-repeat;
  position: static;
  margin: 0 auto;
  width: 18px;

  .icon {
    background-repeat: no-repeat;
    position: static;
    margin: 0 auto;
    width: 18px;
    height: 18px;
  }
}

.cmp-locationbar__clear {
  display: none;
}

.quote-styling .combinedinput-home button {
  margin: 0;
}

.quote-styling .combinedinput-home.right-align form {
  justify-content: center;
  display: flex;
}

.quote-styling .banner-no-image .banner-text-container {
  width: 100%;

  @media screen and (min-width: $screen-md) {
    padding: 0;
    width: 50%;
  }
}
