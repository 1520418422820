.curalate-component-home {
  text-align: center;

  .curalate-title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 1;

    @media screen and (min-width: $screen-sm) {
      margin: 2rem 2rem 0.5rem 2rem;
      line-height: 6.25rem;
      text-align: center;
    }
  }

  .curalate-description {
    font-size: 1rem;
    margin: 2rem;
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (min-width: $screen-md) {
      font-size: 1.2rem;
      margin: 0 10em;
    }
  }

  &.add-gutter {
    .crl8-homepage-carousel {
      width: 1220px;
      margin: auto;
    }
  }

  &.container-margin-small {
    @media screen and (min-width: $screen-md) {
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }

  &.container-margin-medium {
    @media screen and (min-width: $screen-md) {
      padding-top: 4em;
      padding-bottom: 4em;
    }
  }

  &.container-margin-large {
    @media screen and (min-width: $screen-md) {
      padding-top: 6em;
      padding-bottom: 6em;
    }
  }

  &.container-margin-extra-large {
    @media screen and (min-width: $screen-md) {
      padding-top: 8em;
      padding-bottom: 8em;
    }
  }

  .curalate-component-text {
    p {
      margin-top: 0;
    }
  }
}

.text-alignment-left {
  .curalate-title,
  .curalate-description {
    text-align: left;
    margin-left: 4rem;
  }
}

.text-alignment-center {
  .curalate-title,
  .curalate-description {
    text-align: center;
  }
}

.text-alignment-right {
  .curalate-title,
  .curalate-description {
    text-align: right;
    margin-right: 4rem;
  }
}
